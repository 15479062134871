import { Box, Button, Column } from 'rbx'
import { BoxHeader } from '../../shared/BoxHeader'
import { useFetch } from '../../../hooks/useFetch'
import { API_URL } from '../../../services/api'
import { toCurrency } from '../../../helpers/toCurrency'
import Loading from '../../../helpers/Loading'
import QRCode from 'react-qr-code'
import { Images } from '../../../utils/images'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

export function IrregularVehicleQrCodeModal({ closeModal, irregularityId }) {
  const {
    response: irregularityData
  } = useFetch(`${API_URL}/adm/irregular-vehicle-history/${irregularityId}`)

  const irregularity = irregularityData?.data

  // console.log('IrregularVehicleQrCodeModal - ', 'Rendered: ', irregularity)

  if (!irregularity) {
    return <Loading />
  } else {
    return (
      <Box>
        <BoxHeader title="Aviso de Irregularidade" handleClose={closeModal} />
  
        <Column.Group className="mb-0">
          <Column>
            {irregularity?.taxQrCode && <QRCode value={irregularity?.taxQrCode} />}
          </Column>
          <Column className='is-flex-tablet is-flex-direction-column is-justify-content-space-between'>
            <div>
              <div className='mb-2'> <strong>Placa: </strong>{irregularity?.licensePlate} </div>
              <div> <strong>Data: </strong>{irregularity?.date}</div>
            </div>
            <div>
              <div className='mb-2'> <strong>Pague com PIX</strong> </div>
              <div className='mb-2'> <strong>Vencimento: </strong> {moment(irregularity?.taxValidity).format("DD/MM/YYYY ")}</div>
              <div className='mb-2'> <strong>Valor: </strong> {toCurrency(irregularity?.taxValue)}</div>
              {
                irregularity?.paidAt && (
                  <div className='mb-2'>
                    <strong>Pago em: </strong>
                    {moment(irregularity?.paidAt).format("DD/MM/YYYY ")}
                    <FontAwesomeIcon className="ml-1" size="lg" icon={faCheckCircle} color={'green'} />
                  </div>
                )
              }
              <div>
                <Button
                  className="mb-2"
                  color="custom-purple"
                  tooltip="Copiar"
                  onClick={() => {
                    navigator.clipboard.writeText(irregularity?.taxQrCode)
                  }}
                >
                  <img src={Images.pixWhiteImg} alt="Pix" className='mr-2' />
                  Copiar chave PIX
                </Button>
              </div>
            </div>
          </Column>
        </Column.Group>
      </Box>
    )
  }
}
