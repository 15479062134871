import { Control, Label, Field, Input, Icon } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import './styles.scss'

export const TextFieldWithSearch = ({ name, label, value, onChange, onClick, ...other }) => {
  return (
    <>
      {(label) && <Label>{label}</Label>}
      <Field>
        <Control iconRight>
          <Input 
            name={name}
            value={value}
            onChange={onChange}
            {...other}
          />
          <Icon color="black" align="right" onClick={onClick}>
            <FontAwesomeIcon icon={faSearch} />
          </Icon>
        </Control>
      </Field>
    </>
  )
}
