import styled from "styled-components";

export const Container = styled.div`
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & + header {
      margin-top: 1rem;
    }
  }
`;

export const IconButton = styled.button`
  height: 34px;
  padding: 8px 5px;
  border: none;
  background: transparent;
`;