import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { maskCpfCnpj, unMask } from '../../../utils/masks';
import { API_URL } from '../../../services/api';
import { Container } from '../styles';
import { RedirectTo } from '../../../utils/redirectTo';
import { PasswordInput } from '../../shared/PasswordInput';

export function ResetPasswordForm() {
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)

  const [loading, setLoading] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [code, setCode] = useState(searchParams.get('code'));
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== passwordConfirmation) {
      return toast.error('As senhas devem ser idênticas.')
    }

    setLoading(true);

    const body = { 
      cpfCnpj: unMask(cpfCnpj),
      code,
      password
    }

    const res = await fetch(`${API_URL}/reset-password`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setCpfCnpj('')
      setCode('')
      setPassword('')
      setPasswordConfirmation('')
      setTimeout(() => history.push(RedirectTo.login), 3000)
    }

    setLoading(false);
  };

  return (
    <Container>
      <h2>Alterar senha</h2>

      <form onSubmit={handleSubmit}>
        <MaskedInput
          mask={maskCpfCnpj}
          placeholder="Cpf ou Cnpj"
          guide={false}
          type="text"
          value={cpfCnpj}
          onChange={(event) => setCpfCnpj(event.target.value)}
          required
        />

        <input
          placeholder="Código de Verificação"
          type="text"
          value={code}
          onChange={(event) => setCode(event.target.value)}
          pattern=".{4,8}"
          title="Informe de 4 a 8 caracteres"
          maxLength="8"
          required
        />

        <PasswordInput
          placeholder="Nova Senha"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          required
        />

        <PasswordInput
          placeholder="Confirme a Nova Senha"
          value={passwordConfirmation}
          onChange={(event) => setPasswordConfirmation(event.target.value)}
          required
        />

        <div className='is-flex is-justify-content-end is-align-items-center'>
          <Link to={RedirectTo.login}>
            <span>Acessar Conta</span>
          </Link>
        </div>

        {loading ? (
          <button disabled type="submit">
            Alterando
          </button>
        ) : (
          <button type="submit">Alterar</button>
        )}
      </form>
    </Container>
  );
}
