import { Box } from 'rbx'
import { BoxHeader } from '../../shared/BoxHeader'

export const VehicleImage = ({ closeModal, irregularity={} }) => {

  return (
    <Box>
      <BoxHeader title={'Placa: ' + irregularity.licensePlate} handleClose={closeModal} />

      <img src={irregularity.imageVehicle} alt='Imagem do Veículo' width={340} />
    </Box>
  )
}
