import { useContext, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { API_URL } from '../../../../services/api';
import { Container, InputGrid } from './styles';
import AuthContext from '../../../../contexts/AuthContext';
import { ModalHeader } from '../../../shared/ModalHeader';

export function UserDeleteModal({ isOpen, onRequestClose }) {
  const [code, setCode] = useState('');
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const { logout } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoadingConfirm(true)

    const res = await fetch(`${API_URL}/users/confirm-deletion`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token'),
      },
      body: JSON.stringify({ confirmationCode: code }),
    })

    const response = await res.json()

    if (!res.ok) {
      toast.error(response.message)
      setLoadingConfirm(false)
    } else {
      toast.success(response.message)
      setTimeout(logout, 5000)
    }
  }

  const requestCode = async () => {
    setLoadingRequest(true)
    const res = await fetch(`${API_URL}/users/request-deletion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token'),
      }
    })
    
    const response = await res.json();
    setLoadingRequest(false)

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose()
        setCode('')
      }}
      overlayClassName="react-modal-overlay z-index-5"
      className="react-modal-content"
    >
      <ModalHeader
        title='Exclusão de Conta'
        handleClose={() => {
          onRequestClose()
          setCode('')
        }}
      />

      <Container onSubmit={handleSubmit}>
        <div className='has-text-centered'>
          Clique em Solicitar Código para receber um código de verificação no seu email. Informe este código na caixa abaixo e clique em Confirmar Exclusão para excluir sua conta.
        </div>

        <input
          className='has-text-centered'
          placeholder="Código de verificação"
          type="number"
          max="99999999"
          value={code}
          onChange={(event) => setCode(event.target.value)}
          required
          autoFocus
        />

        <hr className='hr'></hr>

        <InputGrid>
          {
            loadingRequest
              ? <button type="button">Solicitando ...</button>
              : <button type="button" onClick={requestCode}>Solicitar Código</button>
          }

          {
            loadingConfirm
              ? <button type="submit" disabled>Excluindo ...</button>
              : <button type="submit">Confirmar Exclusão</button>
          }
        </InputGrid>
      </Container>
    </Modal>
  );
}
