import ActionButtons from './ActionButtons'
import ActionButton from './ActionButton'
import CpfCnpjField from './CpfCnpjField'
import LicensePlateField from './LicensePlateField'
import { PasswordField } from './PasswordField'
import { PhoneField } from './PhoneField'
import RadioButton from './RadioButton'
import SelectField from './SelectField'
import TextareaField from './TextareaField'
import { TextField } from './TextField'
import TextFieldMask from './TextFieldMask'
import { TextFieldWithPlus } from './TextFieldWithPlus'
import { TextFieldWithSearch } from './TextFieldWithSearch'
import { TextFieldWithSearch2 } from './TextFieldWithSearch2'
import { ZipCodeField } from './ZipCodeField'

const Controls = {
  ActionButtons,
  ActionButton,
  CpfCnpjField,
  LicensePlateField,
  PasswordField,
  PhoneField,
  RadioButton,
  SelectField,
  TextareaField,
  TextField,
  TextFieldMask,
  TextFieldWithPlus,
  TextFieldWithSearch,
  TextFieldWithSearch2,
  ZipCodeField
}

export default Controls
