import { useState } from 'react';
import { Images } from '../../../utils/images';
import { Container, IconButton } from './styles';
import Timer from 'react-compound-timer';
import { RenewParkingModal } from '../Modals/RenewParkingModal';
import { Box, Button, Icon } from 'rbx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faEdit, faMapMarkerAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ScheduleParkingModal } from '../Modals/ScheduleParkingModal';
import moment from 'moment';
import { CancelScheduleParkingModal } from '../Modals/CancelScheduleParkingModal';
import { VehicleModal } from '../Modals/VehicleModal';
import { useFetch } from '../../../hooks/useFetch';
import { API_URL } from '../../../services/api';
import { ParkingModal } from '../Modals/ParkingModal';
import { DevelopmentNotification } from '../../shared/DevelopmentNotification';
import { FreeTimeNotification } from '../../shared/FreeTimeNotification';
import { EducationalPeriodNotification } from '../../shared/EducationalPeriodNotification';

export function Vehicles() {
  const [modal, setModal] = useState({})
  const [search, setSearch] = useState('')

  const profile = sessionStorage.getItem('profile');

  // const { 
  //   response: responseTimeRuleToday 
  // } = useFetch(`${API_URL}/time-rule/today`)

  // const timeRuleToday = responseTimeRuleToday?.data || {}

  const { 
    response: responseHomeUser,
    refresh: refreshHomeUser 
  } = useFetch(`${API_URL}/home/user`)
  
  const vehicles = responseHomeUser?.data?.vehicles || []

  const timeRuleToday = responseHomeUser?.data?.timeRuleToday || {}

  const vehiclesFiltered = search.length > 0 
    ? vehicles.filter(vehicle => vehicle.licensePlate.includes(search))
    : vehicles

  // const { 
  //   response: responseScheduleParking, 
  //   refresh: refreshScheduleParking 
  // } = useFetch(`${API_URL}/parkings/schedule/active`)
  
  // const vehiclesSchedule = responseScheduleParking?.data || []

  // const { 
  //   response: responseVehiclesParked, 
  //   refresh: refreshVehiclesParked 
  // } = useFetch(`${API_URL}/parkings`)
  
  // const vehiclesParked = responseVehiclesParked?.data || []

  const { 
    response: responseEducationalPeriod 
  } = useFetch(`${API_URL}/educational-period`)

  const educationalPeriod = responseEducationalPeriod?.data || {}

  const ScheduleButton = ({ vehicle }) => {
    return (
      <div className='is-flex m-0 p-0 ml-1' style={{width:'100%'}}>
        <Button
          className={ vehicle.scheduled ? 'schedule2' : 'schedule' }
          size='small'
          fullwidth
          rounded
          style={{ fontSize: '0.9rem', fontWeight: '500' }}
          title={ vehicle.scheduled ? 'Inicio: '+moment(vehicle.skdStartDate).format('DD/MM HH:mm') : ''}
          onClick={() => {
            if (vehicle.scheduled) {
              openCancelScheduleParkingModal(vehicle)
            } else {
              openScheduleParkingModal(vehicle.licensePlate)
            }
          }}
        >
          {
            vehicle.scheduled 
              ? <>
                  <FontAwesomeIcon icon={faClock} className='mr-2' />
                  {moment(vehicle.skdStartDate).format('DD/MM HH:mm')}
                </>
              : 'AGENDAR'
          }
        </Button>
      </div>
    )
  }

  const ParkingButton = ({ vehicle }) => {
    return (
      <div className='is-flex m-0 p-0 mr-1' style={{width:'100%'}}>
        {
          vehicle.parked ? (
            <span className="timer">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <Timer
                initialTime={vehicle.timeReaming}
                direction="backward"
                checkpoints={[{
                  time: 0, callback: () => window.location.reload()
                }]}
              >
                {
                vehicle.timeReaming !== 0 &&
                  <span>
                    <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
                  </span>
                }
              </Timer>
            </span>
          ) : (
            <Button
              className='parking'
              size='small'
              fullwidth
              rounded
              style={{ background:'var(--blue)', color:'var(--shape)', fontSize:'0.9rem', fontWeight:'500' }}
              onClick={() => {
                openParkingModal(vehicle.licensePlate)
              }}
            >
              ESTACIONAR
            </Button>
          )
        }
      </div>
    )
  }

  const Vehicle = ({ vehicle, validTimeForParking, profile }) => {
    return (
      <Box className='vehicle is-flex is-flex-direction-column py-3 px-4'>
        <div className='is-flex m-0 p-0 is-align-items-start' style={{width:'100%'}}>
          { vehicle.type === 'carro' && <img src={Images.carImg} alt={vehicle.type} /> }
          { vehicle.type === 'ônibus' && <img src={Images.busImg} alt={vehicle.type} /> }
          { vehicle.type === 'caminhão' && <img src={Images.truckImg} alt={vehicle.type} /> }
          { vehicle.type === 'motocicleta' && <img src={Images.motocycleImg} alt={vehicle.type} /> }
          <span>
            <strong>
              { vehicle.licensePlate }
            </strong>
            <p style={{marginTop:0, fontWeight:500}}>
              { vehicle.alias }
            </p>
          </span>
          {
            vehicle.parked ? (
              <IconButton
                onClick={() => {
                  openRenewParkingModal(vehicle);
                }}
              >
                <img src={Images.addImg} alt="Mais opções" title='Mais opções' />
              </IconButton>
            ) : (
              <FontAwesomeIcon icon={faEdit} className="mr-2 is-size-5 is-clickable" onClick={() => openVehicleModal(vehicle, profile)} />
            )
          }
        </div>
        <div className='is-flex m-0 p-0 mt-2' style={{ width:'100%', gap:'0.5rem'}}>
          <ScheduleButton vehicle={vehicle} />

          {validTimeForParking && (
            <ParkingButton vehicle={vehicle} />
          )}
        </div>
      </Box>
    )
  }

  const closeModal = (callback) => {
    if (callback) callback()
    setModal({})
  }

  const openVehicleModal = (vehicle, profile) => {
    if (profile !== 'driver') {
      setModal({
        show: <VehicleModal
          isOpen={true}
          onRequestClose={() => closeModal(refreshHomeUser)}
          vehicle={vehicle}
        />
      })
    }
  }

  const openParkingModal = (licensePlate) => {
    setModal({
      show: <ParkingModal
        isOpen={true}
        onRequestClose={closeModal}
        placa={licensePlate}
      />
    })
  }

  const openRenewParkingModal = (vehicle) => {
    setModal({
      show: <RenewParkingModal
        isOpen={true}
        onRequestClose={() => closeModal(refreshHomeUser)}
        vehicle={vehicle}
      />
    })
  }

  const openScheduleParkingModal = (licensePlate) => {
    setModal({
      show: <ScheduleParkingModal
        isOpen={true}
        onRequestClose={closeModal}
        placa={licensePlate}
      />
    })
  }

  const openCancelScheduleParkingModal = (vehicle) => {
    setModal({
      show: <CancelScheduleParkingModal
        isOpen={true}
        onRequestClose={() => closeModal(refreshHomeUser)}
        vehicle={vehicle}
      />
    })
  }
  
  return (
    <Container className='container-div'>
      { modal.show }

      <header>
        <h2>Meus veículos</h2>
        {
          profile !== 'driver' && 
            <Icon className={"is-clickable"} onClick={openVehicleModal}>
              <FontAwesomeIcon icon={faPlus} style={{fontSize:'1.2rem'}} />
            </Icon>
        }
      </header>

      <DevelopmentNotification />

      <FreeTimeNotification isFreeTime={timeRuleToday?.validTimeForParking === false} />

      <EducationalPeriodNotification isEducationalPeriod={educationalPeriod.valid} message={educationalPeriod.message} />

      {
        vehicles.length > 8 &&
        <div className='is-flex mt-0 py-2 px-0'>
          <input
            name="search"
            value={search}
            onChange={e => setSearch(e.target.value.toUpperCase())}
            placeholder="Pesquisar por placa"
            autoFocus
            autoComplete='off'
          />
        </div>
      }

      {
        vehiclesFiltered?.map(vehicle => (
          <Vehicle
            key={vehicle.licensePlate}
            vehicle={vehicle}
            validTimeForParking={timeRuleToday?.validTimeForParking}
            profile={profile}
          />
        ))
      }

      {
        !vehicles && <p>Nenhum veículo cadastrado.</p>
      }
    </Container>
  );
}
