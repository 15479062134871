import { useState } from 'react'
import { Control, Icon, Input } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AddressItem, AddressList, Container, ItemIcon } from './styles'
import { ReferenceService } from '../../../services/api-zad'
import { faMapPin } from '@fortawesome/free-solid-svg-icons'

export const SearchReferencesPlace = ({ addReference }) => {
  const [searchReferences, setSearchReferences] = useState('')
  const [referencesList, setReferencesList] = useState([])

  const selectReference = async (reference) => {
    setReferencesList([])
    setSearchReferences('')
    addReference(reference)
  }

  const fetchReferences = async (value) => {
    if (value.length > 5) {
      // nothing to do
    } else if (value.length >= 4 ) {
      const params = new URLSearchParams({ name: value })
      const response = await ReferenceService.index(params)
      setReferencesList(response.data.data)
    } else {
      if (referencesList.length) setReferencesList([])
    }
  }

  return (
    <Container>
      <Control iconLeft>
        <Input 
          id='search'
          placeholder="Pesquisar referências cadastradas"
          autoComplete='off'
          value={searchReferences}
          onChange={(event) => {
            fetchReferences(event.target.value)
            setSearchReferences(event.target.value)
          }}
        />
        <Icon align="left">
          <FontAwesomeIcon icon={faMapPin} />
        </Icon>
        <AddressList references={searchReferences}>
          {
            referencesList.length ? (
              referencesList.map((reference, i) => (
                <AddressItem key={i} onClick={() => selectReference(reference)}>
                  <ItemIcon /> { reference.name }
                </AddressItem>
              ))
            ) : (
              <AddressItem>
                Nenhum endereço encontrado.
              </AddressItem>
            )
          }
        </AddressList>
      </Control>
    </Container>
  )
}
