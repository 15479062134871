import { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { API_URL } from '../services/api';
import { RedirectTo } from '../utils/redirectTo';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  const history = useHistory();

  // Login user
  const login = async (body) => {
    try {
      setLoading(true);
      const res = await fetch(`${API_URL}/auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const response = await res.json();
      const data = response.data;

      if (!res.ok) {
        setError(response.message);
        setError(null);
        setLoading(false);
      }

      if (res.ok) {
        if (data.accountActivated === false) {
          setLoading(false);
          setError('Usuário não ativado! Ative sua conta a seguir.');
          history.push(RedirectTo.activate);
          return;
        }

        // const profiles = ['user', 'pdv']
        // const profiles = ['user', 'pdv', 'monitor']
        // if (env.ENV === 'dev') profiles.pop()

        // if (profiles.includes(data.profile)) {
        //   setLoading(false);
        //   setError(
        //     'Acesso bloqueado! Aplicação voltada à Administradores, Suporte e Agentes.',
        //   );
        //   return;
        // }

        setUser(data);

        sessionStorage.setItem('name', data.name);
        sessionStorage.setItem('shortName', data.shortName);
        sessionStorage.setItem('id', data.id);
        sessionStorage.setItem('cpfCnpj', data.cpfCnpj);
        sessionStorage.setItem('birthDay', data.birthDay);
        sessionStorage.setItem('email', data.email);
        sessionStorage.setItem('phone', data.phone);
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('profile', data.profile);
        sessionStorage.setItem('profile2', data.profile2);
        sessionStorage.setItem('discount', data.discount);

        // sessionStorage.setItem('id', data.id);
        // sessionStorage.setItem('token', data.token);

        setLoading(false);
        history.push(RedirectTo.dashboard);
      }
    } catch (e) {
      setLoading(false);
      setError(e.message);
      setError(null);
    } finally {
      setLoading(false);
      setError(null);
    }
  };

  // Logout user
  const logout = async () => {
    setUser(null);
    setError(null);
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('shortName');
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('cpfCnpj');
    sessionStorage.removeItem('birthDay');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('phone');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('profile');
    sessionStorage.removeItem('profile2');
    sessionStorage.removeItem('discount');
    sessionStorage.removeItem('dashboardRefresh');
    
    sessionStorage.removeItem('licensePlate');
    // sessionStorage.removeItem('id');
    // sessionStorage.removeItem('token');
    sessionStorage.removeItem('app');
    sessionStorage.removeItem('appId');

    history.push(RedirectTo.login);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        error,
        loading,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
