import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { maskCpfCnpj, unMask } from '../../../utils/masks';
import { API_URL } from '../../../services/api';
import { Container } from '../styles';
import { RedirectTo } from '../../../utils/redirectTo';

export function ForgotPasswordForm() {
  const [loading, setLoading] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState('');

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const body = { cpfCnpj: unMask(cpfCnpj) };

    const res = await fetch(`${API_URL}/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setCpfCnpj('');
      setTimeout(() => history.push(RedirectTo.login), 3000);
    }

    setLoading(false);
  };

  return (
    <Container>
      <h2>Recuperar senha</h2>

      <form onSubmit={handleSubmit}>
        <MaskedInput
          mask={maskCpfCnpj}
          placeholder="Cpf ou Cnpj"
          guide={false}
          type="text"
          value={cpfCnpj}
          autoFocus
          onChange={(event) => setCpfCnpj(event.target.value)}
          required
        />

        <div className='is-flex is-justify-content-end is-align-items-center'>
          <Link to={RedirectTo.login}>
            <span>Acessar Conta</span>
          </Link>
        </div>

        {loading ? (
          <button disabled type="submit">
            Recuperando
          </button>
        ) : (
          <button type="submit">Recuperar</button>
        )}
      </form>
    </Container>
  );
}
