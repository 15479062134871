import { Card } from 'rbx';
import { useState } from 'react'
import { toast } from 'react-toastify';
import UserService from '../../../services/user'
import { CardHeader } from '../../shared/CardHeader';
import Controls from '../../shared/Controls/Controls';

export const AppForgotPassword = ({ userData, goToMenu }) => {
  const [stateButton, setStateButton] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStateButton("loading");
    try {
      const body = { cpfCnpj: userData.cpf_cnpj }
      const response = await UserService.forgotPassword(body);
      toast.success(response.data.message)
      goToMenu();
    } catch(e) {
      if (e.response.data)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setStateButton('');
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader handleClose={goToMenu}>Reenviar Código para Recuperar Senha</CardHeader>
        <Card.Content>
          <Controls.ActionButtons
            linkName="Fechar"
            handleClickBack={goToMenu} 
            buttonState={stateButton} 
            buttonName='Confirmar'
          />
        </Card.Content>
      </Card>
    </form>
  )
}
