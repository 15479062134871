import Modal from 'react-modal';
import { Container, InfoContainer } from './styles';
import { Images } from '../../../../utils/images'

export function FinedIrregularityDetail({ isOpen, onRequestClose, detailsData }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={Images.closeImg} alt="Close modal" />
      </button>

      <Container>
        <h3>Irregularidade Registrada - Monitor</h3>

        <p>
          Local do estacionamento <br />
          <span>{detailsData.local}</span> <br />
          Ref: <span>{detailsData.reference || '-'}</span>
        </p>

        <InfoContainer>
          <p>
            Placa Identificada <br />
            <span>{detailsData.licensePlate}</span>
          </p>
          <p>
            Data
            <br />
            <span>{detailsData.date}</span>
          </p>
          <p>
            Início - Fim
            <br />
            <span>{detailsData.startDate}</span> -
            <span> {detailsData.endDate}</span>
          </p>
        </InfoContainer>

        <hr/>
        <h3>Confirmação do Fiscal</h3>

        <p>
          Local Confirmado <br />
          <span>{detailsData.confirmedLocal}</span>
        </p>
        <InfoContainer>
          <p>
            Placa Confirmada <br />
            <span>{detailsData.confirmedLicensePlate}</span>
          </p>
          <p>
            Infração <br />
            <span>{detailsData.infractionCode}</span>
          </p>
          <p>
            Data e hora <br />
            <span>{detailsData.confirmedAt}</span>
          </p>
        </InfoContainer>

        <p>
          Observações <br />
          <span>
            {detailsData.note || '-'}
          </span>
        </p>
      </Container>
    </Modal>
  )
}
