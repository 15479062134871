import { Column } from "rbx";
import "../../../styles/user.scss";
import { Layout } from "../../../components/Layout";
import { UserForm } from '../../../components/shared/UserForm';
import { useUser } from "../../../hooks/useUser";
import Loading from "../../../helpers/Loading";

export const UserEditPage = () => {
  const { user } = useUser(sessionStorage.getItem('id'))

  return (
    <Layout>
      {
        !user ? (
          <Loading />
        ) : (
          <Column.Group className="animeLeft" centered>
            <Column size={5}>
              <UserForm editUser={true} editUserLogged={true} userData={user} />
            </Column>
          </Column.Group>
        )
      }
    </Layout>
  )
};
