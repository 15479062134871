import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid lightgrey;
  border-radius: 0.25rem;
  background: var(--shape);

  strong {
    display: block;
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--text-body);
    margin-top: 0.5rem;
  }

  div {
    background: var(--shape);
    padding: 2rem 2rem;
    border-radius: 0.25rem;
    color: var(--text-title);
    height: fit-content;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & + header {
        margin-top: 1rem;
      }
    }

    h6 {
      display: block;
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--text-title);
    }

    p {
      display: block;
      font-size: 0.9rem;
      font-weight: 400;
      color: var(--text-body);
      margin-top: 1rem;
    }

    strong {
      display: block;
      font-size: 1rem;
      font-weight: 500;
      line-height: 2rem;

      span {
        font-size: 2.5rem;
        font-weight: 600;
        color: var(--blue);
      }
    }

    div {
      background: var(--container);
    }
  }

  div {
    padding: 0rem !important;
    background: var(--shape) !important;

    div {
      margin-top: 0rem !important;
      background: var(--shape) !important;

      #pagination-rows {
        margin-top: 1rem !important;
        padding-right: 24px !important;
      }
    }

    td > div {
      background: transparent !important;
    }
  }
`;

export const ContainerForm = styled.form`
  max-width: 560px;
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid lightgrey;
  border-radius: 0.25rem;
  background: var(--shape);

  h2 {
    color: var(--text-title);
    font-size: 1.5rem;
  }

  strong {
    display: block;
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--text-body);
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  input {
    width: 100%;
    padding: 0 1.5rem;
    height: 2.8rem; 
    border-radius: 0.25rem;

    border: 1px solid #d7d7d7;
    background: var(--input-background);

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }

    & + input {
      margin-top: 1rem;
    }
  }

  input,
  select {
    width: 100%;
    padding: 0 1.5rem;
    height: 2.8rem; 
    border-radius: 0.25rem;

    border: 1px solid #d7d7d7;
    background: var(--input-background);

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }

    & + input,
    & + select {
      margin-top: 1rem;
    }
  }

  button[type='submit'] {
    width: 100%;
    padding: 0 1.5rem;
    height: 3.5rem;
    background: var(--green);
    color: #fff;
    border-radius: 0.25rem;
    border: 0;
    font-size: 1rem;
    margin-top: 1rem;
    font-weight: 600;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  .MonitorsNowButton {
    width: 100%;
    padding: 0 1.5rem;
    height: 3.5rem;
    background: var(--blue);
    color: #fff;
    border-radius: 0.25rem;
    border: 0;
    font-size: 1rem;
    font-weight: 600;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;

  input + select,
  input + input {
    margin-top: 0;
  }

  input + input {
    margin-bottom: 1rem;
  }
`;

export const ChartGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
  }

  div {
    margin-bottom: 1rem;
  }

  h6 {
    margin-bottom: 1rem;

    span {
      font-weight: 600;
      color: var(--blue);
    }
  }
`;

export const ProgressGroup = styled.div`
  background: var(--shape) !important;
  display: flex;
  font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  justify-content: space-between;
  padding: 0 !important;
`;