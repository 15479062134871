import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'


export const ReportEstacionamentos = (estacionamentos, setIframe) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle = [
    {
      text: 'Estacionamentos',
      fontSize: 15,
      bold: true,
      margin: [15, 20, 0, 45] // left, top, right, bottom
    }
  ];

  const dados = estacionamentos.map((e) => {
    return [
      {text: e.area, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
      {text: e.street, fontSize: 9, margin: [0, 2, 0, 2]},
      {text: e.quantity, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'},
      {text: e.disabled, fontSize: 9, margin: [0, 2, 0, 2], alignment: 'center'}
    ] 
  });

  const details = [
    {
      table:{
        headerRows: 1,
        widths: [80, '*', 50, 80],
        body: [
          [
            {text: 'Área', style: 'tableHeader', fontSize: 10, alignment: 'center'},
            {text: 'Local', style: 'tableHeader', fontSize: 10},
            {text: 'Vagas', style: 'tableHeader', fontSize: 10, alignment: 'center'},
            {text: 'Desabilitado', style: 'tableHeader', fontSize: 10, alignment: 'center'}
          ],
          ...dados
        ]
      },
      layout: 'lightHorizontalLines' // headerLineOnly
    }
  ];

  const Rodape = (currentPage, pageCount) => {
    return [
      {
        text: currentPage + ' / ' + pageCount,
        alignment: 'right',
        fontSize: 9,
        margin: [0, 10, 20, 0] // left, top, right, bottom
      }
    ]
  }

  const docDefinitios = {
    pageSize: 'A4',
    pageMargins: [15, 50, 15, 40],

    header: [reportTitle],
    content: [details],
    footer: Rodape
  }

  // pdfMake.createPdf(docDefinitios).open()
  // pdfMake.createPdf(docDefinitios).download()
  pdfMake.createPdf(docDefinitios).getDataUrl(setIframe)
}
