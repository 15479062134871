import { useState } from 'react';
import { Modal, Box, Content, Title, Table, Tag } from "rbx";
import { PaginationCustom } from './PaginationCustom';
import { cpfOrCnpjMask } from '../../commons/masks';

const UserModalList = ({ activeModal, setActiveModal, listUsers, selectUser, userType }) => {
  const [pageOfItems, setPageOfItems] = useState([]);

  const toggle = () => { setActiveModal(!activeModal) };

  const onChangePage = (ppageOfItems) => {
    setPageOfItems(ppageOfItems);
  }

  return (
    <Modal closeOnBlur={true} closeOnEsc={true} active={activeModal} onClose={toggle}>
      <Modal.Background onClick={toggle} />
      <Modal.Content>
        <Box>
          <Content>
            <Title size={5} className="mb-4">
              Selecione um {userType}
            </Title>  
            <hr className="hr" />
            <Table fullwidth>
              <Table.Body>
                {
                  pageOfItems.map((user, index) => (
                    <Table.Row key={user._id} onClick={() => selectUser(pageOfItems[index])} className='is-clickable'>
                      <Table.Cell width="55px">
                        <Tag color="custom-purple">{user.profile}</Tag>
                      </Table.Cell>
                      <Table.Cell>
                        {cpfOrCnpjMask(user.cpf_cnpj)} - <strong>{user.name}</strong> <br/>
                        {user.email}
                      </Table.Cell>
                    </Table.Row>
                  ))
                }
              </Table.Body>
            </Table>
            <hr className="hr" />
            <PaginationCustom items={listUsers} onChangePage={onChangePage} pageSize={8} />
          </Content>
        </Box>
      </Modal.Content>
      <Modal.Close />
    </Modal>
  )

}

export default UserModalList;
