import { Layout } from '../../components/Layout'
import { RegionRuleList } from '../../components/RegionRule/list'

export const RegionRulePage = () => {
  return (
    <Layout controllerName='Regras de estacionamento'>
      <RegionRuleList />
    </Layout>
  )
}
