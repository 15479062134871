import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from 'rbx'

export const HistoricUse = ({ parking, onClick }) => {
  return (
    <Box
      className='is-clickable'
      onClick={onClick}
    >
      <div className='is-flex'>
        <div className='my-auto' style={{ flex:'0 1 30px' }}>
          <FontAwesomeIcon className='is-size-4' icon={faMapMarkerAlt} style={{ color:'var(--blue)' }} />
        </div>

        <div style={{ color:'var(--text-body)' }}>
          <strong className='is-block'>{parking.licensePlate}</strong>  
          <span className='is-block'>{parking.startDate}</span>
          <span>{parking.quantity}</span>
        </div>
      </div>
    </Box>
  )
}
