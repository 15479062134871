import { useState } from 'react';
import { Column } from 'rbx';
import { Layout } from "../../../components/Layout";
import { Controls } from '../../../components/App/Controls';
import MenuItems from '../../../components/shared/MenuItems';
import { UserForm } from '../../../components/shared/UserForm';
import Loading from '../../../helpers/Loading';
import { Redirect } from 'react-router-dom';
import UserService from '../../../services/user';
import { faHome, faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import { QuickAccess } from '../../../components/Layout/QuickAccess';
import { useMedia } from '../../../hooks/useMedia';

export const UserDatasheetPage = () => {
  const mobile = useMedia('(max-width: 768px)');
  const [itemSelected, setItemSelected] = useState();

  const user = UserService.get()

  if (!user) return <Redirect to={{ pathname: '/dashboard' }} />;

  const goToMenu = () => setItemSelected()

  const links = []

  if (!user.deleted) {
    if (!user.accountActivated) {
      links.push({ item: <Controls.ActiveAccount userData={user} goToMenu={goToMenu} />, description: "Ativar Conta" })
      links.push({ item: <Controls.AppResendCode userData={user} goToMenu={goToMenu} />, description: "Reenviar código de ativação" })
    }
  
    links.push({ item: <Controls.AppForgotPassword userData={user} goToMenu={goToMenu} />, description: "Reenviar código para recuperar senha" })
    links.push({ item: <UserForm editUser={true} userData={user} goToMenu={goToMenu} />, description: "Editar dados do usuário" })
  
    if (user.accountActivated) {
      links.push({ item: <Controls.AppStartParkingForm userData={user} goToMenu={goToMenu} />, description: "Estacionar veículo" })
      links.push({ item: <Controls.AppRenewParkingForm userData={user} goToMenu={goToMenu} />, description: "Renovar estacionamento" })
      // links.push({ item: <Controls.AppSellCad userData={user} goToMenu={goToMenu} />, description: "Vender CAD" })
      links.push({ item: <Controls.VehicleHistoricList userData={user} goToMenu={goToMenu} />, description: "Histórico do veículo" })
    }
  }

  links.push({ item: <Controls.AppPurchaseHistoryList userData={user} goToMenu={goToMenu} />, description: "Histórico de compras" })
  links.push({ item: <Controls.AppParkingHistoryList userData={user} goToMenu={goToMenu} />, description: "Histórico de uso" })

  if (user.profile2 === 'pdv') {
    links.push({ item: <Controls.AppSaleHistoryList userData={user} goToMenu={goToMenu} />, description: "Histórico de vendas" })
  }

  if (user.deleted) {
    links.push({ item: <Controls.AppUnlock userData={user} goToMenu={goToMenu} />, description: "Desbloquear Usuário" })
  } else {
    links.push({ item: <Controls.AppLock userData={user} goToMenu={goToMenu} />, description: "Bloquear Usuário" })
  }

  const items = [
    { to: "/dashboard", icon: faHome, title: 'Home', size: 5 },
    { to: "/user/search", icon: faSearch, title: 'Outro Usuário', size: 5 },
    { to: "/user/new", icon: faUser, title: 'Novo Usuário', size: 5 },
  ]

  console.log('itemSelected: ', itemSelected instanceof Object)

  return (
    <Layout>
      {
        !user ? (
          <Loading />
        ) : (
          <Column.Group className="animeLeft" centered>
            <Column size={4}>
              <Controls.AppDetail userData={user} setItemSelected={setItemSelected} />
            </Column>
            <Column>
              { !mobile && <QuickAccess items={items} /> }
              { 
                typeof itemSelected === 'object' ? 
                  itemSelected : 
                    <MenuItems setItemSelected={setItemSelected} links={links} />
              }
            </Column>
          </Column.Group>
        )
      }
      { mobile && <QuickAccess items={items} /> }
    </Layout>
  )
}
