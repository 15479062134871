import { Column } from "rbx";
import { Layout } from "../../../components/Layout";
import AppFindForm from '../../../components/App/FindForm';
import { useParams } from "react-router-dom";


export const UsearSearchPage = () => {
  const { cpf } = useParams();
  return (
    <Layout>
      <Column.Group className="animeLeft" centered>
        <Column size={4}>
          <AppFindForm cpf={cpf} />
        </Column>
      </Column.Group>
    </Layout>
  )
}
