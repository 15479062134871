export const toCurrency = (value) => {
  return Number(value||0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

export const toNumber = (value) => {
  return Number(value||0).toLocaleString('pt-br')
}

export const toDecimal = (value) => {
  return Number(value||0).toLocaleString('pt-br', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export const toPercent = (value, fraction=0) => {
  return Number(value||0).toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: fraction, maximumFractionDigits: fraction })
}
