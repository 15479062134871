export const Validator = {
  birthday: (birthday, profile) => {
      // const today = new Date();
      // const year = today.getFullYear();
      // const minYear = +year - 125;
      // const maxYear = +year - 18;
    
      // const dateYear = birthday.substring(0, 4);
  
      // if (dateYear < minYear.toString() || dateYear > maxYear.toString()) {
      //   return false
      // } else {
      //   return true
      // }

    const birthdayDate = new Date(birthday)

    if (new Date() - birthdayDate <= 0) return [false, 'Data inválida.']

    if (new Date().getFullYear() - birthdayDate.getFullYear() >= 125 && profile !== 'pdv') return [false, 'Data de nascimento inválida.']

    if (new Date().getFullYear() - birthdayDate.getFullYear() <= 18 && profile !== 'pdv') return [false, 'Usuário menor de idade.']

    return [true, 'Data válida']
  }
}