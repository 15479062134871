import { useEffect, useState } from 'react'
import { Box, Column, Icon, Progress, Title } from 'rbx'
import { ProgressGroup } from '../styles'
import { toDecimal, toNumber, toPercent } from '../../../helpers/toCurrency'
import { API_URL } from '../../../services/api'
import { useFetch } from '../../../hooks/useFetch'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { Timer } from '../../shared/Timer'

export function OccupationByArea() {
  const [time, setTime] = useState(null)
  const [vacanciesData, setVacanciesData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const { response: vacanciesResponse, load, refresh: vacanciesRefresh } = useFetch(`${API_URL}/adm/vacancies`)

  const minutes = 1

  useEffect(() => {
    setTime(moment())

    if (!load) {
      const interval = setInterval(() => {
        vacanciesRefresh()
        setTime(moment())
      }, minutes * 60 * 1000);
  
      return () => clearInterval(interval)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setVacanciesData(vacanciesResponse?.data?.vacanciesByArea||[])
    setSummaryData(vacanciesResponse?.data?.summary||[])
  }, [vacanciesResponse?.data])

  // console.log('OccupationByArea', 'Rendered')

  return (
    <Box 
      className='occupation-by-area'
      style={{ padding:'2rem' }}
    >
      <header>
        <Title size={4}>Ocupação por Área</Title>
        <Title subtitle size={6} style={{ fontSize:'0.9rem' }}>
          Atualiza em: <Timer startDate={time} paidMinutes={minutes} reverse={true} format='mm[:]ss'/>
          {
            load && (
              <Icon className='fa-spin ml-2' size='small' style={{fontSize:'0.8rem'}}>
                <FontAwesomeIcon icon={faSyncAlt} />
              </Icon>
            )
          }
        </Title>
      </header>
      <Column.Group className='mt-2' gapSize={0} breakpoint='mobile'>
        {
          summaryData.map(summary => (
            <Column key={Math.random()} textAlign='centered'>
              <div className='p-2' style={{ borderLeft: '1px solid lightgrey', borderRight: '1px solid lightgrey' }}>
                <span 
                  style={{ color: summary.title === 'vagas' ? 'var(--text-title)' : summary.title === 'respeito' ? '#28a745' : summary.title === 'irregular' ? '#dc3545' : Number(summary.percent) >= 0.66 ? '#dc3545' : Number(summary.percent) >= 0.33 ? '#ffc107' : '#28a745' }}
                >
                  {toPercent(summary.percent)}
                </span>
                <Title
                  size={5}
                  style={{ color: summary.title === 'vagas' ? 'var(--text-title)' : summary.title === 'respeito' ? '#28a745' : summary.title === 'irregular' ? '#dc3545' : Number(summary.percent) >= 0.66 ? '#dc3545' : Number(summary.percent) >= 0.33 ? '#ffc107' : '#28a745' }}
                >
                  {toNumber(summary.value)}
                </Title>
                <Title subtitle size={7}>{summary.title.toUpperCase()}</Title>
              </div>
            </Column>
          ))
        }
      </Column.Group>
      <ProgressGroup>
        <span style={{width:'33%'}}><b>Área</b></span>
        <span><b>Taxa</b></span>
        <span style={{width:'33%', textAlign:'right'}}><b>Vagas</b></span>
      </ProgressGroup>
      <hr className="hr"/>
      {
        vacanciesData !== null ? (
          vacanciesData?.map(area => (
            <div key={Math.random()} className="mb-4">
              <ProgressGroup busyOccupation={Number(area?.busyOccupation)}>
                <span style={{width:'33%'}}>
                  # {area?.area?.toUpperCase()}
                </span>
                <span 
                  style={{ 
                    width:'33%', 
                    textAlign:'center', 
                    color: Number(area?.totalVacancies) === 0 ? '#dc3545' : Number(area?.busyOccupation) >= 66 ? '#dc3545' : Number(area?.busyOccupation) >= 33 ? '#ffc107' : '#28a745',
                    fontSize: '1.1rem'
                  }}
                >
                  { toDecimal(area?.busyOccupation) }%
                </span>
                <span style={{width:'33%', textAlign:'right'}}>
                  <b>{ area?.totalVacanciesInUse }</b> / { area?.totalVacancies }
                </span>
              </ProgressGroup>
              <Progress 
                size='small'
                max={area?.totalVacancies} 
                value={area?.totalVacanciesInUse} 
                color={ Number(area?.totalVacancies) === 0 ? 'custom-red' : Number(area?.busyOccupation) >= 66 ? 'custom-red' : Number(area?.busyOccupation) >= 33 ? 'custom-yellow' : 'custom-green' } 
              />
            </div>
          ))
        ) : (
          <p>Erro ao obter os dados, tente novamente em alguns instantes.</p>
        )
      }
    </Box>
  );
}
