import { useState, useEffect } from 'react';
import { Card, Column } from "rbx";
import { ParkingService } from '../../../services/api-zad';
import GeocodeService from '../../../services/geocoder';
import { placaMask, unMask } from '../../../commons/masks';
import Controls from '../../shared/Controls/Controls';
import Autocomplete from '../../shared/Autocomplete';
import { CardHeader } from '../../shared/CardHeader';
import './styles.scss'
import { toast } from 'react-toastify';
import UserService from '../../../services/user';
import { env } from '../../../env';

export const AppStartParkingForm = ({ userData, goToMenu }) => {
  const [licensePlate, setLicensePlate] = useState('');
  const [addressName, setAddressName] = useState('');
  const [ruleName, setRuleName] = useState('');
  const [rules, setRules] = useState([]);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [amountCad, setAmountCad] = useState(false);
  const [stateButton, setStateButton] = useState('');

  const ruleSelected = rules.find(rule => rule.name === ruleName)

  useEffect(()=>{
    fetchRules();
  }, []);

  const fetchRules = async () => {
    try {
      const response = await ParkingService.regionRules();
      setRules(response.data.data);
    } catch(e) {
      console.log(e, ' - ', e.message);
    }
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setStateButton("loading");
    try {
      const body = {
        userId: userData.id,
        addressName: addressName,
        latitude: String(latitude),
        longitude: String(longitude),
        licensePlate: unMask(licensePlate),
        ruleName,
        amountCad,
        imei: env.IMEI_DEFAULT,
        firebaseToken: ''
      };
      const response = await ParkingService.parking(body);
      await UserService.reload();
      toast.success(response.data.message)
      goToMenu();
    } catch(e) {
      if (e.response.data)
        toast.error(`${e.response.status}: ${e.response.data.message}`)
      else
        toast.error(`${e.response.status}: ${e.response.data.errors}`)
    }
    setStateButton('');
  }

  const findAddressByGoogle = async () => {
    try {
      const response = await GeocodeService.fromAddress(`${addressName}, ${env.ZAD_CITY}, ${env.ZAD_STATE}`);
      const { lat, lng } = response.results[0].geometry.location;
      setLatitude(lat);
      setLongitude(lng);
      setAddressName(response.results[0].formatted_address.toUpperCase());
    } catch(error) {
      toast.error(error)
      console.error(error);
    }
  }

  const optionsForSelect = []
  for (let i = 2; i <= ruleSelected?.maxCad; i++) {
    optionsForSelect.push({ value: i, description: `${i} Cartões` })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader handleClose={goToMenu}>Estacionar veículo</CardHeader>
        <Card.Content>
          <Autocomplete
            suggestions={userData.vehicles.map(v => placaMask(v.licensePlate))}
            label="Placa do veículo"
            name="licensePlate"
            placeholder="ABC-1234"
            value={licensePlate}
            setValue={value => setLicensePlate( placaMask(value) )}
            autoFocus
            required
          />
          <hr className="hr" />
          <Controls.TextFieldWithSearch 
            label="Local do estacionamento"
            name="description"
            placeholder='Pesquise no Google Maps'
            value={addressName}
            onChange={e => setAddressName(e.target.value.toUpperCase())}
            onClick={findAddressByGoogle}
            required
          />
          <Column.Group style={{marginBottom:0}}>
            <Column style={{paddingBottom:0}}>
              <Controls.TextField 
                label="Latitude"
                name="latitude"
                placeholder='Latitude'
                value={latitude}
                required
                disabled
              />
            </Column>
            <Column>
              <Controls.TextField 
                label="Longitude"
                name="longitude"
                placeholder='Longitude'
                value={longitude}
                required
                disabled
              />
            </Column>
          </Column.Group>
          <Column.Group className="mb-0">
            <Column className="pb-0">
              <Controls.SelectField
                label="Regra"
                name="ruleName"
                value={ruleName}
                onChange={e => {
                  setRuleName(e.target.value)
                  setAmountCad('')
                }}
                options={ rules.map(rule => ({ value: rule.name, description: rule.name })) }
                required
              />
            </Column>
            <Column className="pb-0">
              <Controls.SelectField
                label="Quantidade de CAD"
                name="amountCad"
                value={amountCad}
                onChange={e => setAmountCad(e.target.value)}
                options={
                  ruleSelected?.renewable
                    ? [{ value: 1, description: "1 Cartão" }, ...optionsForSelect]
                    : [{ value: 1, description: "1 Cartão" }]
                }
                required
              />
            </Column>
            {/* <Column>
              <Label>Com Renovação ?</Label>
              <Column.Group style={{paddingBottom:0}} breakpoint={'mobile'}>
                <Column style={{paddingBottom:0}}>
                  <Button 
                    className={ renew ? 'button-renew' : 'button-renew active' }
                    type="button" 
                    color="custom-purple" 
                    outlined
                    fullwidth
                    onClick={() => setRenew(!renew)}
                  >
                    NÃO
                  </Button>
                </Column>
                <Column>
                  <Button 
                    className={ renew ? 'button-renew active' : 'button-renew' }
                    type="button" 
                    color="custom-purple" 
                    outlined
                    fullwidth
                    onClick={() => setRenew(!renew)}
                  >
                    SIM
                  </Button>
                </Column>
              </Column.Group>
            </Column> */}
          </Column.Group>
          <hr className="hr" />
          <Controls.ActionButtons
            linkName="Fechar" 
            handleClickBack={goToMenu} 
            buttonState={stateButton} 
            buttonName='Estacionar'
          />
        </Card.Content>
      </Card>
    </form>
  )
}
