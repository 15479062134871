import Modal from 'react-modal';
import { Container } from './styles';
import { ModalHeader } from '../../shared/ModalHeader';

export function VehicleImageModal({ isOpen, onRequestClose, vechicleImageUrl }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        <ModalHeader title='Imagem do Veículo' handleClose={onRequestClose} />

        <img src={vechicleImageUrl} alt='Imagem do Veículo' width={340} />
      </Container>
    </Modal>
  )
}
