import { useState } from 'react'
import Modal from 'react-modal'
import { useFetch } from '../../../../hooks/useFetch'
import { API_URL } from '../../../../services/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { DriverForm } from './form'
import { Driver } from './driver'
import { LoadingModal } from '../../../Modals/LoadingModal'
import { ModalHeader } from '../../../shared/ModalHeader'

export const DriverModal = ({ isOpen, onRequestClose }) => {
  const [modal, setModal] = useState({})
  const [search, setSearch] = useState('')

  const { 
    response: driversResponse,
    load: driversLoad,
    refresh: driversRefresh
  } = useFetch(`${API_URL}/drivers`)

  const drivers = driversResponse?.data || []

  const driversFiltered = search.length > 0 
    ? drivers.filter(driver => driver.name.toUpperCase().includes(search.toUpperCase()))
    : drivers

  // console.log('DriverModal:', 'Rendered')

  const closeModal = () => setModal({})

  const openDriverForm = () => {
    setModal({
      show: <DriverForm
        isOpen={true}
        onRequestClose={closeModal}
        driversRefresh={driversRefresh}
      />
    })
  }

  if (driversLoad) {
    return <LoadingModal />
  }

  return (
    <>
      { modal.show }

      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <ModalHeader title='Meus Motoristas' handleClose={onRequestClose}>
          <div
            className='is-flex p-2 is-align-items-center ml-3 is-clickable'
            style={{ borderRadius:'100%', backgroundColor:'var(--blue)' }}
            onClick={openDriverForm}
          >
            <FontAwesomeIcon icon={faPlus} style={{ color:'var(--shape)', fontSize:'0.9rem' }} />
          </div>
        </ModalHeader>

        {
          drivers.length > 8 &&
          <div className='is-flex mt-0 py-2 px-0'>
            <input
              className="input"
              name="search"
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder="Pesquisar por nome"
              autoFocus
              autoComplete='off'
            />
          </div>
        }

        <div>
          {driversFiltered.reverse().map((driver, index) => (
              <Driver key={'driver_' + index} driver={driver} driversRefresh={driversRefresh} destroy />
          ))}
        </div>
      </Modal>
    </>
  )
}
