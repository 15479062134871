import { Panel, Card } from "rbx"
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons"

const MenuItems = ({ setItemSelected, links, linksExtras }) => {
  return (
    <>
      <Panel className='has-background-white'>
        <Card.Header>
          <Card.Header.Title>O que você quer fazer ?</Card.Header.Title>
        </Card.Header>
        {
          links.map((link, index) => (
            <Panel.Block key={index+10}>
              <Panel.Icon>
                <FontAwesomeIcon icon={faEllipsisV} />
                <FontAwesomeIcon icon={faEllipsisV} />
              </Panel.Icon>
              <Link style={{color: '#0033cc'}} to="#" onClick={()=>setItemSelected(link.item)}>{link.description}</Link>
            </Panel.Block>
          ))
        }
      </Panel>

      {/* <Panel className='has-background-white'>
        <Card.Header>
          <Card.Header.Title>Extras</Card.Header.Title>
        </Card.Header>
        {
          linksExtras.map((linkExtra, index) => (
            <Panel.Block key={index+100}>
              <Panel.Icon>
                <FontAwesomeIcon icon={faEllipsisV} />
                <FontAwesomeIcon icon={faEllipsisV} />
              </Panel.Icon>
              <Link style={{color: '#0033cc'}} to={linkExtra.url}>{linkExtra.description}</Link>
            </Panel.Block>
          ))
        }
      </Panel> */}
    </>
  )
}

export default MenuItems
