import { useEffect, useState } from 'react'
import { Box, Button, Control, Field, Icon, Modal, Progress, Table } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { faEllipsisV, faSearch, faSpinner, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useFetch } from '../../../hooks/useFetch'
import { API_URL } from '../../../services/api'
import { VacancyService } from '../../../services/api-zad'
import { PaginationCustom } from '../../shared/PaginationCustom'
import { VacancyDetail } from '../detail'
import { VacancyForm } from '../form'
import { VacancySearch } from '../search'
import { VacancyReport } from '../report'

const configModal = { active: false, page: '', report: false }

export const VacancyList = () => {
  const [filter, setFilter] = useState({})
  const [items, setItems] = useState([])
  const [pageOfItems, setPageOfItems] = useState([])
  const [activeProgress, setActiveProgress] = useState(true)
  const [disableLoading, setDisableLoading] = useState(null)
  const [filterLoading, setFilterLoading] = useState(null)
  const [modal, setModal] = useState(configModal)
  const [reloadList, setReloadList] = useState('')

  const { response: areasResponse } = useFetch(`${API_URL}/areas`)

  // console.log('VacancyList - reloadList: ', reloadList)

  useEffect(() => {
    fetchVacancy()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[reloadList, filter])
  
  const updateList = () => setTimeout(() => setReloadList(moment().format()), 2000);

  const fetchVacancy = async () => {
    try {
      const params = new URLSearchParams(filter)
      const response = await VacancyService.index(params)
      setItems(response.data.data);
    } catch(e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setActiveProgress(false)
    setDisableLoading(null)
    setFilterLoading(null)
  }

  const disableVacancy = async (item) => {
    try {
      if (window.confirm('Confirma a desativação do Estacionamento?')) {
        setDisableLoading(item)
        const response = await VacancyService.disable(item.id)
        toast.success(response.data.message)
        updateList()
      }
    } catch (e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
  }

  const setPageSearch = () => { 
    setModal({
      active: true, 
      page: <VacancySearch filter={filter} setFilter={setFilter} closeModal={closeModal} setFilterLoading={setFilterLoading} />,
      report: false
    }) 
  }

  const setPageDetail = (item) => {
    setModal({
      active: true,
      page: <VacancyDetail itemSelected={item} closeModal={closeModal} />,
      report: false
    })
  }

  const setPageNew = () => {
    setModal({
      active: true,
      page: <VacancyForm closeModal={closeModal} updateList={updateList} newItem={true} areas={areasResponse.data} />,
      report: false
    })
  }

  const setPageEdit = (item) => {
    setModal({
      active: true,
      page: <VacancyForm itemSelected={item} closeModal={closeModal} updateList={updateList} newItem={false} areas={areasResponse.data} />,
      report: false
    })
  }

  const setPageReport = (items) => {
    setModal({
      active: true,
      page: <VacancyReport items={items} />,
      report: true
    })
  }

  const closeModal = () => setModal(configModal)

  const onChangePage = (ppageOfItems) => setPageOfItems(ppageOfItems)

  const tableHeader = () => (
    <Table.Row>
      <Table.Heading textAlign='centered' style={{ width:'10rem' }}>Área</Table.Heading>
      <Table.Heading>Local</Table.Heading>
      <Table.Heading textAlign='centered' style={{ width:'10rem' }}>Referências</Table.Heading>
      <Table.Heading textAlign='right' style={{ width:'10rem' }}>Vagas</Table.Heading>
      <Table.Heading textAlign='centered' style={{ width:'10rem' }}>Ações</Table.Heading>
    </Table.Row>
  )

  const tableBody = () => {
    return (pageOfItems.length === 0) ? (
      <Table.Row>
        <Table.Cell colSpan="9">
          { (activeProgress) ? <Progress size='small' color='custom-purple' /> : 'Nenhuma vaga foi encontrada.' }
        </Table.Cell>
      </Table.Row>
    ) : (
      pageOfItems.map((item) => (
        <Table.Row key={item.id} style={{ color: item.disabled ? 'red' : '' }}>
          <Table.Cell textAlign='centered'>{item.area}</Table.Cell>
          <Table.Cell>{item.street}</Table.Cell>
          <Table.Cell textAlign='centered'>
            {
              item.references.length > 0 && (
                <Button 
                  size=''
                  rounded
                  color='light'
                  style={{ height: '1.1rem' }}
                  onClick={() => setPageDetail(item)}
                >
                  <FontAwesomeIcon icon={faEllipsisV} className='fa-rotate-90' />
                </Button>
              )
            }
          </Table.Cell>
          <Table.Cell textAlign='right'>{item.quantity}</Table.Cell>
          <Table.Cell textAlign='centered'>
            <Icon size={'medium'} className='is-clickable ml-2' title='Editar' onClick={() => setPageEdit(item)}>
              <FontAwesomeIcon icon={faEdit} />
            </Icon>
            {
              !item.disabled && (
                <Icon size={'medium'} className='is-clickable ml-2' title='Desativar' onClick={() => disableVacancy(item)}>
                  {
                    disableLoading?.id === item.id ? (
                      <FontAwesomeIcon icon={faSpinner} pulse />
                    ) : (
                      <FontAwesomeIcon icon={faTrashAlt} />
                    )
                  }
                </Icon>
              )
            }
          </Table.Cell>
        </Table.Row>
      ))
    )
  }

  return (
    <>
      <Box>
        <div className='is-flex is-justify-content-space-between'>
          <Field kind="group" multiline className='is-align-items-center mb-0'>
            <Control className='mb-0'>
              <Button size={'small'} rounded onClick={setPageSearch} state={filterLoading} title='Pesquisar'>
                <FontAwesomeIcon icon={faSearch} /> <span className='ml-2'>Filtrar</span>
              </Button>
            </Control>
            {
              Object.keys(filter).length > 0 && (
                <Control className='mb-0'>
                  <Button type='button' size={'small'} rounded onClick={() => setFilter({})} title='Limpar pesquisar'>
                    <FontAwesomeIcon icon={faTimes} /> <span className='ml-2'>Limpar Filtro</span>
                  </Button>
                </Control>
              ) 
            }
            <Button type='button' size="small" color="custom-red" rounded onClick={() => setPageReport(items)}>
            <FontAwesomeIcon icon={faFilePdf} /> <span className='ml-2'>Exportar PDF</span>
            </Button>
          </Field>
          <Button type='button' size="small" color="custom-purple" rounded onClick={() => setPageNew()}>
            Novo
          </Button>
        </div>
        <div className="table-container">
          <hr className='hr my-3' />
          <Table hoverable fullwidth>
            <Table.Head>
              { tableHeader() }
            </Table.Head>
            <Table.Body>
              { tableBody() }
            </Table.Body>
          </Table>
        </div>
        <PaginationCustom items={items} onChangePage={onChangePage} />
      </Box>
      <Modal closeOnBlur={true} closeOnEsc={true} active={modal.active} onClose={closeModal}>
        <Modal.Background onClick={closeModal} />
        <Modal.Content style={modal.report ? { width: '1200px' } : {}}>
          { modal.page }
        </Modal.Content>
      </Modal>
    </>
  )
}
