import { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { API_URL } from '../../../../../services/api';
import { Container, InputGrid } from './styles';
import { ModalHeader } from '../../../../shared/ModalHeader';

export function DeleteVehicleModal({
  isOpen,
  onRequestClose,
  vehicleId: id,
  vehicleLicensePlate: plate,
}) {
  const [requestSuccess, setRequestSuccess] = useState('');
  // const [loading, setLoading] = useState('');

  const handleDeleteVehicle = async (e) => {
    e.preventDefault();
    // setLoading(true);

    const res = await fetch(`${API_URL}/vehicles/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token'),
      },
    });

    const response = await res.json();
    // console.log(response);

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setRequestSuccess(response.message);
      setTimeout(() => window.location.reload(), 3000)
    }

    // setLoading(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay z-index-5"
      className="react-modal-content"
    >
      <Container onSubmit={handleDeleteVehicle}>
        <ModalHeader title='Deletar Veículo' handleClose={onRequestClose} />

        {requestSuccess ? (
          <p>
            Veículo <span>{plate}</span> excluído com sucesso!
          </p>
        ) : (
          <p>
            Deseja mesmo excluir o veículo <span>{plate}</span>?
          </p>
        )}

        <hr className='hr'></hr>

        {requestSuccess ? (
          <button className="secondButton" onClick={onRequestClose}>
            Fechar
          </button>
        ) : (
          <InputGrid>
            <button type="submit">Deletar</button>
            <button className="secondButton" onClick={onRequestClose}>
              Não Deletar
            </button>
          </InputGrid>
        )}
      </Container>
    </Modal>
  );
}
