import styled from 'styled-components';

export const Container = styled.div`
  // max-width: 1120px;
  margin: 0 auto;
  padding: 2rem;
  // margin-top: -10rem;
  border-radius: 0.25rem;
  background: var(--shape);

  strong {
    display: block;
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--text-body);
    // margin-top: 0.5rem;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & + header {
      margin-top: 1rem;
    }
  }

  div {
    background: var(--shape);
    padding: 2rem 2rem;
    border-radius: 0.25rem;
    color: var(--text-title);
    height: fit-content;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & + header {
        margin-top: 1rem;
      }
    }

    h6 {
      display: block;
      font-size: 1.5rem;
      font-weight: 600;
      color: #000;
    }

    p {
      display: block;
      font-size: 0.9rem;
      font-weight: 400;
      color: var(--text-body);
      margin-top: 1rem;
    }

    strong {
      display: block;
      font-size: 1rem;
      font-weight: 500;
      line-height: 2rem;

      span {
        font-size: 2.5rem;
        font-weight: 600;
        color: var(--blue);
      }
    }

    div {
      background: var(--container);
    }
  }

  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  div {
    padding: 0rem !important;
    background: var(--shape) !important;

    div {
      margin-top: 0rem !important;
      background: var(--shape) !important;

      #pagination-rows {
        margin-top: 1rem !important;
        padding-right: 24px !important;
      }
    }

    td > div {
      background: transparent !important;
    }
  }
`;

export const ContainerForm = styled.form`
  max-width: 560px;
  margin: 0 auto;
  padding: 2rem;
  // margin-top: -10rem;
  border-radius: 0.25rem;
  background: var(--shape);

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & + header {
      margin-top: 1rem;
    }
  }

  h2 {
    color: var(--text-title);
    font-size: 1.5rem;
  }

  strong {
    display: block;
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--text-body);
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  input {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    border-radius: 0.25rem;

    border: 1px solid #d7d7d7;
    background: var(--input-background);

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }

    & + input {
      margin-top: 1rem;
    }
  }

  /* select {
    -webkit-appearance: none;
    -moz-appearance: none;

    &:invalid {
      color: var(--text-body);
    }
  } */

  input,
  select {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    border-radius: 0.25rem;

    border: 1px solid #d7d7d7;
    background: var(--input-background);

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }

    & + input,
    & + select {
      margin-top: 1rem;
    }
  }

  button[type='submit'] {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    background: var(--green);
    color: #fff;
    border-radius: 0.25rem;
    border: 0;
    font-size: 1rem;
    margin-top: 1.5rem;
    font-weight: 600;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const InputGrid = styled.div`
  display: block;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;

  input + select,
  input + input {
    margin-top: 0;
  }

  input + input {
    margin-bottom: 1rem;
  }
`;

export const IconButton = styled.button`
  height: 34px;
  padding: 8px 20px;
  border: none;
  background: transparent;
`;
