import { useState } from 'react';
import TextFieldMask from './TextFieldMask';
import { cpfOrCnpjValidate } from '../../../commons/validates';
import { unMask } from '../../../commons/masks';
import { toast } from 'react-toastify';

const Mask = { CPF: '999.999.999-99999', CNPJ: '99.999.999/9999-99' };

const CpfCnpjField = ({ name, label, value, onChange, ...other }) => {
  const [ mask, setMask ] = useState( unMask(value).length <= 11 ? Mask.CPF : Mask.CNPJ );
  const [ isCpfValid, setIsCpfValid ] = useState(true)

  const handleBlur = (e) => {
    const [isValid, message] = cpfOrCnpjValidate(e.target.value)
    setIsCpfValid(isValid)
    if (!isValid) toast.error(message)
  }

  return (
    <TextFieldMask 
      label={label}
      name={name}
      mask={mask}
      value={value}
      onChange={e => {
        setMask( unMask(e.target.value).length <= 11 ? Mask.CPF : Mask.CNPJ )
        onChange(e)
      }}
      onBlur={handleBlur}
      className={ isCpfValid ? '' : 'is-danger'}
      {...other}
    />
  )
}

export default CpfCnpjField;
