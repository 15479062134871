import { Column, Tag } from 'rbx'
import { Timer } from '../../shared/Timer';

export const VehiclesList = ({ userData }) => {
  const vehicles = userData.vehicles

  return (
    <>
      <p>
        Veículos: {userData.quantityVehicles} 
        <span>Estacionados: {userData.parkedVehicles}</span>
      </p>
      <ul>
        {vehicles?.map((vehicle, index) => (
          <li key={index}>
            <Column.Group style={{display:'flex', padding:'0.5rem', justifyContent:'space-between', alignItems:'center'}}>
              { vehicle.licensePlate }
              {
                vehicle.parked &&
                <Tag className="ml-2" color="custom-purple" size="medium" style={{minWidth:'8.5rem'}} >
                  <Timer
                    startDate={vehicle.startedParking}
                    paidMinutes={vehicle.paidTime}
                    reverse={true}
                  />
                </Tag>
              }
            </Column.Group>
          </li>
        ))}
      </ul>
    </>
  )
}
