import { FinancialAppPdv } from "../../components/Adm/FinancialAppPdv";
import { Layout } from "../../components/Layout";

export default function FinancialAppPdvPage() {
  return (
    <Layout>
      <FinancialAppPdv />
    </Layout>
  )
}
