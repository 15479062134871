import { Column } from 'rbx'
import { Layout } from '../../../components/Layout'
import { UserRemove } from '../../../components/shared/UserRemove'

export const UserRemovePage = () => {
  return (
    <Layout>
      <Column.Group className="animeLeft" centered>
        <Column size={5}>
          <UserRemove />
        </Column>
      </Column.Group>
    </Layout>
  )
}
