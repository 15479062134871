import { Box } from 'rbx'
import { ReportEstacionamentos } from '../../Reports/Estacionamentos'

export const VacancyReport = ({ items }) => {

  const setIframe = (dataUrl) => {
    const iframe = document.querySelector('#report')
    iframe.src = dataUrl
  }

  ReportEstacionamentos(items, setIframe)

  return (
    <Box>
      <iframe id='report' title='report' width='100%' height='600px'></iframe>
    </Box>
  )
}
