import styled from 'styled-components';

export const Container = styled.div`
  input {
    width: 100%;
    padding: 0 0.75rem;
    border-radius: 0.25rem;
    height: 2.5rem;

    border: 1px solid #d7d7d7;
    background: var(--background);

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }
  }

  header {
    padding: 0.7rem 0;
  }

  div.box {
    border: 1px solid rgb(210 210 210 / 1);
    border-radius: 0.5rem;
  }

  div.vehicle {
    background: var(--shape) !important;
    margin-top: 0rem !important;
    margin-bottom: 0.8rem !important;
  }

  button.schedule,
  button.schedule2,
  button.parking {
    border: 0;
    transition: filter 0.2s;
    
    &:hover {
      filter: brightness(0.9);
    }
  }

  button.schedule {
    background: var(--green);
    color: var(--shape);
  }

  button.schedule2 {
    border: 2px solid var(--green);
    color: var(--green);
  }

  div {
    padding: 0.6rem 1.5rem;
    align-items: center;
    text-align: center;
    justify-content: space-between;

    strong {
      display: flex;
      justify-content: center;
    }
  }

  div + div {
    margin-top: 1rem;
  }

  span.timer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    border: 2px solid var(--blue);
    // background: var(--blue);
    border-radius: 1rem;
    color: var(--blue); // var(--shape);
    padding: 0.4rem 0;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    padding-bottom: 4rem !important;
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
    margin-top: -1rem;
    padding-top: 0 !important;

    header {
      background: var(--shape);
      border-bottom: 2px dashed rgb(210 210 210 / 1);
      padding: 0.7rem 0;
      position: sticky;
      top: 0;
      z-index: 2;
      margin-bottom: 1rem;
    }

    header + div {
      margin-top: 0rem;
    }
  }
`;

export const IconButton = styled.button`
  height: 34px;
  padding: 8px;
  border: none;
  background: transparent;
`;
