import { ActiveAccount } from './ActiveAccount'
import { AppDetail } from './Detail'
import { AppForgotPassword } from './ForgotPassword'
import { AppLock } from './Lock'
import { AppParkingHistoryList } from './ParkingHistoryList'
import { AppPurchaseHistoryList } from './PurchaseHistoryList'
import { AppSaleHistoryList } from './SaleHistoryList'
import { AppRenewParkingForm } from './RenewParkingForm'
import { AppResendCode } from './ResendCode'
import { AppSellCad } from './SellCad'
import { AppStartParkingForm } from './StartParkingForm'
import { AppUnlock } from './Unlock'
import { VehiclesList } from './VehiclesList'
import { VehicleHistoricList } from '../VehicleHistoric/list'

export const Controls = {
  ActiveAccount,
  AppDetail,
  AppForgotPassword,
  AppLock,
  AppParkingHistoryList,
  AppPurchaseHistoryList,
  AppSaleHistoryList,
  AppRenewParkingForm,
  AppResendCode,
  AppSellCad,
  AppStartParkingForm,
  AppUnlock,
  VehiclesList,
  VehicleHistoricList,
}
