import { Container, Group } from "./styles";
import { SearchVehicle } from "../../Monitor/SearchVehicle";
import { ConfirmedIrregularityList } from "../ConfirmedIrregularity/list";


export function DashboardAgent() {
  return (    
    <Container className="animeLeft">
      <Group>
        <SearchVehicle registrarIrregularidade={false} />
      </Group>
      <Group>
        <ConfirmedIrregularityList />
      </Group>
    </Container>
  );
}
