import { useState } from 'react'
import { Box, Button, Icon, Modal, Progress, Table } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { useFetch } from '../../../hooks/useFetch'
import { API_URL } from '../../../services/api'
// import { PaginationCustom } from '../../shared/PaginationCustom'
import { DomainBlacklistForm } from '../form'
import { DomainBlacklistService } from '../../../services/api-zad'
import { toast } from 'react-toastify'
import { faSpinner, faTrashAlt, faUsersSlash } from '@fortawesome/free-solid-svg-icons'
import Controls from '../../shared/Controls/Controls'

const configModal = { active: false, page: '' }

export const DomainBlacklistList = () => {
  // const [pageOfItems, setPageOfItems] = useState([])
  const [modal, setModal] = useState(configModal)
  const [destroyLoading, setDestroyLoading] = useState(null)
  const [blockLoading, setBlockLoading] = useState(null)
  const [search, setSearch] = useState('')

  const { 
    response: domainBlacklistResponse,
    load: domainBlacklistLoad,
    refresh: domainBlacklistrefresh
  } = useFetch(`${API_URL}/domain-blacklist`)

  const items = domainBlacklistResponse?.data || []

  const itemsFiltered = search.length > 0 
    ? items.filter(item => item.domain.includes(search))
    : items

  // console.log('DomainBlacklistList', 'Rendered')

  const destroy = async (item) => {
    try {
      if (window.confirm('Confirma a exclusão do Domínio?')) {
        setDestroyLoading(item)
        const response = await DomainBlacklistService.destroy(item.id)
        toast.success(response.data.message)
        domainBlacklistrefresh()
      }
    } catch (e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setDestroyLoading(null)
  }

  const blockAccounts = async (item) => {
    try {
      if (window.confirm('Confirma o bloqueio das contas deste Domínio?')) {
        setBlockLoading(item)
        const response = await DomainBlacklistService.blockAccounts(item.id)
        toast.success(response.data.message)
      }
    } catch (e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setBlockLoading(null)
  }

  const setPageForm = (item) => {
    setModal({
      active: true,
      page: <DomainBlacklistForm itemSelected={item||{}} closeModal={closeModal} refreshList={domainBlacklistrefresh} />
    })
  }

  const closeModal = () => setModal(configModal)

  // const onChangePage = (ppageOfItems) => setPageOfItems(ppageOfItems)

  const tableHeader = () => (
    <Table.Row>
      <Table.Heading>Domínio</Table.Heading>
      <Table.Heading></Table.Heading>
    </Table.Row>
  )

  const tableBody = () => {
    return (itemsFiltered.length === 0) ? (
      <Table.Row>
        <Table.Cell colSpan="9">
          { (domainBlacklistLoad) ? <Progress size='small' color='custom-purple' /> : 'Nenhum domínio foi encontrado.' }
        </Table.Cell>
      </Table.Row>
    ) : (
      itemsFiltered.map((item) => (
        <Table.Row key={item.id}>
          <Table.Cell>{item.domain}</Table.Cell>
          <Table.Cell textAlign='right'>
            <Icon size={'medium'} className='is-clickable ml-2' title='Editar' onClick={() => setPageForm(item)}>
              <FontAwesomeIcon icon={faEdit} />
            </Icon>
            <Icon size={'medium'} className='is-clickable ml-2' title='Excluir' onClick={() => destroy(item)}>
              {
                destroyLoading?.id === item.id ? (
                  <FontAwesomeIcon icon={faSpinner} pulse />
                ) : (
                  <FontAwesomeIcon icon={faTrashAlt} />
                )
              }
            </Icon>
            <Icon size={'medium'} className='is-clickable ml-2' title='Bloquear contas' onClick={() => blockAccounts(item)}>
              {
                blockLoading?.id === item.id ? (
                  <FontAwesomeIcon icon={faSpinner} pulse />
                ) : (
                  <FontAwesomeIcon icon={faUsersSlash} />
                )
              }
            </Icon>
          </Table.Cell>
        </Table.Row>
      ))
    )
  }

  return (
    <>
      <Box>
        <div className='is-flex is-justify-content-space-between'>
          <Controls.TextField
            name="search"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Pesquisar por domínio"
            autoFocus
            autoComplete='off'
          />
          <Button type='button' size="small" color="custom-purple" rounded onClick={setPageForm}>
            Novo
          </Button>
        </div>
        <hr className='hr my-3' />
        <div className="table-container">
          <Table hoverable fullwidth>
            <Table.Head>
              { tableHeader() }
            </Table.Head>
            <Table.Body>
              { tableBody() }
            </Table.Body>
          </Table>
        </div>
        {/* <PaginationCustom items={items} onChangePage={onChangePage} /> */}
      </Box>
      <Modal closeOnBlur={true} closeOnEsc={true} active={modal.active} onClose={closeModal}>
        <Modal.Background onClick={closeModal} />
        <Modal.Content>
          { modal.page }
        </Modal.Content>
      </Modal>
    </>
  )
}
