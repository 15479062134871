import { useState } from 'react'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { IconButton, IconButtonContainer } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const visible = {
  on:  {visible: true, type: "text", icon: faEye},
  off: {visible: false, type: "password", icon: faEyeSlash}
}

export const PasswordInput = ({ name, label, value, onChange, ...other }) => {
  const [status, setStatus] = useState(visible.off);

  const toggle = () => setStatus(status.visible ? visible.off : visible.on)

  return (
    <div>
      <input
        type={status.type}
        name={name}
        placeholder='●●●●●●'
        pattern=".{6,12}"
        title="Informe de 6 a 12 caracteres"
        maxLength="12"
        value={value}
        onChange={onChange}
        {...other}
      />
      <IconButtonContainer>
        <IconButton type="button" onClick={toggle}>
          <FontAwesomeIcon icon={status.icon} />
        </IconButton>
      </IconButtonContainer>
    </div>
  )
}
