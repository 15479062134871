import { Field, Label, Control, Select } from "rbx";

const SelectField = (props) => {
  const { name, label, optionText='Selecione uma opção', value, onChange, options=[], ...other } = props;

  return (
    <Field>
      {(label) && <Label>{label}</Label>}
      <Control>
        <Select.Container fullwidth>
          <Select 
            name={name} 
            value={value} 
            onChange={onChange}
            style={{backgroundColor: "var(--background)"}}
            {...other}
          >
            <Select.Option value="" disabled defaultValue hidden>{optionText}</Select.Option>
            {
              options.map(
                (item, index) => (<Select.Option key={index} value={item.value}>{item.description}</Select.Option>)
              )
            }
          </Select>
        </Select.Container>
      </Control>
    </Field>
  )

}

export default SelectField;
