import styled from 'styled-components';

export const ContainerForm = styled.form`
  border-radius: 0.25rem;
  background: var(--shape);

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & + header {
      margin-top: 1rem;
    }
  }

  h2 {
    color: var(--text-title);
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  h3 {
    color: var(--text-title);
    font-size: 1.3rem;
    /*margin: 2rem 0 1rem 0;*/
  }

  h4 {
    color: var(--text-title);
    font-size: 1rem;
    margin: 0.5rem 0 -1rem 0;
    text-align: center;
    font-weight: 400;
  }

  span {
    color: var(--blue);
    font-size: 1rem;
    font-weight: 600;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input,
  textarea,
  select {
    width: 100%;
    padding: 0 1.5rem;
    height: 3rem;
    border-radius: 0.25rem;

    border: 1px solid #d7d7d7;
    background: var(--input-background);

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }

    & + input,
    & + textarea,
    & + select {
      margin-top: 1rem;
    }
  }

  textarea + .counter {
    margin-top: -5px !important;
  }

  button[type='submit'] {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    background: var(--green);
    color: #fff;
    border-radius: 0.25rem;
    border: 0;
    font-size: 1rem;
    margin-top: 1.5rem;
    font-weight: 600;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  .secondButton {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    background: var(--blue);
    color: #fff;
    border-radius: 0.25rem;
    border: 0;
    font-size: 1rem;
    margin-top: 1.5rem;
    font-weight: 600;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const InfoContainer = styled.div`
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;

  img.responsive {
    width: 100%;
    height: auto;
    max-width: 410px;
  }

  @media (max-width: 425px) {
    grid-template-columns: 1fr;
    /*gap: 2rem;*/
    justify-content: center;
  }
`;

export const InfoContainer2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
`;

export const IconButton = styled.button`
  height: 34px;
  padding: 8px 20px;
  border: none;
  background: transparent;
`;
