import styled from "styled-components";

export const Container = styled.header`
  background: #4b6cb7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #182848,
    var(--blue),
    #4b6cb7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #182848,
    var(--blue),
    #4b6cb7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  @media (max-width: 768px) {
    position: sticky;
    top: 0;
  }
`;

export const Content = styled.div`
  max-width: 920px;
  margin: 0 auto;
  color: var(--background);
  max-height: 17rem;

  padding: 2rem 1rem 9rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  a img {
      max-height: 50px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    background: var(--green);
    border: 0;
    padding: 0.6rem 3rem;
    border-radius: 1rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }

    img {
      margin-left: 10px;
      }
    }
  }

  button.buy {
    padding: 0.6rem 1rem;
    width: 100%
  }

  button.park {
    background: var(--cian);
    padding: 0.6rem 1rem;
    width: 100%
  }

  div.header-logo {
    display: flex;
    flex-direction: column;

    .saldo {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-left: 4.8rem;
    }
  }

  div.header-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 250px;
    padding-top: 0.5rem;
  }

  img.logo {
    max-height: 4rem;
  }

  @media (max-width: 768px) {
    padding: 1rem 0.5rem 2.5rem;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    div.header-logo {
      align-items: center;
      justify-content: center;

      .saldo {
        display: block;
        margin-left: revert;
      }
    } 

    div.header-actions {
      flex-direction: row;
      max-width: 400px;
      padding-top: 0;
      justify-content: center;
    }

    img.logo {
      max-height: 33px;
    }

    button {
      font-weight: 500;
      padding: 0.6rem 5rem;
      border-radius: 1rem;
      box-shadow: rgb(0 0 0 / 0.10) 0px 10px 36px 0px, rgb(0 0 0 / 0.2) 2px 2px 0px 1px;
    }
  }
`;
