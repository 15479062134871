import { useState, useEffect } from 'react';
import { Title, Button, Field, Control, Card } from "rbx";
import { Link, Redirect } from "react-router-dom";
import UserService from '../../../services/user';
import UserModalList from '../../shared/UserModalList';
import { unMask } from '../../../commons/masks';
import { cpfOrCnpjValidate } from '../../../commons/validates';
import { searchTypes } from '../../../commons/constants';
import Controls from '../../shared/Controls/Controls';
import { toast } from 'react-toastify';

const AppFindForm = ({ cpf='' }) => {
  const [search, setSearch] = useState('');
  const [searchType, setSearchType] = useState('cpfCnpj');
  const [usersFound, setUsersFound] = useState([]);

  const [redirectToApp, setRedirectToApp] = useState('');
  const [stateButton, setStateButton] = useState('');
  const [activeModal, setActiveModal] = useState(false);

  useEffect(() => {
    UserService.cleanStorage();
    setSearch(cpf)
  }, [cpf])

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setStateButton("loading");
    try {
      const validate = cpfOrCnpjValidate(search);
      if (searchType==='cpfCnpj' && !validate[0]) {
        toast.error(validate[1])
      } else {
        const response = await UserService.findBy(searchType, searchType==='cpfCnpj' ? unMask(search) : search);
        const users = response.data.data
        if (Array.isArray(users)) {
          if (users.length > 0) {
            setActiveModal(true);
            setUsersFound(response.data.data);  
          } else {
            toast.error('Usuário não encontrado.')
          }
        } else {
          selectUser(users);
        }
      }
    } catch(e) {
      if (e.response) {
        toast.error(e.response.data.message)
      } else {
        toast.error(e.message)
      }
    }
    setStateButton('');
  }
  
  const selectUser = async (user) => {
    UserService.select(user)
    setActiveModal(false)
    setRedirectToApp(true)
  }
  
  const handleChangeSearchType = (evt) => {
    setSearch('');
    setSearchType(evt.target.value);
  }

  if (redirectToApp) 
    return <Redirect to={{pathname: "/user/datasheet"}}/>;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Card>
          <Card.Content>
            <Title subtitle className="has-text-grey has-text-centered has-text-weight-medium">Localize ou registre um Usuário</Title>
            <Controls.SelectField
              name="searchType"
              value={searchType}
              onChange={handleChangeSearchType}
              options={searchTypes}
              required
            />
            {
              (searchType==='cpfCnpj') ?
                <Controls.CpfCnpjField 
                  name='search'
                  placeholder={`Localize por ${searchTypes.find(obj => obj.value===searchType)?.description||'...'}`}
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  autoFocus
                  className='has-text-centered'
                  required
                />
              :
                <Controls.TextField 
                  name='search'
                  placeholder={`Localize por ${searchTypes.find(obj => obj.value===searchType)?.description||'...'}`}
                  pattern={searchTypes.find(obj => obj.value===searchType)?.pattern||''}
                  title={searchTypes.find(obj => obj.value===searchType)?.title||''}
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  autoFocus
                  className='has-text-centered'
                  required
                />
            }
            <Field>
              <Control>
                <Button className="has-text-weight-medium" state={stateButton} color="custom-purple" fullwidth>Localizar Usuário</Button>
              </Control>
            </Field>
            <Field>
              <Control>
                <Link to={`/user/new/${searchType==='cpfCnpj' ? unMask(search) : ''}`} className="button is-fullwidth has-text-custom-purple has-text-weight-medium is-outlined">
                  Registrar
                </Link>
              </Control>
            </Field>
            <div className="has-text-grey has-text-centered mb-2">ou</div>
            <Field>
              <Control>
                <Link to='/user/remove' className="button is-fullwidth has-text-weight-medium is-outlined is-danger">
                  Remover inativos
                </Link>
              </Control>
            </Field>
          </Card.Content>
        </Card>
      </form>
      <UserModalList activeModal={activeModal} setActiveModal={setActiveModal} listUsers={usersFound} selectUser={selectUser} userType="Usuário" />
    </>
  )
}

export default AppFindForm
