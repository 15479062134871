import { Notification } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import './styles.css'

export const FreeTimeNotification = ({ isFreeTime=false }) => {
  return (
    isFreeTime && (
      <Notification className='is-info is-light is-free-time py-2 px-4 mb-3' textAlign='centered'>
        <div className='is-size-5 has-text-weight-medium has-text-centered p-2'>
          <FontAwesomeIcon icon={faThumbsUp} className='mr-2' />
          Horário Livre
        </div>
        O estacionamento neste horário é livre. Você pode <b>AGENDAR</b> seu estacionamento 
        para o próximo dia e horário de funcionamento do Zona Azul.
      </Notification>
    )
  )
}
