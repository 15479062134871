import { useState } from 'react'
import { Link } from "react-router-dom";
import { Box, Button, Icon, Modal, Progress, Table } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { useFetch } from '../../../hooks/useFetch'
import { API_URL } from '../../../services/api'
// import { PaginationCustom } from '../../shared/PaginationCustom'
import { AdvertisingForm } from '../form'
// import { AdvertisingService } from '../../../services/api-zad'
import { toast } from 'react-toastify'
import { faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Controls from '../../shared/Controls/Controls'
import moment from 'moment'
import { getDescCategoryPost } from '../../../utils';

const configModal = { active: false, page: '' }

export const AdvertisingList = () => {
  // const [pageOfItems, setPageOfItems] = useState([])
  const [modal, setModal] = useState(configModal)
  const [destroyLoading, setDestroyLoading] = useState(null)
  const [search, setSearch] = useState('')

  const { 
    response: advertisingResponse,
    load: advertisingLoad,
    refresh: advertisingRefresh
  } = useFetch(`${API_URL}/posts`)

  const items = (advertisingResponse?.data?.ads || [])
    .concat(advertisingResponse?.data?.service || [])

  const itemsFiltered = search.length > 0 
    ? items.filter(item => item.title.includes(search))
    : items

  console.log('AdvertisingList', 'Rendered')

  const destroy = async (item) => {
    // try {
    //   if (window.confirm('Confirma a exclusão do Post?')) {
    //     setDestroyLoading(item)
    //     const response = await AdvertisingService.destroy(item.id)
    //     toast.success(response.data.message)
    //     advertisingRefresh()
    //   }
    // } catch (e) {
    //   toast.error(e.response?.data?.message || e.message)
    // }
    // setDestroyLoading(null)
    setDestroyLoading(null)
    toast.error('A exclusão está desativada')
  }

  const setPageForm = (item) => {
    setModal({
      active: true,
      page: <AdvertisingForm itemSelected={item||{}} closeModal={closeModal} refreshList={advertisingRefresh} />
    })
  }

  const closeModal = () => setModal(configModal)

  // const onChangePage = (ppageOfItems) => setPageOfItems(ppageOfItems)

  const tableHeader = () => (
    <Table.Row>
      <Table.Heading>Título</Table.Heading>
      <Table.Heading>Validade</Table.Heading>
      <Table.Heading>Categoria</Table.Heading>
      <Table.Heading>Fonte</Table.Heading>
      <Table.Heading></Table.Heading>
    </Table.Row>
  )

  const tableBody = () => {
    return (itemsFiltered.length === 0) ? (
      <Table.Row>
        <Table.Cell colSpan="9">
          { (advertisingLoad) ? <Progress size='small' color='custom-purple' /> : 'Nenhum Post foi encontrado.' }
        </Table.Cell>
      </Table.Row>
    ) : (
      itemsFiltered.map((item) => (
        <Table.Row key={item.id}>
          <Table.Cell>{item.title}</Table.Cell>
          <Table.Cell>{item.expirationDate ? moment(item.expirationDate).format('DD/MM/YYYY HH:mm') : '-'}</Table.Cell>
          <Table.Cell>{getDescCategoryPost(item.category)}</Table.Cell>
          <Table.Cell><Link style={{color: '#000'}} to={{pathname: item.source}} target={'_blank'}>{item.source}</Link></Table.Cell>
          <Table.Cell textAlign='right'>
            <Icon size={'medium'} className='is-clickable ml-2' title='Editar' onClick={() => setPageForm(item)}>
              <FontAwesomeIcon icon={faEdit} />
            </Icon>
            <Icon size={'medium'} className='is-clickable ml-2' title='Excluir' onClick={() => destroy(item)}>
              {
                destroyLoading?.id === item.id ? (
                  <FontAwesomeIcon icon={faSpinner} pulse />
                ) : (
                  <FontAwesomeIcon icon={faTrashAlt} />
                )
              }
            </Icon>
          </Table.Cell>
        </Table.Row>
      ))
    )
  }

  return (
    <>
      <Box>
        <div className='is-flex is-justify-content-space-between'>
          <Controls.TextField
            name="search"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Pesquisar por título"
            autoFocus
            autoComplete='off'
          />
          <Button type='button' size="small" color="custom-purple" rounded onClick={setPageForm}>
            Nova
          </Button>
        </div>
        <hr className='hr my-3' />
        <div className="table-container">
          <Table hoverable fullwidth>
            <Table.Head>
              { tableHeader() }
            </Table.Head>
            <Table.Body>
              { tableBody() }
            </Table.Body>
          </Table>
        </div>
        {/* <PaginationCustom items={items} onChangePage={onChangePage} /> */}
      </Box>
      <Modal closeOnBlur={true} closeOnEsc={true} active={modal.active} onClose={closeModal}>
        <Modal.Background onClick={closeModal} />
        <Modal.Content>
          { modal.page }
        </Modal.Content>
      </Modal>
    </>
  )
}
