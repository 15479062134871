import { FooterHome } from "../../components/AppPdv/FooterHome";
import { HeaderHome } from "../../components/AppPdv/HeaderHome";
import { env } from "../../env";
import { Container, Content, Cover } from "../../styles/styles";
import { RedirectTo } from "../../utils/redirectTo";
import WhatsappButton from "../../components/shared/WhatsappButton";
import { ToastContainer } from "react-toastify";

export default function LayoutHomePage({ children }) {
  return (
    <>
      <Container>
        <ToastContainer />
        <HeaderHome redirectTo={RedirectTo.home} />
        <Content id="topo">
          <Cover>
            <h1>Zona Azul <span>Digital</span> { env.ZAD_CITY }</h1>
            <p>A solução ideal para o estacionamento rotativo da sua cidade</p>
          </Cover>
          { children }
        </Content>
      </Container>
      <FooterHome />
      <WhatsappButton />
    </>
  );
}
