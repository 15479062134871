import { Layout } from '../../components/Layout'
import { AccountabilityList } from '../../components/Accountability/list'

export const AccountabilityPage = () => {
  return (
    <Layout controllerName='Prestação de Conta'>
      <AccountabilityList />
    </Layout>
  )
}
