import { Layout } from '../../components/Layout'
import { DomainBlacklistList } from '../../components/DomainBlacklist/list'

export const DomainBlacklistPage = () => {
  return (
    <Layout controllerName='Domínios Bloqueados'>
      <DomainBlacklistList />
    </Layout>
  )
}
