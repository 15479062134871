import { useEffect, useState } from 'react'
import { Box, Column } from 'rbx'
import { BoxHeader } from '../../shared/BoxHeader'
import Controls from '../../shared/Controls/Controls'
import { toast } from 'react-toastify'
import { RegionRuleService } from '../../../services/api-zad'
import moment from 'moment'

export const RegionRuleForm = ({ itemSelected={}, closeModal, refreshList }) => {
  const [id, setId] = useState('')
  const [baseTime, setBaseTime] = useState('')
  const [renewable, setRenewable] = useState(false)
  const [maxCad, setMaxCad] = useState(1)
  const [tolerance, setTolerance] = useState('')

  const [stateButton, setStateButton] = useState('')

  // console.log('RegionRuleForm', 'Rendered')

  useEffect(() => {
    if (itemSelected.id) {
      setId(itemSelected.id)
      setBaseTime(moment.utc(itemSelected.baseTime*60*1000).format('HH:mm'))
      setRenewable(itemSelected.renewable)
      setMaxCad(itemSelected.maxCad)
      setTolerance(moment.utc(itemSelected.tolerance*60*1000).format('HH:mm'))
    } else {
      setId('')
      setBaseTime('')
      setRenewable(false)
      setMaxCad(1)
      setTolerance('')
    }
  },[itemSelected])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setStateButton('loading')
    try {
      const response = id
        ? await RegionRuleService.update(id, { baseTime, renewable, maxCad, tolerance })
        : await RegionRuleService.create({ baseTime, renewable, maxCad, tolerance })
      toast.success(response.data.message)
      refreshList()
      closeModal()
    } catch (e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setStateButton('')
  }

  return (
    <Box>
      <BoxHeader title={id ? 'Editar regra: '+ itemSelected.name : 'Nova Regra'} handleClose={closeModal} />
      <form onSubmit={handleSubmit}>
        <Column.Group className='mb-0' breakpoint='mobile'>
          <Column>
            <Controls.TextFieldMask
              label="Quantas horas correspodem 1 CAD ?"
              name="baseTime"
              mask={'99:99'}
              value={baseTime}
              onChange={e => setBaseTime(e.target.value)}
              placeholder="00:00"
              autoFocus
              required
            />
          </Column>
          <Column>
            <Controls.SelectField
              label="Com Renovação?"
              name="renewable"
              value={renewable}
              onChange={e => {
                setRenewable(e.target.value==='true')
                if (e.target.value==='true'  && maxCad < 2) setMaxCad(2)
                if (e.target.value==='false' && maxCad > 1) setMaxCad(1)
              }}
              options={[{ value: false, description: 'NÃO' }, { value: true, description: 'SIM' }]}
              required
            />
          </Column>
        </Column.Group>
        <Column.Group className='mb-0' breakpoint='mobile'>
          <Column>
            <Controls.TextField
              label="Quantidade máxima de CAD"
              name="maxCad"
              type="number"
              min={ renewable ? 2 : 1}
              max={ !renewable ? 1 : 10}
              value={maxCad}
              onChange={e => setMaxCad(e.target.value)}
              required
            />
          </Column>
          <Column>
            <Controls.TextFieldMask
              label="Tempo de tolerância para devolução"
              name="tolerance"
              mask={'99:99'}
              value={tolerance}
              onChange={e => setTolerance(e.target.value)}
              placeholder="00:00"
              required
            />
          </Column>
        </Column.Group>
        
        <hr className='hr' />
        <Controls.ActionButtons
          linkName="Fechar"
          handleClickBack={closeModal}
          buttonState={stateButton}
          buttonName={id ? 'Atualizar' : 'Cadastrar'}
        />
      </form>
    </Box>
  )
}
