import { API_URL, Api } from './api';

export const ProblemService = {
  index: (params) => Api.get(`/problems?${params}`, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  answer: (id, body) => Api.patch(`/problems/${id}/answer`, body, { headers: {'Authorization': sessionStorage.getItem('token')} }),
}

export const GoogleMapsApi = {
  fromAddress: (address) => Api.get(`/google/place/autocomplete?address=${address}`, { headers: {'Authorization': sessionStorage.getItem('token')} })
}

export const ParkingService = {
  parking: (params) => Api.post('/adm/parkings', params, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  renew: (params) => Api.patch('/adm/parkings', params, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  regionRules: () => Api.get('/region-rules', { headers: {'Authorization': sessionStorage.getItem('token')} }),
}

export const HolidayService = {
  index: (filter) => Api.get(`/holidays?startDate=${filter.startDate||''}&endDate=${filter.endDate||''}`, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  create: (body) => Api.post('/holidays', body, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  update: (id, body) => Api.put(`/holiday/${id}`, body, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  disable: (id) => Api.delete(`/holiday/${id}`, { headers: {'Authorization': sessionStorage.getItem('token')} }),
}

export const VacancyService = {
  index: (params) => Api.get(`/vacancy?${params}`, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  create: (body) => Api.post('/vacancy', body, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  update: (id, body) => Api.put(`/vacancy/${id}`, body, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  disable: (id) => Api.delete(`/vacancy/${id}`, { headers: {'Authorization': sessionStorage.getItem('token')} }),
}

export const ReferenceService = {
  index: (params) => Api.get(`/references?${params}`, { headers: {'Authorization': sessionStorage.getItem('token')} }),
}

export const AreaService = {
  create: (body) => Api.post('/areas', body, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  update: (id, body) => Api.put(`/areas/${id}`, body, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  disable: (id) => Api.delete(`/areas/${id}`, { headers: {'Authorization': sessionStorage.getItem('token')} }),
}

export const VehicleService = {
  irregularities: (licensePlate, params) =>
    Api.get(`/vehicles/irregularities/${licensePlate}?${params}`, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  parkings: (licensePlate, params) =>
    Api.get(`/vehicles/parkings/${licensePlate}?${params}`, { headers: {'Authorization': sessionStorage.getItem('token')} })
}

export const AccountabilityService = {
  downloadFileContabilidade: (id) =>
    Api.post(`/accountability/download/file-contabilidade/${id}`, { }, { responseType: 'blob', headers: {'Authorization': sessionStorage.getItem('token')} }),

  downloadFileClient: (id) =>
    Api.post(`/accountability/download/file-client/${id}`, { }, { responseType: 'blob', headers: {'Authorization': sessionStorage.getItem('token')} }),

  generate: () => Api.post(`/accountability/generate`, { }, { headers: {'Authorization': sessionStorage.getItem('token')} }),
}

export const DataExtractionService = {
  downloadFileTransactions: (body) =>
    Api.post(`/data-extraction/transactions`, body, { responseType: 'blob', headers: {'Authorization': sessionStorage.getItem('token')} }),
}

export const TimeRuleService = {
  update: (id, body) => Api.put(`/time-rule/${id}`, body, { headers: {'Authorization': sessionStorage.getItem('token')} }),
}

export const RegionRuleService = {
  create: (body) => Api.post('/region-rules', body, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  update: (id, body) => Api.put(`/region-rules/${id}`, body, { headers: {'Authorization': sessionStorage.getItem('token')} }),
}

export const DomainBlacklistService = {
  create: (body) => Api.post('/domain-blacklist', body, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  update: (id, body) => Api.put(`/domain-blacklist/${id}`, body, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  blockAccounts: (id) => Api.post(`/domain-blacklist/${id}/block-accounts`, { }, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  destroy: (id) => Api.delete(`/domain-blacklist/${id}`, { headers: {'Authorization': sessionStorage.getItem('token')} }),
}

export const CardService = {
  create: (body) => Api.post('/cards', body, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  destroy: (id) => Api.delete(`/cards/${id}`, { headers: {'Authorization': sessionStorage.getItem('token')} }),
}

export const AdvertisingService = {
  create: (body) => Api.post('/posts', body, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  update: (id, body) => Api.put(`/posts/${id}`, body, { headers: {'Authorization': sessionStorage.getItem('token')} }),
}

export const DriverService = {
  create: (body) => Api.post(`/drivers`, body, { headers: {'Authorization': sessionStorage.getItem('token')} }),

  destroy: (id) => Api.delete(`/drivers/${id}`, { headers: {'Authorization': sessionStorage.getItem('token')} }),
}

export const ApiZad = {
  post: async (path, body = {}) => {
    const res = await fetch( API_URL + path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    });

    return {
      res,
      response: await res.json()
    }
  }
}
