import { useState } from 'react'
import { Box, Icon, Modal, Progress, Table } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import moment from 'moment'
import { useFetch } from '../../../hooks/useFetch'
import { API_URL } from '../../../services/api'
import { PaginationCustom } from '../../shared/PaginationCustom'
import { TimeRuleForm } from '../form'

const configModal = { active: false, page: '', report: false }

export const TimeRuleList = () => {
  const [pageOfItems, setPageOfItems] = useState([])
  const [modal, setModal] = useState(configModal)
  
  const { 
    response: timeRuleResponse,
    load: timeRuleLoad,
    refresh: timeRulerefresh
  } = useFetch(`${API_URL}/time-rule`)

  const items = timeRuleResponse?.data || []

  // console.log('TimeRuleList', 'Rendered')

  const setPageEdit = (item) => {
    setModal({
      active: true,
      page: <TimeRuleForm itemSelected={item} closeModal={closeModal} refreshList={timeRulerefresh} />,
      report: false
    })
  }

  const closeModal = () => setModal(configModal)

  const onChangePage = (ppageOfItems) => setPageOfItems(ppageOfItems)

  const tableHeader = () => (
    <Table.Row>
      <Table.Heading>Dia da semana</Table.Heading>
      <Table.Heading textAlign='centered'>Início</Table.Heading>
      <Table.Heading textAlign='centered'>Fim</Table.Heading>
      <Table.Heading textAlign='centered'>Ações</Table.Heading>
    </Table.Row>
  )

  const tableBody = () => {
    return (pageOfItems.length === 0) ? (
      <Table.Row>
        <Table.Cell colSpan="9">
          { (timeRuleLoad) ? <Progress size='small' color='custom-purple' /> : 'Nenhuma horário de estacionamento foi encontrado.' }
        </Table.Cell>
      </Table.Row>
    ) : (
      pageOfItems.map((item) => (
        <Table.Row key={item.id}>
          <Table.Cell>{item.weekday}</Table.Cell>
          <Table.Cell textAlign='centered'>{moment(item.minHour).format('HH:mm')}</Table.Cell>
          <Table.Cell textAlign='centered'>{moment(item.maxHour).format('HH:mm')}</Table.Cell>
          <Table.Cell textAlign='centered'>
            <Icon size={'medium'} className='is-clickable ml-2' title='Editar' onClick={() => setPageEdit(item)}>
              <FontAwesomeIcon icon={faEdit} />
            </Icon>
          </Table.Cell>
        </Table.Row>
      ))
    )
  }

  return (
    <>
      <Box>
        <div className="table-container">
          <Table hoverable fullwidth>
            <Table.Head>
              { tableHeader() }
            </Table.Head>
            <Table.Body>
              { tableBody() }
            </Table.Body>
          </Table>
        </div>
        <PaginationCustom items={items} onChangePage={onChangePage} />
      </Box>
      <Modal closeOnBlur={true} closeOnEsc={true} active={modal.active} onClose={closeModal}>
        <Modal.Background onClick={closeModal} />
        <Modal.Content>
          { modal.page }
        </Modal.Content>
      </Modal>
    </>
  )
}
