import { Card } from 'rbx';
import { useState } from 'react'
import { toast } from 'react-toastify';
import UserService from '../../../services/user'
import { numberMask } from '../../../utils/masks';
import { CardHeader } from '../../shared/CardHeader';
import Controls from '../../shared/Controls/Controls';

export const ActiveAccount = ({ userData, goToMenu }) => {
  const [code, setCode] = useState('');
  const [stateButton, setStateButton] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStateButton("loading");
    try {
      const body = {
        cpfCnpj: userData.cpf_cnpj,
        activationCode: Number(code)
      }
      const response = await UserService.activeAccount(body);
      await UserService.reload();
      toast.success(response.data.message)
      goToMenu();
    } catch(e) {
      if (e.response.data)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setStateButton('');
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader handleClose={goToMenu}>Ativar conta</CardHeader>
        <Card.Content>
          <Controls.TextField 
            label="Código de ativação"
            name="code"
            type="text"
            placeholder='000000'
            value={code}
            onChange={(e) => setCode(numberMask(e.target.value))}
            autoFocus
            maxLength="6"
            required
          />
          <Controls.ActionButtons
            linkName="Fechar"
            handleClickBack={goToMenu} 
            buttonState={stateButton} 
            buttonName='Ativar'
          />
        </Card.Content>
      </Card>
    </form>
  )
}
