import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Container, Group } from './styles';
import { Vehicles } from '../Vehicles';
import { VehiclesParked } from '../VehiclesParked';
import WhatsappButton from '../../shared/WhatsappButton'
import { FooterMenu } from '../FooterMenu';
import { useMedia } from '../../../hooks/useMedia';

export const DashboardAppPdv = () => {
  const mobile = useMedia('(max-width: 768px)');
  const profile = sessionStorage.getItem('profile');

  return (
    <>
      <ToastContainer />

      <Container>
        {
          !mobile && 
            <Group className="animeLeft">
              <FooterMenu />
            </Group>
        }

        <Group className="animeLeft">
          { ["user","frotista","driver"].includes(profile) && <Vehicles /> }

          { profile === 'pdv' && <VehiclesParked /> }
        </Group>
      </Container>

      <WhatsappButton />

      { mobile &&  <FooterMenu />}
    </>
  )
}
