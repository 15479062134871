import { Constant } from '../../commons/constants'
import { RedirectTo } from '../../utils/redirectTo'

export const MenuItems = {
  adm: [
    {
      title: 'geral',
      items: [
        { label: 'Home', to: RedirectTo.dashboard },
        { label: 'Extração de Dados', to: '/dataExtraction' },
      ]
    },
    {
      title: 'administrativo',
      items: [
        { label: 'Estacionamentos', to: '/parking' },
        { label: 'Veículo Histórico', to: '/vehicleHistoric' },
        { label: 'Veículos Irregulares', to: '/irregularVehicles' },
      ]
    },
    {
      title: 'monitor',
      items: [
        { label: 'Localização Atual', to: '/monitorsNow' },
        { label: 'Histórico Localização', to: '/monitors' },
        { label: 'Financeiro', to: '/financialMonitor' },
      ]
    },
    {
      title: 'financeiro',
      items: [
        { label: 'APP e PDV', to: '/financialAppPdv' },
        { label: 'Prestação de Conta', to: '/accountability' },
      ]
    },
  ],

  pdv: [],

  user: [],

  monitor: [],

  agent: [
    {
      title: 'geral',
      items: [
        { label: 'Home', to: RedirectTo.dashboard },
      ]
    },
    {
      title: 'irregularidades',
      items: [
        { label: 'Avisos', to: '/agent/irregularVehicles/a' },
        { label: 'Autuadas', to: '/agent/finedIrregularity' },
      ]
    }
  ],

  support: [
    {
      title: 'geral',
      items: [
        { label: 'Home', to: RedirectTo.dashboard },
        { label: 'Usuário', to: '/user/search' },
        { label: 'Fale Conosco', to: '/contactUs' },
        { label: 'Veículo Histórico', to: '/vehicleHistoric' },
        { label: 'Veículos Irregulares', to: '/irregularVehicles' },
      ]
    },
    {
      title: 'administrativo',
      items: [
        { label: 'Áreas', to: '/areas' },
        { label: 'Locais de estacionamento', to: '/vacancy' },
        { label: 'Feriados', to: '/holidays' },
        { label: 'Regras de estacionamento', to: '/regionRules' },
        { label: 'Horários de estacionamento', to: '/timeRules' },
        { label: 'Domínios bloqueados', to: '/domainBlacklist' },
        { label: 'Publicidade', to: '/advertising' },
      ]
    }
  ],

  prefecture: [
    {
      title: 'geral',
      items: [
        { label: 'Home', to: RedirectTo.dashboard },
      ]
    },
    {
      title: 'irregularidades',
      items: [
        { label: 'Avisos', to: '/agent/irregularVehicles/a' },
        { label: 'Confirmadas', to: '/agent/confirmedIrregularity' },
        { label: 'Autuadas', to: '/agent/finedIrregularity' },
      ]
    }
  ],

  diretoria: [
    {
      title: 'geral',
      items: [
        { label: 'Home', to: RedirectTo.dashboard },
        { label: 'Novo Usuário', to: '/user/new' },
        { label: 'Extração de Dados', to: '/dataExtraction' },
      ]
    },{
      title: 'relatórios',
      items: [
        { label: 'Estacionamentos', to: '/parking' },
        { label: 'Veículos Irregulares', to: '/irregularVehicles' },
      ]
    },
    {
      title: 'monitor',
      items: [
        { label: 'Localização Atual', to: '/monitorsNow' },
        { label: 'Histórico Localização', to: '/monitors' },
        { label: 'Financeiro', to: '/financialMonitor' },
      ]
    },
    {
      title: 'financeiro',
      items: [
        { label: 'Prestação de Conta', to: '/accountability' },
      ]
    },
    {
      title: 'gerencial',
      items: [
        { label: 'Business Intelligence', to: Constant.DiretoriaBI, external: true },
      ]
    },
  ],
}
