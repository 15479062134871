import { Column } from 'rbx'
import { Summary } from '../../Adm/Summary'
import { OccupationByArea } from '../../Adm/OccupationByArea'

export function DashboardDiretoria() {
  return (
    <Column.Group>
      <Column>
        <OccupationByArea />
      </Column>
      <Column>
        <Summary />
      </Column>
    </Column.Group>
  )
}
