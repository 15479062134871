import { useState } from 'react'
import { Box } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { DriverService } from '../../../../../services/api-zad'
import { toast } from 'react-toastify'

export const Driver = ({ driver, driversRefresh, destroy = false }) => {
  const [destroyLoading, setDestroyLoading] = useState(null)

  const handleDestroy = async (item) => {
    try {
      if (window.confirm('Confirma a exclusão do Motorista?')) {
        setDestroyLoading(item)
        const response = await DriverService.destroy(item.id)
        toast.success(response.data.message)
        driversRefresh()
      }
    } catch (e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setDestroyLoading(null)
  }

  return (
    <Box className='p-2'>
      <div className='is-flex'>
        <div className='is-flex is-justify-content-right p-2 is-size-5'>
          <FontAwesomeIcon icon={faUser} />
        </div>

        <div className='px-3' style={{ flex:'1 0' }}>
          <strong style={{ color:'var(--blue)', fontSize:'1.2rem' }}>{driver.name}</strong>
          <span className='is-block' style={{ color: 'var(--text-body)' }}>
            Matrícula: {driver.matricula}
          </span>
        </div>

        {
          destroy &&
            <div
              className='is-flex is-justify-content-right p-2 is-clickable is-size-5'
              onClick={() => handleDestroy(driver)}
            >
              {
                destroyLoading?.id === driver.id
                  ? <FontAwesomeIcon icon={faSpinner} pulse />
                  : <FontAwesomeIcon icon={faTrashAlt} style={{ color:'var(--red)' }} />
              }
            </div>
        }
      </div>
    </Box>
  )
}
