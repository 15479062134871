import { Box, Column, Content } from 'rbx'
import { BoxHeader } from '../../shared/BoxHeader'

export const VacancyDetail = ({ itemSelected = {}, closeModal }) => {
  return (
    <Box>
      <BoxHeader title='Detalhe do Estacionamento' handleClose={closeModal} />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0">
          <small><strong>Área: </strong></small>
          {itemSelected.area}
        </Column>
        <Column className="pb-0">
          <small><strong>Vagas: </strong></small>
          {itemSelected.quantity}
        </Column>
        <Column className="pb-0">
          <small><strong>Desabilitado: </strong></small>
          {itemSelected.disabled ? 'Sim' : 'Não'}
        </Column>
      </Column.Group>
      <hr className="hr" />

      <small><strong>Local</strong></small><br />
      - {itemSelected.street}
      <hr className="hr" />

      <small><strong>Referências</strong></small><br />
      <Content>
        <ul>
          {
            itemSelected.references.map((reference) => (
              <li key={reference.id}>{reference.name}</li>
            ))
          }
        </ul>
      </Content>
    </Box>
  )
}
