import { ToastContainer } from "react-toastify";
import { FindMonitors } from "../../components/Adm/FindMonitors";

export default function MonitorPage() {
  return (
    <>
      <ToastContainer />
      <FindMonitors />
    </>
  );
}
