import { Column } from "rbx"
import { useState } from "react"
import { HolidayForm } from "../../components/Holiday/form"
import { HolidayList } from "../../components/Holiday/list"
import { Layout } from "../../components/Layout"

export const HolidayPage = () => {
  const [reloadList, setReloadList] = useState('')
  const [itemSelected, setItemSelected] = useState({})
  
  return (
    <Layout controllerName='Feriados' breadcrumb='Dashboard / Feriados'>
      <Column.Group className="animeLeft" centered>
        <Column size={4}>
          <HolidayForm 
            setReloadList={setReloadList} 
            itemSelected={itemSelected} 
            newItem={ !Object.keys(itemSelected).length } 
          />
        </Column>
        <Column>
          <HolidayList 
            reload={reloadList} 
            setItemSelected={setItemSelected}
          />
        </Column>
      </Column.Group>
    </Layout>
  )
}