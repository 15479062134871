import AuthContext from '../../../contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { maskCpfCnpj, unMask } from '../../../utils/masks';
import { Container } from '../styles';
import { RedirectTo } from '../../../utils/redirectTo';
import { PasswordInput } from '../../shared/PasswordInput';

export function LoginForm() {
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [password, setPassword] = useState('');

  const { login, error, loading } = useContext(AuthContext);

  useEffect(() => error && toast.error(error));

  const handleSubmit = async (e) => {
    e.preventDefault();

    login({
      cpfCnpj: unMask(cpfCnpj),
      password,
    });
  };

  return (
    <Container>
      <h2>Acesse sua conta</h2>

      <form onSubmit={handleSubmit}>
        <MaskedInput
          mask={maskCpfCnpj}
          placeholder="Cpf ou Cnpj"
          guide={false}
          type="text"
          value={cpfCnpj}
          autoFocus
          onChange={(event) => setCpfCnpj(event.target.value)}
          required
        />

        <PasswordInput
          placeholder="Senha"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          required
        />

        <div className='is-flex is-justify-content-space-between is-align-items-center'>
          <Link to={RedirectTo.activate}>
            <span>Ativar Conta</span>
          </Link>
          <Link to={RedirectTo.forgotPassword}>
            <span>Esqueci a Senha?</span>
          </Link>
        </div>

        {loading ? (
          <button disabled type="submit">
            Entrando...
          </button>
        ) : (
          <button type="submit">Entrar</button>
        )}

        <Link to={RedirectTo.register}>
          <button className="secondButton">Criar nova conta</button>
        </Link>
      </form>
    </Container>
  );
}
