import { faChevronLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from 'rbx';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { RedirectTo } from '../../utils/redirectTo';
import { Container, IconButton } from "./styles"

export const TitleButtons = ({ children, handleBtnBackClick }) => {
  const [redirectToDashboard, setRedirectToDashboard] = useState(false)

  if (redirectToDashboard)
    return <Redirect to={{ pathname: RedirectTo.dashboard }} />

  return (
    <Container>
      <header>
        <IconButton type="button" onClick={ handleBtnBackClick }>
          <Icon style={{fontSize:'1.3rem'}}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </Icon>
        </IconButton>

        { children }

        <IconButton type="button" onClick={() => setRedirectToDashboard(true)}>
          <Icon style={{fontSize:'1.3rem'}}>
            <FontAwesomeIcon icon={faHome} />
          </Icon>
        </IconButton>
      </header>
    </Container>
  )
}