import { RegisterForm } from "../../components/Forms/Register";
import LayoutHomePage from "../LayoutHomePage";

export default function RegisterPage() {
  return (
    <LayoutHomePage>
      <RegisterForm />
    </LayoutHomePage>
  );
}
