import styled from 'styled-components'

export const Container = styled.div`
  input#search {
    background: white;
    height: 2.3rem;
    font-size: 13px;
    margin-bottom: 0.4rem;

    & + div {
      margin-top: 0rem;
    }
  }
`

export const AddressList = styled.div`
  display: ${(props) => props.addresses?.length >= 3 ? 'block' : 'none'};
  background-color: var(--background);
  position: absolute !important;
  z-index: 2;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial,sans-serif;
  box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  top: 38px;
`

export const AddressItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 35px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 12px;
  color: #515151;

  &:hover {
    background-color: var(--input-background);
    cursor: pointer;
  }
`

export const ItemIcon = styled.span`
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
  background-size: 34px;
  background-position: -1px -161px;
`
