import { Link, useLocation } from "react-router-dom";
import { Images } from '../../../utils/images';
import { Container, Content } from "./styles";
import { RedirectTo } from "../../../utils/redirectTo";
import { DevelopmentNotification } from "../../shared/DevelopmentNotification";

export function HeaderHome({ redirectTo }) {
  let location = useLocation();

  const normas = (location.pathname === RedirectTo.serviceTerms || location.pathname === RedirectTo.privacyPolicy)

  return (
    <Container id="topo">
      <Content>
        <Link to={redirectTo ? redirectTo : RedirectTo.login}>
          <img
            src={Images.logoZad}
            alt="Transitar Consultoria"
            className="logo"
          />
        </Link>

        <div>
          <Link to={RedirectTo.serviceTerms}>
            <button className={ normas ? "" : "white" }>Termos de Serviço</button>
          </Link>
          <Link to={RedirectTo.privacyPolicy}>
            <button className={ normas ? "" : "white" }>Política de Privacidade</button>
          </Link>
        </div>
      </Content>
      <div style={{ maxWidth:'1120px', width:'100%', margin:'auto', padding:'0 2rem' }}>
        <DevelopmentNotification />
      </div>
    </Container>
  );
}
