import { useEffect, useState } from 'react';
import { Redirect } from "react-router-dom";
import UserService from '../../services/user';
import { Card, Column } from "rbx";
import { unMask } from '../../commons/masks';
import Controls from '../shared/Controls/Controls';
import { toast } from 'react-toastify';
import { CardHeader } from './CardHeader';
import cep from 'cep-promise';
import { useFetch } from '../../hooks/useFetch';
import { API_URL } from '../../services/api';


export const UserForm = ({ newUser=false, editUser=false, editUserLogged=false, cpf, userData, goToMenu }) => {
  const [redirectTo, setRedirectTo] = useState('');
  const [profile, setProfile] = useState('');
  const [profiles, setProfiles] = useState([]);
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [birthday, setBirthday] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [admPassword, setAdmPassword] = useState('');
  const [pdvCode, setPdvCode] = useState('');

  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');

  const [stateButton, setStateButton] = useState('');

  const { response: profilesResponse } = useFetch(`${API_URL}/adm/profiles`)

  useEffect(()=>{
    setCpfCnpj(userData?.cpf_cnpj||cpf)
    setName(userData?.name)
    setEmail(userData?.email)
    setBirthday(userData?.birthDay)
    setPhone(userData?.phone?.replace('+55', ''))
    setProfiles(profilesResponse?.data||[])
    setProfile(userData?.profile2)
    setPdvCode(userData?.pdvCode)
    setZipCode(userData?.address?.zipCode)
    setStreet(userData?.address?.street);
    setNeighborhood(userData?.address?.neighborhood);
    setNumber(userData?.address?.number);
    setCity(userData?.address?.city);
    setState(userData?.address?.state);
  }, [userData, profilesResponse, cpf])

  const findAddressByCep = async () => {
    try {
      if (zipCode && zipCode.length===9) {
        const address = await cep(zipCode);
        setStreet(address.street.toUpperCase());
        setNeighborhood(address.neighborhood.toUpperCase());
        setCity(address.city.toUpperCase());
        setState(address.state.toUpperCase());
      } else {
        setStreet('');
        setNeighborhood('');
        setNumber('');
        setCity('');
        setState('');
        toast.error('Digite um CEP completo')
      }
    } catch(e) {
      toast.error(e.message)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStateButton("loading");
    try {

      if (newUser) { // New User

        const data = {
          name,
          cpfCnpj: unMask(cpfCnpj),
          birthday,
          phone: `+55${unMask(phone)}`,
          email,
          password,
          profile
        }
        if (["pdv","frotista"].includes(profile)) {
          data.pdvCode = pdvCode
          data.address = {
            street,
            number,
            neighborhood,
            state,
            city,
            zipCode
          }
        }
        const response = await UserService.register(data);
        toast.success(`${response.status}: ${response.data.message}`)
        cleanForm()

      } else if (editUserLogged) { // Edit User Logged

        if (newPassword && newPassword !== passwordConfirmation) {
          toast.error('As senhas não coincidem')
        } else if (newPassword && !password) {
          toast.error('Senha atual obrigatória para troca de senha')
        } else {
           const data = {
            name,
            birthday,
            phone: `+55${unMask(phone)}`,
            email,
            password,
            newPassword
          }
          const response = await UserService.updateMyAccount(data);
          toast.success(`${response.status}: ${response.data.message}`)
        }

      } else { // Edit User

        if (newPassword && newPassword !== passwordConfirmation) {
          toast.error('As senhas não coincidem')
        } else {
           const data = {
            userId: userData.id,
            name,
            birthday,
            phone: `+55${unMask(phone)}`,
            email,
            profile,
            password,
            newPassword,
            admPassword
          }
          if (["pdv","frotista"].includes(profile)) {
            data.pdvCode = pdvCode
            data.address = {
              street,
              number,
              neighborhood,
              state,
              city,
              zipCode
            }
          }
          const response = await UserService.updateOtherAccount(data);
          await UserService.reload();
          toast.success(response.data.message)
          goToMenu()
        }

      }
    } catch (e) {
      if (e.response)
        toast.error(`${e.response.status}: ${e.response.data.message}`)
      else
        toast.error(e)
    }
    setStateButton('');
  }

  if (redirectTo)
    return <Redirect to={{pathname: redirectTo}} />

  const goToFind = () => setRedirectTo('/dashboard');

  const cleanForm = () => {
    setCpfCnpj('');
    setName('');
    setEmail('');
    setBirthday('');
    setPhone('');
    setPassword('');
    setNewPassword('');
    setPasswordConfirmation('');
    setAdmPassword('');
    setStreet('');
    setNumber('');
    setNeighborhood('');
    setState('');
    setCity('');
    setZipCode('')
    setPdvCode('')
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader handleClose={goToMenu||goToFind}>
          { newUser ? 'Registrar Usuário' : 'Editar Dados' }
        </CardHeader>
        <Card.Content>
          <Controls.TextField 
            label="Nome"
            name="name"
            placeholder='Nome completo'
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoFocus
            required
          />
          <Column.Group className="mb-0">
            <Column className="pb-0">
              <Controls.CpfCnpjField 
                label="CPF ou CNPJ"
                name="cpfCnpj"
                placeholder='123.456.789-01'
                value={cpfCnpj}
                onChange={(e) => setCpfCnpj(e.target.value)}
                required
              />
            </Column>
            <Column>
              <Controls.TextField 
                label="Data de nascimento"
                name="birthday"
                type="date"
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
              />
            </Column>
          </Column.Group>
          <Controls.TextField 
            label="Email"
            name="email"
            placeholder='nome@email.com'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Column.Group className="mb-0">
            <Column className="pb-0">
              <Controls.PhoneField 
                label="Telefone"
                name="phone"
                placeholder='(__) ____-____'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </Column>
            <Column>
              <Controls.SelectField
                label="Perfil"
                name="profile"
                value={profile}
                onChange={(e) => {
                  setProfile(e.target.value)
                  setEmail((e.target.value === 'monitor' && !email) ? 'suporte@zonaazul.org' : email)
                }} 
                options={profiles?.map(profile => ({ value: profile.name, description: profile.description }))}
                required
              />
            </Column>
          </Column.Group>
          {
            newUser && (
              <>
                { 
                  ["pdv","frotista"].includes(profile) && (
                    <div className="animeLeft">
                      <Controls.TextField 
                        label="Código PDV / Frotista"
                        name="pdvCode"
                        placeholder='Código PDV / Frotista'
                        value={pdvCode}
                        onChange={(e) => setPdvCode(e.target.value)}
                        required
                      />
                      <Controls.ZipCodeField 
                        label="CEP"
                        name="zipCode"
                        placeholder='_____-___'
                        value={zipCode}
                        onChange={e => setZipCode(e.target.value)}
                        onClick={findAddressByCep}
                        required
                      />
                      <Controls.TextField 
                        label="Logradouro"
                        name="street"
                        placeholder='Logradouro'
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                      />
                      <Column.Group className="mb-0">
                        <Column className="pb-0">
                          <Controls.TextField 
                            label="Número"
                            name="number"
                            type="text"
                            placeholder='Número'
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                          />
                        </Column>
                        <Column>
                          <Controls.TextField 
                            label="Bairro"
                            name="neighborhood"
                            placeholder='Bairro'
                            value={neighborhood}
                            onChange={(e) => setNeighborhood(e.target.value)}
                          />
                        </Column>
                      </Column.Group>
                      <Column.Group className="mb-0">
                        <Column className="pb-0">
                          <Controls.TextField 
                            label="Cidade"
                            name="city"
                            placeholder='Cidade'
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </Column>
                        <Column>
                          <Controls.TextField 
                            label="Estado"
                            name="state"
                            placeholder='Estado'
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                          />
                        </Column>
                      </Column.Group>
                    </div>
                  )
                }
                <Controls.PasswordField 
                  label="Senha"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <Controls.ActionButtons
                  linkName="Fechar" 
                  handleClickBack={goToFind} 
                  buttonState={stateButton} 
                  buttonName="Registrar"
                />
              </>
            )
          }
          {
            editUser &&
            <>
              { 
                ["pdv","frotista"].includes(profile) && (
                  <div className="animeLeft">
                    <hr className='hr' />
                    <Controls.TextField 
                      label="Código PDV / Frotista"
                      name="pdvCode"
                      placeholder='Código PDV / Frotista'
                      value={pdvCode}
                      onChange={(e) => setPdvCode(e.target.value)}
                      required
                    />
                    <Controls.ZipCodeField 
                      label="CEP"
                      name="zipCode"
                      placeholder='_____-___'
                      value={zipCode}
                      onChange={e => setZipCode(e.target.value)}
                      onClick={findAddressByCep}
                      required
                    />
                    <Controls.TextField 
                      label="Logradouro"
                      name="street"
                      placeholder='Logradouro'
                      value={street}
                      onChange={(e) => setStreet(e.target.value)}
                    />
                    <Column.Group className="mb-0">
                      <Column className="pb-0">
                        <Controls.TextField 
                          label="Número"
                          name="number"
                          type="text"
                          placeholder='Número'
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                        />
                      </Column>
                      <Column>
                        <Controls.TextField 
                          label="Bairro"
                          name="neighborhood"
                          placeholder='Bairro'
                          value={neighborhood}
                          onChange={(e) => setNeighborhood(e.target.value)}
                        />
                      </Column>
                    </Column.Group>
                    <Column.Group className="mb-0">
                      <Column className="pb-0">
                        <Controls.TextField 
                          label="Cidade"
                          name="city"
                          placeholder='Cidade'
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </Column>
                      <Column>
                        <Controls.TextField 
                          label="Estado"
                          name="state"
                          placeholder='Estado'
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        />
                      </Column>
                    </Column.Group>
                  </div>
                )
              }
              <hr className='hr' />
              <Column.Group className="mb-0">
                <Column className="pb-0">
                  <Controls.PasswordField 
                    label="Nova senha"
                    name="newPassword"
                    placeholder="Obrigatório para troca de senha."
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Column>
                <Column>
                  <Controls.PasswordField 
                    label="Confirme a nova senha"
                    name="passwordConfirmation"
                    placeholder="Obrigatório para troca de senha."
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                  />
                </Column>
              </Column.Group>
              {
                editUserLogged ? (
                  <Controls.PasswordField 
                    label="Senha atual"
                    name="password"
                    placeholder="Obrigatório para troca de senha."
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                ) : (
                  <Controls.PasswordField 
                    label="Senha do Administrador"
                    name="admPassword"
                    placeholder="Senha do Administrador."
                    value={admPassword}
                    onChange={(e) => setAdmPassword(e.target.value)}
                    required
                  />
                )
              }
              <Controls.ActionButtons
                linkName="Fechar" 
                handleClickBack={goToMenu||goToFind}
                buttonState={stateButton} 
                buttonName='Atualizar'
              />
            </>
          }
        </Card.Content>
      </Card>
    </form>
  )
}
