import { useState, useEffect } from 'react'
import moment from 'moment'

export const Timer = ({ startDate, paidMinutes, reverse=false, format='HH[h] mm[m] ss[s]' }) => { 
  const [seconds, setSeconds] = useState(0)
  
  useEffect(() => {
    const beginTime = moment().diff(moment(startDate), 'seconds') // segundos decorridos até agora
    const endTime = paidMinutes*60 // total de segundos a decorrer

    setSeconds(reverse ? endTime-beginTime : beginTime)

    const tick = () => {
      if (seconds > 0 && seconds <= endTime) setSeconds( reverse ? seconds-1 : seconds+1 )
    }

    const interval = setInterval(() => tick(), 1000);
    return () => clearInterval(interval);
  }, [startDate, paidMinutes, reverse, seconds]);

  return (
    <>{ moment.utc(seconds*1000).format(format) }</>
  )
}
