import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { maskPlate } from '../../../../utils/masks';
import MaskedInput from 'react-text-mask';
import { API_URL } from '../../../../services/api';
import { Container, InputGrid } from './styles';
import { DeleteVehicleModal } from '../VehicleModal/delete';
import { ModalHeader } from '../../../shared/ModalHeader'

export function VehicleModal({ isOpen, onRequestClose, vehicle }) {
  const [modal, setModal] = useState({})
  const [licensePlate, setLicensePlate] = useState('');
  const [alias, setAlias] = useState('');
  const [type, setType] = useState('');
  const [loading, setLoading] = useState('');

  useEffect(() => {
    if (vehicle?.licensePlate) {
      setLicensePlate(vehicle?.licensePlate||'')
      setAlias(vehicle?.alias||'')
      setType(vehicle?.type||'')
    } else {
      setLicensePlate('');
      setAlias('');
      setType('');
    }
  },[vehicle])

  const handleNewVehicle = async (e) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      licensePlate: licensePlate.replace('-', ''),
      alias,
      type: type.toLowerCase(),
    };

    const res = await fetch(`${API_URL}/vehicles`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setLicensePlate('');
      setAlias('');
      setType('');
    }

    setLoading(false);
  }

  const closeModal = () => setModal({})

  const openDeleteVehicleModal = () => {
    setModal({
      show: <DeleteVehicleModal
        isOpen={true}
        onRequestClose={closeModal}
        vehicleId={vehicle?.id}
        vehicleLicensePlate={setLicensePlate}
      />
    })
  }

  return (
    <>
      { modal.show }

      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <Container onSubmit={handleNewVehicle}>
          <ModalHeader
            title={ vehicle?.id ? 'Editar Veículo' : 'Novo Veículo' }
            handleClose={onRequestClose}
          />

          <InputGrid>
            <MaskedInput
              mask={maskPlate}
              placeholder="Placa"
              guide={false}
              type="text"
              autoFocus
              value={licensePlate.toUpperCase()}
              onChange={(event) => setLicensePlate(event.target.value)}
              required
            />

            <input
              placeholder="Apelido deste veículo"
              type="text"
              maxLength={15}
              value={alias}
              onChange={(event) => setAlias(event.target.value.toUpperCase())}
            />
          </InputGrid>

          <select
            type="text"
            value={type}
            onChange={(event) => setType(event.target.value)}
            required
          >
            <option value="" disabled defaultValue hidden>
              Tipo de veículo
            </option>
            <option value="Carro">Carro</option>
            <option value="Caminhão">Caminhão</option>
            {/* <option value="Motocicleta">Motocicleta</option> */}
            <option value="Ônibus">Ônibus</option>
          </select>

          <hr className='hr'></hr>

          {loading ? (
            <button type="submit" className='btn' disabled>
              Salvando
            </button>
          ) : (
            <button type="submit" className='btn'>Salvar</button>
          )}

          {
            vehicle?.id && 
              <button type="button" className='btn danger' onClick={openDeleteVehicleModal}>
                Excluir Veículo
              </button>
          }
        </Container>
      </Modal>
    </>
  );
}
