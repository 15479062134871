export const env = {
  isProduction: Number(process.env.REACT_APP_ENV) === 1,
  isDevelopment: Number(process.env.REACT_APP_ENV) === 2,

  API_BACKEND: process.env.REACT_APP_API_BACKEND,
  GEOCODE_KEY: process.env.REACT_APP_GEOCODE_KEY,
  IMEI_DEFAULT: process.env.REACT_APP_IMEI_DEFAULT,
  PAGE_SIZE: process.env.REACT_APP_PAGE_SIZE,
  CAD_DESC: process.env.REACT_APP_CAD_DESC,
  CAD_VALUE: Number(process.env.REACT_APP_CAD_VALUE),
  CITY: process.env.REACT_APP_CITY,
  STATE: process.env.REACT_APP_STATE,
  COMPANY: process.env.REACT_APP_COMPANY,
  COMPANY_ADDRESS: process.env.REACT_APP_COMPANY_ADDRESS,
  COMPANY_ZIPCOD: process.env.REACT_APP_COMPANY_ZIPCOD,
  COMPANY_CONTACT_1: process.env.REACT_APP_COMPANY_CONTACT_1,
  COMPANY_CONTACT_2: process.env.REACT_APP_COMPANY_CONTACT_2,
  ZAD_GOOGLE_PLAY: process.env.REACT_APP_ZAD_GOOGLE_PLAY,
  ZAD_APPLE_STORE: process.env.REACT_APP_ZAD_APPLE_STORE,
  ZAD_MAP_LAT: Number(process.env.REACT_APP_ZAD_MAP_LAT),
  ZAD_MAP_LNG: Number(process.env.REACT_APP_ZAD_MAP_LNG),
  ZAD_CITY: process.env.REACT_APP_ZAD_CITY.toUpperCase(),
  ZAD_STATE: process.env.REACT_APP_ZAD_STATE,
  WHATSAPP: process.env.REACT_APP_WHATSAPP,
  SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL,
  SERVICE_TERMS_COMPANY: process.env.REACT_APP_SERVICE_TERMS_COMPANY,
  SERVICE_TERMS_CNPJ: process.env.REACT_APP_SERVICE_TERMS_CNPJ,
  SERVICE_TERMS_ADDRESS: process.env.REACT_APP_SERVICE_TERMS_ADDRESS,
}
