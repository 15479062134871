import moment from 'moment'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

export const useFetch = (url, showToast=false) => {
  const [response, setResponse] = useState('')
  const [error, setError] = useState('')
  const [load, setLoad] = useState('')
  const [time, setTime] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      setLoad('loading')
      try {
        const res = await fetch(url, {
          method: 'GET',
          cache: 'no-store',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
          },
        })
        const data = await res.json()
        if (data.statusCode === 200) {
          setResponse(data)
          setError('')
        } else {
          setResponse([])
          setError(data)
          if (showToast) toast.error(data.message)
        }
      } catch (err) {
        setError(err)
      }
      setLoad('')
    }
    fetchData()
  }, [url, time, showToast])

  const refresh = () => setTime(moment().format())

  return { response, error, load, refresh }
}