import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Control, Field, Input } from "rbx"
import { useState } from "react"

export const HolidaySearch = ({ setFilter, filterLoading, setFilterLoading }) => {
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    setFilterLoading('loading')
    setFilter({
      startDate, endDate
    })
  }

  const resetFilter = () => {
    setStartDate('')
    setEndDate('')
    setFilter({})
  }

  return (
    <form onSubmit={handleSubmit} className='is-flex is-align-items-center is-justify-content-space-between'>
      <Field kind="group" multiline className='is-align-items-center mb-0'>
        <Control>
          <Input 
            type='date' 
            size={'small'} 
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required 
          />
        </Control>
        <Control>
        <Input 
          type='date' 
          size={'small'} 
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          required 
        />
        </Control>
        <Control>
          <Button size={'small'} state={filterLoading} title='Pesquisar'>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </Control>
        <Control>
          <Button type='button' size={'small'} onClick={resetFilter} title='Limpar pesquisar'>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Control>
      </Field>
    </form>
  )
}
