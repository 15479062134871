// import { Column } from 'rbx'
import { Layout } from '../../components/Layout'
import { AreaList } from '../../components/Area/list'

export const AreaPage = () => {
  return (
    <Layout controllerName='Áreas de estacionamento'>
      <AreaList />
    </Layout>
  )
}
