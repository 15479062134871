import { Box, Dropdown } from 'rbx'
import { faChevronDown, faCreditCard, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card } from '../../AppPdv/Modals/CardModal/card'
import './styles.css'

export const DropdownCreditCard = ({ cardsData, openCardForm, setCardSelected }) => {
  return (
    <Dropdown className='dropdown-credit-card'>
      <Dropdown.Trigger>
        <h3 className='m-0 is-clickable'>
          <FontAwesomeIcon icon={faCreditCard} className='mr-2' />
          Selecione o Cartão de Crédito
          <FontAwesomeIcon icon={faChevronDown} className='ml-2' />
        </h3>
      </Dropdown.Trigger>
      <Dropdown.Menu>
        <Dropdown.Content>
          <Dropdown.Item
            onClick={openCardForm}
          >
            <Box className='is-flex is-align-items-center'>
              <div
                className='is-flex p-2 is-align-items-center ml-3 is-clickable'
                style={{ borderRadius:'100%', backgroundColor:'var(--blue)' }}
              >
                <FontAwesomeIcon icon={faPlus} style={{ color:'var(--shape)', fontSize:'0.7rem' }} />
              </div>
              <span className='ml-2 is-size-6' style={{ color:'var(--text-title)' }}>Novo Cartão de Crédito</span>
            </Box>
          </Dropdown.Item>
          <Dropdown.Divider />
          {cardsData.reverse().map((card, index) => (
            <Dropdown.Item
              key={'card_' + index}
              onClick={() => setCardSelected(card) }>
              <Card card={card} />
            </Dropdown.Item>
          ))}
        </Dropdown.Content>
      </Dropdown.Menu>
    </Dropdown>
  )
}
