import { Card, Icon } from 'rbx'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const CardHeader = ({ children, handleClose }) => {
  return (
    <Card.Header>
      <Card.Header.Title className='is-justify-content-space-between'>
        { children }
        {
          handleClose &&
          <Icon className='is-clickable' onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </Icon>
        }
      </Card.Header.Title>
    </Card.Header>
  )
}
