import styled from 'styled-components';

export const Container = styled.section`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0.25rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 2rem;

  h2 {
    color: var(--shape);
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem !important;
    margin-bottom: 1rem;
    color: var(--shape);
  }

  input {
    width: 100%;
    padding: 0 1.5rem;
    height: 3.5rem;
    border-radius: 0.25rem;

    border: 1px solid #d7d7d7;
    background: var(--input-background);

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }

    & + input {
      margin-top: .5rem;
    }

    & + div {
      margin-top: .5rem;
    }
  }

  button[type='submit'] {
    width: 100%;
    padding: 0 1.5rem;
    height: 3rem;
    background: var(--green);
    color: #fff;
    border-radius: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.6);
    font-size: 1rem;
    margin-top: 1.2rem;
    font-weight: 500;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  .secondButton {
    width: 100%;
    padding: 0 1.5rem;
    height: 3rem;
    background: var(--blue);
    color: #fff;
    border-radius: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.6);
    font-size: 1rem;
    margin-top: 1rem;
    font-weight: 500;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  span {
    display: inline-block;
    font-size: 1rem;
    margin-top: 1.2rem;
    color: var(--shape);
  }
`;
