import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { Button, Control, Field, Input } from "rbx"
import { useState } from "react"

export const VehicleHistoricSearch = ({ setFilter, filterLoading, setFilterLoading }) => {
  const [licensePlate, setLicensePlate] = useState('')
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))

  const handleSubmit = (e) => {
    e.preventDefault()
    setFilterLoading('loading')
    setFilter({
      licensePlate, startDate, endDate
    })
  }

  const resetFilter = () => {
    setLicensePlate('')
    setStartDate(moment().format('YYYY-MM-DD'))
    setEndDate(moment().format('YYYY-MM-DD'))
    setFilter({})
  }

  return (
    <form onSubmit={handleSubmit} className='is-flex is-align-items-center is-justify-content-space-between'>
      <Field kind="group" className='is-align-items-center mb-0'>
        <Control>
          <Input 
            type='text' 
            size={'small'} 
            value={licensePlate}
            placeholder='Placa'
            autoFocus
            maxLength='7'
            style={{ width: '10rem' }}
            onChange={(e) => setLicensePlate(e.target.value.toUpperCase())}
            required 
          />
        </Control>
        <Control>
          <Input 
            type='date' 
            size={'small'} 
            value={startDate}
            title='Data inicial'
            onChange={(e) => setStartDate(e.target.value)}
          />
        </Control>
        <Control>
          <Input 
            type='date' 
            size={'small'} 
            value={endDate}
            title='Data final'
            onChange={(e) => setEndDate(e.target.value)}
          />
        </Control>
        <Control>
          <Button size={'small'} state={filterLoading} title='Pesquisar'>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </Control>
        <Control>
          <Button type='button' size={'small'} onClick={resetFilter} title='Limpar pesquisar'>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Control>
      </Field>
    </form>
  )
}
