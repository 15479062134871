import moment from 'moment'

export const getDiffTime = (startTime, endTime) => (
  moment.utc( moment(endTime||moment()).diff(moment(startTime)) ).format("HH:mm:ss")
)

export const convertPercentToDecimal = (value) => (
  (value) ? value / 100 : null
)

export const convertDecimalToPercent = (value) => (
  (value) ? value * 100 : null
)

export const handleDownload = (response, filename) => {
  const link = document.createElement("a")
  link.target = "_blank";
  link.download = filename
  link.href = URL.createObjectURL( new Blob([response.data], { type: response.headers['content-type'] }) )
  link.click()
}

export const convertResponseBlobToJson = async (responseError) => {
  const response = new Blob([responseError.data], { type: responseError.headers['content-type'] })
  return JSON.parse(await response.text())
}

const profile = sessionStorage.getItem('profile')
const discount = sessionStorage.getItem('discount');

export const Profile = {
  adm: profile === 'adm',
  pdv: profile === 'pdv',
  user: profile === 'user',
  support: profile === 'support',

  discount
}

export const getDescCategoryPost = (category) => (
  { ADS: 'Publicidade', SERVICE: 'Serviço'}[category]
)
