import { Parking } from "../../components/Adm/Parking";
import { Layout } from "../../components/Layout";

export default function ParkingPage() {
  return (
    <Layout>
      <Parking />
    </Layout>
  );
}
