import { ActivateForm } from "../../components/Forms/Activate";
import LayoutHomePage from "../LayoutHomePage";

export default function ActivatePage() {
  return (
    <LayoutHomePage>
      <ActivateForm />
    </LayoutHomePage>
  );
}
