import { Layout } from '../../components/Layout'
import { DataExtractionList } from '../../components/DataExtraction/list'

export const DataExtractionPage = () => {
  return (
    <Layout controllerName='Extração de Dados'>
      <DataExtractionList />
    </Layout>
  )
}
