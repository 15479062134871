import { useEffect, useState } from 'react'
import { Box, Button, Column, Modal, Progress, Table } from 'rbx'
import moment from 'moment'
import { toast } from 'react-toastify'
import { VehicleService } from '../../../services/api-zad'
import { PaginationCustom } from '../../shared/PaginationCustom'
import { VehicleImage } from '../image'
import { VehicleCompare } from '../compare'
import { VehicleHistoricSearch } from '../search'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faImage, faQrcode } from '@fortawesome/free-solid-svg-icons'
import { IrregularVehicleQrCodeModal } from '../../Modals/IrregularVehicleQrCodeModal'

const configModal = { active: false, page: '', report: false }

export const VehicleHistoricList = () => {
  const [filter, setFilter] = useState({})

  const [irregularitySelected, setIrregularitySelected] = useState('')
  const [irregularities, setIrregularities] = useState([])
  const [pageOfIrregularities, setPageOfIrregularities] = useState([])

  const [parkingSelected, setParkingSelected] = useState('')
  const [parkings, setParkings] = useState([])
  const [pageOfParkings, setPageOfParkings] = useState([])

  const [activeProgress, setActiveProgress] = useState(false)
  const [filterLoading, setFilterLoading] = useState(null)
  const [modal, setModal] = useState(configModal)

  // console.log('VehicleHistoricList - selected: ', irregularitySelected, parkingSelected)

  useEffect(() => {
    setIrregularitySelected('')
    setParkingSelected('')
    if (Object.keys(filter).length) {
      setActiveProgress(true)
      fetchIrregularities()
      fetchParkings()
    } else {
      setIrregularities([])
      setParkings([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filter])

  // IRREGULARITIES

  const fetchIrregularities = async () => {
    try {
      const {startDate, endDate} = filter
      const params = new URLSearchParams({startDate, endDate})
      const response = await VehicleService.irregularities(filter.licensePlate, params)
      setIrregularities(response.data.data);
    } catch(e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setActiveProgress(false)
    setFilterLoading(null)
  }

  const onChangePageIrregularities = (page) => setPageOfIrregularities(page)

  const tableBodyIrregularities = () => {
    return (pageOfIrregularities.length === 0) ? (
      <Table.Row>
        <Table.Cell colSpan="9">
          { (activeProgress) ? <Progress size='small' color='custom-purple' /> : Object.keys(filter).length!==0 && 'Nenhuma irregularidade encontrada.' }
        </Table.Cell>
      </Table.Row>
    ) : (
      pageOfIrregularities.map((item) => (
        <Table.Row 
          key={item.id} 
          className='is-clickable' 
          onClick={() => setIrregularitySelected(item)}
          style={{ backgroundColor: irregularitySelected?.id === item.id ? 'hsl(235deg 100% 96%)' : '' }}
        >
          <Table.Cell>{item.seq}</Table.Cell>
          <Table.Cell>{moment(item.startDate).format('DD/MM/YYYY')}</Table.Cell>
          <Table.Cell>{moment(item.startDate).format('HH:mm:ss')}</Table.Cell>
          <Table.Cell>{item.endDate ? moment(item.endDate).format('HH:mm:ss') : 'Irregular'}</Table.Cell>
          <Table.Cell textAlign='centered'>
            {
              item.endDate ? (
                moment.utc( moment(item.endDate).diff(moment(item.startDate)) ).format('HH:mm:ss')
              ) : (
                '-'
              )
            }
          </Table.Cell>
          <Table.Cell textAlign='centered'>
            <div className='is-flex is-align-items-center is-justify-content-end'>
              {
                item.paidAt
                  ? <FontAwesomeIcon 
                      icon={faDollarSign} 
                      color='green' 
                      title={'Pago em: ' + moment(item.paidAt).format('DD/MM/YYYY HH:mm:ss')}
                      onClick={() => setPageQrCode(item.id)}
                    />
                  : <FontAwesomeIcon 
                      icon={faQrcode} 
                      onClick={() => setPageQrCode(item.id)}
                    />
              }
              <FontAwesomeIcon
                icon={faImage}
                className='is-clickable ml-2'
                style={{fontSize:'1.2rem'}}
                title="Imagem do veículo"
                onClick={() => setPageImage(item)}
              />
            </div>
          </Table.Cell>
        </Table.Row>
      ))
    )
  }

  // PARKINGS

  const fetchParkings = async () => {
    try {
      const {startDate, endDate} = filter
      const params = new URLSearchParams({startDate, endDate})
      const response = await VehicleService.parkings(filter.licensePlate, params)
      setParkings(response.data.data);
    } catch(e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setActiveProgress(false)
    setFilterLoading(null)
  }

  const onChangePageParkings = (page) => setPageOfParkings(page)

  const tableBodyParkings = () => {
    return (pageOfParkings.length === 0) ? (
      <Table.Row>
        <Table.Cell colSpan="9">
          { (activeProgress) ? <Progress size='small' color='custom-purple' /> : Object.keys(filter).length!==0 && 'Nenhuma ativação encontrada.' }
        </Table.Cell>
      </Table.Row>
    ) : (
      pageOfParkings.map((item) => (
        <Table.Row 
          key={item.id} 
          className='is-clickable' 
          onClick={() => setParkingSelected(item)}
          style={{ backgroundColor: parkingSelected?.id === item.id ? 'hsl(235deg 100% 96%)' : '' }}
        >
          <Table.Cell>{item.seq}</Table.Cell>
          <Table.Cell>{moment(item.startDate).format('DD/MM/YYYY')}</Table.Cell>
          <Table.Cell>{moment(item.startDate).format('HH:mm:ss')}</Table.Cell>
          <Table.Cell>{item.endDate ? moment(item.endDate).format('HH:mm:ss') : 'Ativo'}</Table.Cell>
          <Table.Cell textAlign='centered'>
            {
              item.endDate ? (
                moment.utc( moment(item.endDate).diff(moment(item.startDate)) ).format('HH:mm:ss')
              ) : (
                '-'
              )
            }
          </Table.Cell>
          <Table.Cell textAlign='centered'></Table.Cell>
        </Table.Row>
      ))
    )
  }

  const setPageCompare = () => {
    if (!irregularitySelected && !parkingSelected) {
      toast.error('Selecione os itens para comparar')
    } else {
      setModal({
        active: true,
        page: <VehicleCompare closeModal={closeModal} irregularity={irregularitySelected} parking={parkingSelected} />
      })
    }
  }

  const setPageImage = (item) => {
    setModal({
      active: true,
      page: <VehicleImage closeModal={closeModal} irregularity={item} />
    })
  }

  const setPageQrCode = (irregularityId) => {
    setModal({
      active: true,
      page: <IrregularVehicleQrCodeModal closeModal={closeModal} irregularityId={irregularityId} />
    })
  }

  const closeModal = () => setModal(configModal)

  const tableHeader = () => (
    <Table.Row>
      <Table.Heading style={{ width:'10rem' }}>#</Table.Heading>
      <Table.Heading style={{ width:'15rem' }}>Data</Table.Heading>
      <Table.Heading style={{ width:'15rem' }}>Inicio</Table.Heading>
      <Table.Heading style={{ width:'15rem' }}>Fim</Table.Heading>
      <Table.Heading textAlign='centered' style={{ width:'10rem' }}>Duração</Table.Heading>
      <Table.Heading textAlign='centered'></Table.Heading>
    </Table.Row>
  )

  return (
    <>
      <Box>
        <div className='is-flex'>
          <VehicleHistoricSearch setFilter={setFilter} filterLoading={filterLoading} setFilterLoading={setFilterLoading} />
          <Button size={'small'} className='ml-3' onClick={setPageCompare}>
            Comparar
          </Button>
        </div>
      </Box>
      <Column.Group>
        <Column>
          <Box>
            <span color=''>AVISOS DE IRREGULARIDADE</span>
            <div className="table-container">
              <hr className='hr my-3' />
              <Table hoverable fullwidth>
                <Table.Head>
                  { tableHeader() }
                </Table.Head>
                <Table.Body>
                  { tableBodyIrregularities() }
                </Table.Body>
              </Table>
            </div>
            <PaginationCustom items={irregularities} onChangePage={onChangePageIrregularities} pageSize={6} />
          </Box>
        </Column>
        <Column>
          <Box>
            <span color=''>ATIVAÇÃO DE CAD</span>
            <div className="table-container">
              <hr className='hr my-3' />
              <Table hoverable fullwidth>
                <Table.Head>
                  { tableHeader() }
                </Table.Head>
                <Table.Body>
                  { tableBodyParkings() }
                </Table.Body>
              </Table>
            </div>
            <PaginationCustom items={parkings} onChangePage={onChangePageParkings} pageSize={6} />
          </Box>
        </Column>
      </Column.Group>
      <Modal closeOnBlur={true} closeOnEsc={true} active={modal.active} onClose={closeModal}>
        <Modal.Background onClick={closeModal} />
        <Modal.Content>
          { modal.page }
        </Modal.Content>
      </Modal>
    </>
  )
}
