import styled from 'styled-components';

export const Container = styled.div`
  // max-width: 1120px;
  margin: 0 auto;
  padding: 2rem;
  // margin-top: -10rem;
  border-radius: 0.25rem;
  background: var(--shape);

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & + header {
      margin-top: 1rem;
    }
  }

  div {
    background: var(--shape);
    padding: 2rem 2rem;
    border-radius: 0.25rem;
    color: var(--text-title);
    height: fit-content;

    h6 {
      display: block;
      font-size: 1.5rem;
      font-weight: 600;
      color: #000;
    }

    p {
      display: block;
      font-size: 0.9rem;
      font-weight: 400;
      color: var(--text-body);
      margin-top: 1rem;
    }

    strong {
      display: block;
      font-size: 1rem;
      font-weight: 500;
      line-height: 2rem;

      span {
        font-size: 2.5rem;
        font-weight: 600;
        color: var(--blue);
      }
    }

    div {
      background: var(--container);
    }
  }

  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  div {
    padding: 0rem !important;
    background: var(--shape) !important;

    div {
      margin-top: 0rem !important;
      background: var(--shape) !important;

      #pagination-rows {
        margin-top: 1rem !important;
        padding-right: 24px !important;
      }
    }

    td > div {
      background: transparent !important;
    }
  
  }
`;

// export const Container = styled.div`
//   box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
//     rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

//   h2 {
//     margin-bottom: 0.8rem;
//   }

//   h6 {
//     display: block;
//     font-size: 1.5rem;
//     font-weight: 600;
//   }

//   div {
//     padding: 0rem !important;
//     background: var(--shape) !important;

//     div {
//       margin-top: 0rem !important;
//       background: var(--shape) !important;
//     }

//     td > div {
//       background: transparent !important;
//     }
//   }
// `;

export const IconButton = styled.button`
  height: 34px;
  padding: 8px 20px;
  border: none;
  background: transparent;
`;
