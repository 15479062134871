import { useState } from 'react'
import { Card, Checkbox, Label } from 'rbx'
import { CardHeader } from '../../shared/CardHeader'
import Controls from '../../shared/Controls/Controls'
import { HolidayService } from '../../../services/api-zad'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import moment from 'moment'

export const HolidayForm = ({ setReloadList, itemSelected={}, newItem=true }) => {
  const [id, setId] = useState('')
  const [date, setDate] = useState('')
  const [description, setDescription] = useState('')
  const [permanent, setPermanent] = useState(false)
  const [stateButton, setStateButton] = useState('')

  // console.log('HolidayForm', 'Renderer')

  useEffect(() => {
    if (newItem) {
      resetForm()
    } else {
      setId(itemSelected.id)
      setDate(itemSelected.date)
      setDescription(itemSelected.description)
      setPermanent(itemSelected.permanent)
    }
  },[newItem, itemSelected])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setStateButton('loading')
    try {
      const body = { date, description, permanent }
      const response = id ? (
        await HolidayService.update(id, body)
      ) : (
        await HolidayService.create(body)
      )
      toast.success(response.data.message)
      resetForm()
      setReloadList(moment().format())
    } catch (e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setStateButton('')
  }

  const resetForm = () => {
    setId('')
    setDate('')
    setDescription('')
    setPermanent(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader>
          { id ? 'Editar Feriado' : 'Cadastrar Feriado' }
        </CardHeader>
        <Card.Content>
          <Controls.TextField
            label="Data"
            name="date"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
          <Controls.TextField 
            label="Descrição"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value.toUpperCase())}
            placeholder='Descrição'
            required
          />
          <Label>
            <Checkbox 
              className="mr-2"
              checked={permanent}
              onChange={(e) => setPermanent(e.target.checked)}
            /> 
            Permanente
          </Label>
          <hr className="hr" />
          <Controls.ActionButtons
            linkName="Fechar" 
            buttonState={stateButton} 
            buttonName={ id ? 'Atualizar' : 'Cadastrar' }
          />
        </Card.Content>
      </Card>
    </form>
  )
}
