import styled from 'styled-components';

export const Container = styled.form`
  h2 {
    color: var(--text-title);
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  input,
  select {
    width: 100%;
    padding: 0 1.5rem;
    height: 3.5rem;
    border-radius: 0.25rem;

    border: 1px solid #d7d7d7;
    background: var(--input-background);

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }

    & + input,
    & + div {
      margin-top: 1rem;
    }
  }

  div + select {
    margin-top: 1rem;
  }

  button[type='submit'] {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    background: var(--green);
    color: #fff;
    border-radius: 0.25rem;
    border: 0;
    font-size: 1rem;
    margin-top: 1.5rem;
    font-weight: 600;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const Result = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: var(--text-title);
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  img {
    cursor: pointer;
  }
`;

export const IconButton = styled.button`
  padding: 10px;
  border: none;
  background: transparent;
  height: 100%;
  vertical-align: middle;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* width: 80%; */
  }
`;

export const IconButtonContainer = styled.span`
  margin-left: -60px;
`;

export const ImageUpload = styled.input`
  margin-top: 1rem;
  padding: 1.2rem !important;
`;

export const InputsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;

  input + input {
    margin-top: 0rem;
  }
`;
