import { useState } from 'react'
import { Navbar } from 'rbx'
import { Images } from '../../../utils/images';
import "./header.scss"
import { Redirect } from "react-router-dom"
import { Sidebar } from '../Sidebar'
import { API_URL } from '../../../services/api'
import { celPhoneMask, cpfOrCnpjMask } from '../../../utils/masks'
import { UserModal } from '../../Modals/UserModal'
import { RedirectTo } from '../../../utils/redirectTo'
import { UserDropdown } from '../UserDropdown'


export const Header = () => {
  const [redirectTo, setRedirectTo] = useState(null)
  const [userData, setUserData] = useState({});
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  const profile = sessionStorage.getItem('profile')

  async function fetchUser() {
    const id = sessionStorage.getItem("id");
    const res = await fetch(`${API_URL}/users/${id}`, {
      method: "GET",
      cache: "no-store",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("token"),
      },
    });
    const response = await res.json();
    const body = response.data;
    body.cpf_cnpj = cpfOrCnpjMask(body.cpf_cnpj);
    body.phone = celPhoneMask(body.phone.replace("+55", ""));
    setUserData(body);
  }

  const toggleUserModal = () => {
    if (!isUserModalOpen) fetchUser();
    setIsUserModalOpen(!isUserModalOpen);
  }

  if (redirectTo && redirectTo!==window.location.pathname)
    return <Redirect to={{ pathname: redirectTo }} />

  return (
    <Navbar className="is-fixed-top is-flex is-justify-content-space-between">
      <Navbar.Brand className="ml-0">
        <Sidebar profile={profile} />
        <img src={Images.logoZad} alt="logoImage" onClick={()=>setRedirectTo(RedirectTo.dashboard)} style={{cursor: 'pointer'}} />
      </Navbar.Brand>

      <Navbar.Segment align="end" className="navbar-item">
        <UserDropdown setRedirectTo={setRedirectTo} toggleUserModal={toggleUserModal} />
      </Navbar.Segment>
      <UserModal
        isOpen={isUserModalOpen}
        onRequestClose={toggleUserModal}
        userData={userData}
      />
    </Navbar>
  )
}
