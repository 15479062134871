import { Box } from 'rbx'
import { BoxHeader } from '../../shared/BoxHeader'
import { ZadMap } from '../../shared/ZadMap'

export const AreaMap = ({ items=[], closeModal }) => {
  return (
    <Box>
      <BoxHeader title='Mapa' handleClose={closeModal} />
      <ZadMap areasMap={items} mapContainerStyle={{ height: 'calc(100vh - 150px)' }} zoom={16} />
    </Box>
  )
}
