import { Box } from "rbx"
import { useState } from "react"
import { BoxHeader } from "../../shared/BoxHeader"
import Controls from "../../shared/Controls/Controls"

export const VacancySearch = ({ filter, setFilter, closeModal, setFilterLoading }) => {
  const [area, setArea] = useState(filter?.area||'')
  const [address, setAddress] = useState(filter?.address||'')
  const [reference, setReference] = useState(filter?.reference||'')

  const [stateButton, setStateButton] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    setFilterLoading('loading')
    setFilter({
      area, address, reference 
    })
    setStateButton('')
    closeModal()
  }

  return (
    <Box>
      <BoxHeader title='Filtar por' handleClose={closeModal} />
      <form onSubmit={handleSubmit}>
        <Controls.TextField 
          label="Área"
          name="area"
          type="number"
          value={area}
          onChange={(e) => setArea(e.target.value)}
          placeholder='Área'
        />
        <Controls.TextField 
          label="Local"
          name="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder='digite parte do endereço'
        />
        <Controls.TextField 
          label="Referência"
          name="reference"
          value={reference}
          onChange={(e) => setReference(e.target.value)}
          placeholder='digite parte da referência do local'
        />
        <Controls.ActionButtons
          linkName="Fechar"
          handleClickBack={closeModal}
          buttonState={stateButton}
          buttonName='Filtrar'
        />
      </form>
    </Box>
  )
}