import { Column } from "rbx";
import { faMobileAlt, faHeadset, faCar, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import UserService from '../../../services/user';
import '../../../App.scss'
import './styles.scss'
import { useFetch } from '../../../hooks/useFetch';
import { useState } from "react";
import { API_URL } from '../../../services/api';
import { OccupationByArea } from "../../Adm/OccupationByArea";
import { Summary } from "../../Adm/Summary";
import { RechargeUserModal } from "../../Modals/RechargeUserModal";
import { QuickAccess } from "../../Layout/QuickAccess";
import { DistributorWallet } from "../DistributorWallet";


export const DashboardAdm = () => {
  const { response } = useFetch(`${API_URL}/problems/countNotSolved`)
  const [isRechargeUserModalOpen, setIsRechargeUserModalOpen] = useState(false);

  const toggleRechargeUser = () => setIsRechargeUserModalOpen(!isRechargeUserModalOpen)

  const countNotSolvedProblem = response?.data ?? 0

  UserService.cleanStorage();

  const items = [
    { to: "/user/search", icon: faMobileAlt, title: 'Usuário', external: false },
    { to: "/contactUs", icon: faHeadset, title: 'Fale Conosco', className: countNotSolvedProblem ? 'fa-bounce' : '', external: false },
    { to: "/vehicleHistoric", icon: faCar, title: 'Histórico', external: false },
    { to: "#", icon: faDollarSign, title: 'Recarga', click: true, callback: toggleRechargeUser, external: false },
  ]

  return (
    <>
      <RechargeUserModal
        isOpen={isRechargeUserModalOpen}
        onRequestClose={toggleRechargeUser}
      />

      <QuickAccess items={items} />

      <Column.Group className='mb-3'>
        <Column>
          <Column.Group multiline>
            <Column size={"full"}>
              <OccupationByArea />
            </Column>
            <Column size={"full"}>
              <DistributorWallet />
            </Column>
          </Column.Group>
        </Column>
        <Column>
          <Summary />
        </Column>
      </Column.Group>
    </>
  )
}
