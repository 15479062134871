import Modal from 'react-modal'
import { useState } from 'react'
import { toast } from 'react-toastify'
import MaskedInput from 'react-text-mask'
import { maskCardCvv, maskCardExpirationDate, maskCardNumber, unMask } from '../../../../../utils/masks'
import '../styles.css'
import { CardService } from '../../../../../services/api-zad'
import { ModalHeader } from '../../../../shared/ModalHeader'
import moment from 'moment'
// import cep from 'cep-promise'
// import Controls from '../../../../shared/Controls/Controls'

export const CardForm = ({ isOpen, onRequestClose, cardsRefresh, selectCard }) => {
  const [cardNumber, setCardNumber] = useState('')
  const [cardExpirationDate, setCardExpirationDate] = useState('')
  const [cardHolderName, setCardHolderName] = useState('')
  const [cardCvv, setCardCvv] = useState('')
  // const [street, setStreet] = useState('')
  // const [number, setNumber] = useState('')
  // const [neighborhood, setNeighborhood] = useState('')
  // const [complement, setComplement] = useState('')
  // const [zipCode, setZipCode] = useState('')
  // const [city, setCity] = useState('')
  // const [state, setState] = useState('')
  const [loading, setLoading] = useState(false);
  // const [loadZipCode, setLoadZipCode] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    const card = {
      cardNumber: unMask(cardNumber),
      cardHolderName: cardHolderName,
      cardExpirationDate: unMask(cardExpirationDate),
      cvv: cardCvv,
    }

    // let address = {}
    // if (zipCode && street) {
    //   address = {
    //     street,
    //     number,
    //     neighborhood,
    //     zipCode: unMask(zipCode),
    //     city,
    //     state
    //   }
    //   if (complement) address.complement = complement
    // }

    const body = {
      ...card,
      // ...address
    }

    if (!handleValidCard(body.cardExpirationDate)) return

    setLoading(true)

    try {
      const response = await CardService.create(body)
      toast.success(response.data.message)
      cardsRefresh()
      if (selectCard) selectCard(response.data.data, body.cvv)
      setTimeout(closeModal, 3000)
    } catch (e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setLoading(false)
  }

  const handleValidCard = (cardExpirationDate) => {
    const expiration = moment(`01/${cardExpirationDate}`,'DD/MM/YY').endOf('month').format('YYYY-MM-DD')
    if (moment().isAfter(moment(expiration))) {
      toast.error('Cartão com validade expirada!'); return false;
    } else {
      return true
    }
  }

  // const findAddressByCep = async () => {
  //   setLoadZipCode(true)
  //   try {
  //     if (zipCode && zipCode.length===9) {
  //       const address = await cep(zipCode);
  //       setStreet(address.street.toUpperCase());
  //       setNeighborhood(address.neighborhood.toUpperCase());
  //       setCity(address.city.toUpperCase());
  //       setState(address.state.toUpperCase());
  //     } else {
  //       setStreet('');
  //       setNeighborhood('');
  //       setNumber('');
  //       setCity('');
  //       setState('');
  //       toast.error('Digite um CEP completo')
  //     }
  //   } catch(e) {
  //     toast.error(e.message)
  //   }
  //   setLoadZipCode(false)
  // }

  const closeModal = () => {
    setCardNumber('')
    setCardExpirationDate('')
    setCardHolderName('')
    setCardCvv('')

    onRequestClose()
  }

  return (
    <Modal
    isOpen={isOpen}
    onRequestClose={closeModal}
    overlayClassName="react-modal-overlay z-index-5"
    className="react-modal-content"
  >
    <ModalHeader title='Novo Cartão de Crédito' handleClose={onRequestClose} />

    <form className='card-form' onSubmit={handleSubmit}>
      <MaskedInput
        mask={maskCardNumber}
        placeholder="Número do cartão"
        guide={false}
        type="text"
        value={cardNumber}
        onChange={(event) => setCardNumber(event.target.value)}
        required
      />
      <input
        placeholder="Nome no cartão"
        type="text"
        maxLength={25}
        value={cardHolderName}
        onChange={(event) => setCardHolderName(event.target.value.toUpperCase())}
        required
      />
      <div className='columns is-mobile my-1'>
        <div className='column pb-1'>
          <MaskedInput
            mask={maskCardExpirationDate}
            placeholder="Validade (MM/AA)"
            guide={false}
            type="text"
            value={cardExpirationDate}
            onChange={(event) => setCardExpirationDate(event.target.value)}
            required
          />
        </div>
        <div className='column pb-0'>
          <MaskedInput
            mask={maskCardCvv}
            placeholder="CVV"
            guide={false}
            type="text"
            value={cardCvv}
            onChange={(event) => setCardCvv(event.target.value)}
            required
          />
        </div>
      </div>

      <hr className='hr'></hr>
{/*
      <h3>Endereço de Cobrança</h3>

      <hr className='hr'></hr>

      <Controls.ZipCodeField 
        placeholder="CEP"
        className='mb-1'
        value={zipCode}
        onChange={e => setZipCode(e.target.value)}
        onClick={findAddressByCep}
        loading={loadZipCode}
      />
      <input
        placeholder="RUA / AVENIDA / TRAVESSA"
        type="text"
        minLength={4}
        maxLength={80}
        value={street}
        onChange={(event) => setStreet(event.target.value.toUpperCase())}
      />
      <MaskedInput
        mask={maskNumber}
        placeholder="NÚMERO"
        guide={false}
        type="text"
        minLength={1}
        maxLength={8}
        value={number}
        onChange={(event) => setNumber(event.target.value)}
      />
      <input
        placeholder="BAIRRO"
        type="text"
        minLength={2}
        maxLength={50}
        value={neighborhood}
        onChange={(event) => setNeighborhood(event.target.value.toUpperCase())}
      />
      <input
        placeholder="COMPLEMENTO"
        type="text"
        maxLength={100}
        value={complement}
        onChange={(event) => setComplement(event.target.value.toUpperCase())}
      />
      <div className='columns is-mobile my-1'>
        <div className='column pb-1'>
          <input
            placeholder="MUNICIPIO"
            type="text"
            minLength={3}
            maxLength={50}
            value={city}
            onChange={(event) => setCity(event.target.value.toUpperCase())}
              />
        </div>
        <div className='column is-one-quarter pb-0'>
          <input
            placeholder="UF"
            type="text"
            minLength={2}
            maxLength={2}
            value={state}
            onChange={(event) => setState(event.target.value.toUpperCase())}
              />
        </div>
      </div>

      <hr className='hr'></hr>
*/}
      {loading ? (
        <button type="submit" disabled>
          Salvando
        </button>
      ) : (
        <button type="submit">Salvar</button>
      )}
    </form>
  </Modal>
  )
}
