import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { maskCpfCnpj, unMask } from '../../../utils/masks';
import { Container } from '../styles';
import { API_URL } from '../../../services/api';
import { RedirectTo } from '../../../utils/redirectTo';

export function ActivateForm() {
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)

  const [loading, setLoading] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [activationCode, setActivationCode] = useState(searchParams.get('code'));

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const body = { activationCode, cpfCnpj: unMask(cpfCnpj) };

    const res = await fetch(`${API_URL}/active-account`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setActivationCode('');

      setTimeout(() => history.push(RedirectTo.login), 3000);
    }

    setLoading(false);
  };

  return (
    <Container>
      <h2>Ative sua conta</h2>

      <p>Insira o código que recebeu no e-mail para ativar a sua conta.</p>

      <form onSubmit={handleSubmit}>
        <MaskedInput
          mask={maskCpfCnpj}
          placeholder="Cpf ou Cnpj"
          guide={false}
          type="text"
          value={cpfCnpj}
          autoFocus
          onChange={(event) => setCpfCnpj(event.target.value)}
          required
        />

        <input
          placeholder="Código de ativação"
          type="text"
          value={activationCode}
          maxLength={7}
          onChange={(event) => setActivationCode(event.target.value)}
          required
        />

        <div className='is-flex is-justify-content-space-between is-align-items-center'>
          <Link to={RedirectTo.resendCode}>
            <span>Reenviar Código</span>
          </Link>
          <Link to={RedirectTo.login}>
            <span>Acessar Conta</span>
          </Link>
        </div>

        {loading ? (
          <button disabled type="submit">
            Ativar
          </button>
        ) : (
          <button type="submit">Ativar</button>
        )}
      </form>
    </Container>
  );
}
