import Modal from 'react-modal';
import { Button, Icon, Tab } from 'rbx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './styles.css'
import { useState } from 'react';
import { ModalHeader } from '../../../../shared/ModalHeader';
import moment from 'moment';


export function FilterHistoricModal({ isOpen, onRequestClose, filter, setFilter }) {
  const [filterHistoric, setFilterHistoric] = useState(filter)

  const handleChange = (e) => {
    const { name, value } = e.target
    if (Object.keys(filterHistoric).includes('days')) {
      setFilterHistoric({ [name]: value})
    } else {
      setFilterHistoric({ ...filterHistoric, [name]: value})
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setFilter(filterHistoric)
    onRequestClose()
  }

  const TabPeriod = ({ period, description }) => {
    return (
      <Tab
        active={ filterHistoric.days === period.days }
        onClick={() => setFilterHistoric(period)}
      >
        {
          filterHistoric.days === period.days &&
            <Icon size="small">
              <FontAwesomeIcon icon={faCheck} />
            </Icon>
        }
        <span>{description}</span>
      </Tab>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay z-index-5"
      className="react-modal-content"
    >
      <ModalHeader title='Filtrar por:' handleClose={onRequestClose} />

      <div className='columns is-flex-direction-column'>
        <div className='column is-size-5 has-text-weight-medium'>Últimos periodos</div>

        <div className='column'>
          <Tab.Group kind="toggle-rounded" align='centered'>
            <TabPeriod period={{ days: 3 }} description='3 dias' />

            <TabPeriod period={{ days: 15 }} description='15 dias' />

            <TabPeriod period={{ days: 30 }} description='30 dias' />

            <TabPeriod period={{ days: 365 }} description='1 ano' />
          </Tab.Group>
        </div>
      </div>

      <hr className='hr' />

      <form onSubmit={handleSubmit}>
        <div className='columns is-flex-direction-column'>
          <div className='column is-size-5 has-text-weight-medium'>Período Específico</div>

          <div className='column'>
            <div className='columns is-mobile'>
              <div className='column'>
                <span>Data inicial</span>
                <input
                  className='start-date'
                  type="date"
                  name="startDate"
                  min={'2024-01-01'}
                  max={filterHistoric.endDate || moment().format('YYYY-MM-DD')}
                  value={filterHistoric.startDate||''}
                  onChange={handleChange}
                  required={(filterHistoric.endDate||'') !== ''}
                />
              </div>

              <div className='column'>
                <span>Data final</span>
                <input
                  className='end-date'
                  type="date"
                  name="endDate"
                  min={filterHistoric.startDate || moment().format('YYYY-MM-DD')}
                  max={moment().format('YYYY-MM-DD')}
                  value={filterHistoric.endDate||''}
                  onChange={handleChange}
                  required={(filterHistoric.startDate||'') !== ''}
                />
              </div>
            </div>
          </div>
        </div>

        <hr className='hr' />

        <div className='columns is-flex-direction-column'>
          <div className='column'>Consulte com limite máximo de 12 meses entre a data inicial e data final</div>

          <div className='column'>
            <Button type='submit' className='btn primary'>
              APLICAR
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
}
