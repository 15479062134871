import { Layout } from '../../components/Layout'
import { VehicleHistoricList } from '../../components/VehicleHistoric/list'

export const VehicleHistoricPage = () => {
  return (
    <Layout controllerName='Veículo - Historico'>
      <VehicleHistoricList />
    </Layout>
  )
}
