import { Field, Control, Column, Button } from "rbx";

const ActionButton = ({ buttonState, buttonName, ...other }) => {
  return (
    <Field>
      <Control>
        <Column.Group breakpoint="mobile">
          <Column className="has-text-right">
            <Button state={buttonState} color="custom-purple" outlined {...other}>{buttonName}</Button>
          </Column>
        </Column.Group>
      </Control>
    </Field>
  )

}

export default ActionButton;
