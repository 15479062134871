import Geocode from 'react-geocode';
import { env } from '../env';

const GeocodeService = Geocode;

GeocodeService.setApiKey(env.GEOCODE_KEY);
GeocodeService.setLocationType("ROOFTOP")
GeocodeService.setLanguage('pt-BR');
GeocodeService.setRegion('br');
GeocodeService.enableDebug();

export default GeocodeService;
