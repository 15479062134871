import { Route, Redirect } from 'react-router-dom';
import { RedirectTo } from '../utils/redirectTo';

const ProtectedRouteAdm = (props) => {
  const token = sessionStorage.getItem('token')
  const profile = sessionStorage.getItem('profile')

  if (token && !['user', 'pdv'].includes(profile)) return <Route {...props} />
  
  return <Redirect to={RedirectTo.dashboard} />
};

export default ProtectedRouteAdm
