import { useState } from 'react'
import { Box, Button, Icon, Progress, Table } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArchive as fasFileArchive, faSpinner } from '@fortawesome/free-solid-svg-icons'
// import { faFileArchive as farFileArchive } from '@fortawesome/free-regular-svg-icons'
import moment from 'moment'
import { toast } from 'react-toastify'
import { AccountabilityService } from '../../../services/api-zad'
import { PaginationCustom } from '../../shared/PaginationCustom'
import { useFetch } from '../../../hooks/useFetch'
import { API_URL } from '../../../services/api'
import { convertResponseBlobToJson, handleDownload } from '../../../utils'
import { toDecimal, toNumber } from '../../../helpers/toCurrency'
import { env } from '../../../env'

export const AccountabilityList = () => {
  const [pageOfItems, setPageOfItems] = useState([])
  const [load, setLoad] = useState(null)
  const [loadFile1, setLoadFile1] = useState(null)
  // const [loadFile2, setLoadFile2] = useState(null)

  const { 
    response: accountabilityResponse,
    load: accountabilityLoad,
    refresh
  } = useFetch(`${API_URL}/accountability`)

  const items = accountabilityResponse?.data || []

  // console.log('AccountabilityList', 'Rendered')
  
  const downloadFileContabilidade = async (item) => {
    setLoadFile1(item.id)
    try {
      const response = await AccountabilityService.downloadFileContabilidade(item.id)
      const filename = `zad_${ env.ZAD_CITY.toLowerCase() }_prestacao_contas_${ moment(item.competence).format('MM[-]YYYY') }.zip`
      handleDownload(response, filename)
    } catch (e) {
      if (e.response) {
        const data = await convertResponseBlobToJson(e.response)
        toast.error(data.message)
      } else
        toast.error(e.message)
    }
    setLoadFile1(null)
  }

  // const downloadFileClient = async (item) => {
  //   setLoadFile2(item.id)
  //   try {
  //     const response = await AccountabilityService.downloadFileClient(item.id)
  //     const filename = 'zad_medicao_' + moment(item.competence).format('MM[-]YYYY') + '.zip'
  //     handleDownload(response, filename)
  //   } catch (e) {
  //     if (e.response) {
  //       const data = await convertResponseBlobToJson(e.response)
  //       toast.error(data.message)
  //     } else
  //       toast.error(e.message)
  //   }
  //   setLoadFile2(null)
  // }

  const generate = async () => {
    try {
      if (window.confirm('Confirma a geração da prestação de conta?')) {
        setLoad('loading')
        const response = await AccountabilityService.generate()
        toast.success(response.data.message)
        refresh()
      }
    } catch (e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setLoad(null)
  }

  const onChangePage = (ppageOfItems) => setPageOfItems(ppageOfItems)

  const tableHeader = () => (
    <Table.Row>
      <Table.Heading textAlign='centered' style={{ width:'10rem' }}>Competência</Table.Heading>
      <Table.Heading textAlign='centered'>Período</Table.Heading>
      <Table.Heading className='pr-5' textAlign='right'>CAD Vendidos</Table.Heading>
      <Table.Heading className='pr-5' textAlign='right'>Valor Arrecadado</Table.Heading>
      <Table.Heading textAlign='centered' style={{ width:'10rem' }}>Ações</Table.Heading>
    </Table.Row>
  )

  const tableBody = () => {
    return (pageOfItems.length === 0) ? (
      <Table.Row>
        <Table.Cell colSpan="9">
          { (accountabilityLoad) ? <Progress size='small' color='custom-purple' /> : 'Nenhum registro encontado.' }
        </Table.Cell>
      </Table.Row>
    ) : (
      pageOfItems.map((item) => (
        <Table.Row key={item.id}>
          <Table.Cell textAlign='centered'>{moment(item.competence).format('MM/YYYY')}</Table.Cell>
          <Table.Cell textAlign='centered'>
            {moment(item.startDate).format('DD/MM/YYYY')} - {moment(item.endDate).format('DD/MM/YYYY')}
          </Table.Cell>
          <Table.Cell className='pr-5' textAlign='right'>{toNumber(item.cadSold)}</Table.Cell>
          <Table.Cell className='pr-5' textAlign='right'>{toDecimal(item.valueCadSold)}</Table.Cell>
          <Table.Cell textAlign='centered'>
            <Icon size={'medium'} className='is-clickable ml-2' onClick={() => downloadFileContabilidade(item)} title='Planilhas para Contabilidade'>
              {
                item.id === loadFile1
                  ? <FontAwesomeIcon icon={faSpinner}  className='is-size-5' pulse />
                  : <FontAwesomeIcon icon={fasFileArchive} className='is-size-5' />
              }
            </Icon>
            {/* <Icon size={'medium'} className='is-clickable ml-2' onClick={() => downloadFileClient(item)} title='Relatório de prestação de conta - Prefeitura'>
              {
                item.id === loadFile2
                  ? <FontAwesomeIcon icon={faSpinner}  className='is-size-5' pulse />
                  : <FontAwesomeIcon icon={farFileArchive} className='is-size-5' />
              }
            </Icon> */}
          </Table.Cell>
        </Table.Row>
      ))
    )
  }

  return (
    <Box>
      <div className='is-flex is-justify-content-end'>
        <Button type='button' size="small" color="custom-purple" rounded title="Gerar prestação de conta pendente." onClick={generate} state={load}>
          Gerar
        </Button>
      </div>
      <div className="table-container">
        <hr className='hr my-3' />
        <Table hoverable fullwidth>
          <Table.Head>
            { tableHeader() }
          </Table.Head>
          <Table.Body>
            { tableBody() }
          </Table.Body>
        </Table>
      </div>
      <PaginationCustom items={items} onChangePage={onChangePage} />
    </Box>
  )
}
