import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2rem;
  margin-top: -8rem;

  div.container-div {
    background: var(--shape);
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    color: var(--text-title);
    height: fit-content;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & + header {
        margin-top: 1rem;
      }
    }

    h2 {
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
    }

    p {
      display: block;
      font-size: 0.9rem;
      font-weight: 400;
      color: var(--text-body);
      margin-top: 1rem;
    }

    strong {
      font-size: 1.1rem;
      font-weight: 500;
      line-height: 1.5rem;

      span {
        font-size: 2.5rem;
        font-weight: 600;
        color: var(--blue);
      }
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-top: 0rem;
    gap: 1rem;

    div.container-div {
      padding: 0.7rem 1rem;

      p {
        font-size: 1.1rem;
      }
    }
  }
`;

export const Group = styled.span`
  display: block;

  div {
    @media (max-width: 768px) {
      /* padding: 1rem !important; */
      max-width: 100vw;
    }
  }

  @media (max-width: 768px) {
    div {
      max-width: 100vw;
    }

    div + div {
      margin-top: 1rem;
    }
  }
`;
