import { useEffect, useState } from 'react'
import { Box, Column, Icon, Label } from 'rbx'
import { BoxHeader } from '../../shared/BoxHeader'
import Controls from '../../shared/Controls/Controls'
import { SearchReferencesPlace } from '../../shared/SearchReferencesPlace'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import { VacancyService } from '../../../services/api-zad'

export const VacancyForm = ({ itemSelected={}, closeModal, updateList, newItem=true, areas=[] }) => {
  const [id, setId] = useState('')
  const [areaId, setAreaId] = useState('')
  const [quantity, setQuantity] = useState(1)
  const [place, setPlace] = useState('')
  const [reference, setReference] = useState()
  const [references, setReferences] = useState([])

  const [stateButton, setStateButton] = useState('')

  // console.log('VacancyForm - Renderer')

  useEffect(() => {
    if (newItem) {
      resetForm()
    } else {
      setId(itemSelected.id)
      setAreaId(itemSelected.areaId)
      setQuantity(itemSelected.quantity)
      setPlace(itemSelected.street)
      setReferences(itemSelected.references)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[newItem])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setStateButton('loading')
    try {
      const body = { areaId, quantity, place, references }
      const response = id ? (
        await VacancyService.update(id, body)
      ) : (
        await VacancyService.create(body)
      )
      toast.success(response.data.message)
      updateList()
      resetForm()
      closeModal()
    } catch (e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setStateButton('')
  }

  const addReference = (reference) => {
    if (typeof reference === 'object') {
      setReferences([
        ...references, 
        { id: reference.id, name: reference.name, status: 'add' }
      ])
    } else {
      setReferences([
        ...references, 
        { id: null, name: reference, status: 'add' }
      ])
    }
    setReference('')
  }

  const delReference = (reference) => {
    const items = references.filter(r => r.name !== reference.name )
    if (!newItem && reference.id) {
      setReferences([
        ...items, 
        { id: reference.id, name: reference.name, status: 'del' }
      ])
    } else {
      setReferences([...items])
    }
  }

  const resetForm = () => {
    setId('')
    setAreaId('')
    setQuantity(1)
    setPlace('')
    setReference('')
    setReferences([])
  }

  return (
    <Box>
      <BoxHeader 
        title={ id ? 'Editar estacionamento' : 'Cadastrar estacionamento' }
        handleClose={closeModal} 
      />
      <form onSubmit={handleSubmit}>
        <Column.Group className='mb-0' breakpoint='mobile'>
          <Column>
            <Controls.SelectField
              label="Área"
              name="area"
              value={areaId}
              onChange={(e) => setAreaId(e.target.value)}
              options={areas.map(a => ({ value: a.id, description: a.name }))}
              required
            />
          </Column>
          <Column>
            <Controls.TextField 
              label="Quantidade de vagas"
              name="quantity"
              type="number"
              min="1"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              placeholder='quantidade de vagas'
              required
            />
          </Column>
        </Column.Group>
        <Controls.TextField 
          label="Local do estacionamento"
          name="place"
          value={place}
          onChange={(e) => setPlace(e.target.value)}
          placeholder='Local do estacionamento'
          required
        />
        <Label>Escolha referências cadastradas ou adicione novas</Label>
        <SearchReferencesPlace addReference={addReference} />
        <Controls.TextFieldWithPlus
          name="references"
          value={reference}
          onChange={(e) => setReference(e.target.value)}
          placeholder='Adicionar nova referência'
          onClick={() => addReference(reference)}
        />
        <Label>Referências do local:</Label>
        <ul>
          {
            references.map((reference, index) => (
              <li key={index} className='is-flex'>
                {
                  reference.status === 'del' ? (
                    <span className='ml-1 has-text-danger'>- {reference.name}</span>
                  ) : (
                    <>
                      <Icon className='is-clickable' onClick={() => delReference(reference)}>
                        <FontAwesomeIcon icon={faTimes} />
                      </Icon>
                      <span className='ml-1'>{reference.name}</span>
                    </>
                  )
                }
              </li>
            ))
          }
        </ul>
        <hr className='hr' />
        <Controls.ActionButtons
          linkName="Fechar"
          handleClickBack={closeModal}
          buttonState={stateButton}
          buttonName={ id ? 'Atualizar' : 'Cadastrar' }
        />
      </form>
    </Box>
  )
}
