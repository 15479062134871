import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Container, ContainerForm, IconButton, InputGrid } from './styles';
import moment from 'moment';
import IrregularVehicleService from '../../../../services/irregular-vehicle';
import { ConfirmedIrregularityDetail } from '../detail';
import { VehicleImageModal } from '../../../Modals/VehicleImageModal';
import { TitleButtons } from '../../../TitleButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSync } from '@fortawesome/free-solid-svg-icons';
import MaskedInput from 'react-text-mask';
import { maskCpfCnpj, unMask } from '../../../../utils/masks';
import { RedirectTo } from '../../../../utils/redirectTo';
import { Redirect } from 'react-router-dom';
import { Icon } from 'rbx';
import { faImage } from '@fortawesome/free-regular-svg-icons';

export function ConfirmedIrregularityList() {
  const profile = sessionStorage.getItem('profile')
  const cpf     = sessionStorage.getItem('cpfCnpj')
  
  const [cpfCnpj, setCpfCnpj] = useState(profile==='agent' ? cpf : '');
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [loading, setLoading] = useState(false)
  const [detailsData, setDetailsData] = useState({});
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isVehicleImageModalOpen, setIsVehicleImageModalOpen] = useState(false)
  const [confirmedIrregularityData, setConfirmedIrregularityData] = useState([]);
  const [redirectTo, setRedirectTo] = useState('');
  const [vechicleImageUrl, setVechicleImageUrl] = useState('')
  const [refresh, setRefresh] = useState('')
  const [showFilter, setShowFilter] = useState(profile==='prefecture')

  useEffect(() => {
    if (profile==='agent') {
      fetchConfirmedIrregularity()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  const fetchConfirmedIrregularity = async (e) => {
    e?.preventDefault();
    try {
      setLoading(true)
      setShowFilter(false)
      const response = await IrregularVehicleService.confirmedIrregularityHistory(unMask(cpfCnpj), startDate, endDate, false)
      setConfirmedIrregularityData(response.data.data || [])
      // if (!response.data.data) {
        // toast.error('Nenhum registro encontrado.')
      // }
    } catch (error) {
      if (error.response.data)
        toast.error(error.response.data.message)
      else
        toast.error(error.message)
    } finally {
      setLoading(false)
    }
  };

  if (redirectTo) 
  return <Redirect to={{pathname: redirectTo}} />

  const toggleDetailModal = () => {
    if (isDetailModalOpen) setDetailsData({})
    setIsDetailModalOpen(!isDetailModalOpen);
  }

  const toggleVehicleImageModal = () => {
    if (isVehicleImageModalOpen) setVechicleImageUrl('')
    setIsVehicleImageModalOpen(!isVehicleImageModalOpen);
  }

  const theme = createMuiTheme({
    typography: {
      fontFamily: `"Poppins", "Roboto", "Arial", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    // overrides: {
    //   MUIDataTableToolbar: { root: { display: 'none' } },
    // },
  });

  theme.shadows = [];

  const columns = [
    {
      name: 'confirmedAt',
      label: 'Data e hora',
      options: { filter: false, sort: true, display: true },
    },
    {
      name: 'confirmedLocal',
      label: 'Local confirmado',
      options: { filter: false, sort: false, display: true },
    },
    {
      name: 'confirmedLicensePlate',
      label: 'Placa',
      options: { filter: true, sort: true, display: true },
    },
    {
      name: 'imageVehicle',
      label: 'Imagem',
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Icon
              className='is-clickable mx-3'
              style={{fontSize:'1.5rem'}}
              onClick={() => {
                toggleVehicleImageModal();
                setVechicleImageUrl(value);
              }}
            >
              <FontAwesomeIcon icon={faImage} />
            </Icon>
          );
        },
      },
    },
    {
      name: 'Detalhes',
      label: 'Detalhes',
      options: {
        filter: false,
        sort: false,
        display: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Icon
              className='is-clickable mx-3'
              style={{fontSize:'1.5rem'}}
              onClick={() => { 
                setDetailsData(confirmedIrregularityData.find(irregular => irregular.confirmedLicensePlate === tableMeta.rowData[2]))
                toggleDetailModal();
              }}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </Icon>
          );
        },
      },
    },
  ];

  const options = {
    responsive: 'standard',
    draggableColumns: {
      enabled: true,
    },
    selectableRows: 'none',
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    print: 'false',
    filter: 'false',
    download: 'true',
    onDownload: (buildHead, buildBody, columns, data) => {
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    },
    downloadOptions: {
      filename: 'Irregularidades Confirmadas.csv',
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: false,
      },
    },
    textLabels: {
      body: {
        noMatch: 'Nenhum registro encontrado.',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'resultado(s) de', // 1-10 of 30
      },
      toolbar: {
        search: 'Pesquisar',
        viewColumns: 'Selecionar Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        title: 'FILTROS',
        reset: 'limpar',
        all: 'Todos',
      },
      viewColumns: {
        title: 'Mostrar Colunas',
        titleAria: 'Mostrar/Esconder Colunas da Tabela',
      },
    },
    customToolbarSelect: () => {},
    expandableRows: false,
    expandableRowsOnClick: false,
  };

  return profile!=='agent' && confirmedIrregularityData.length === 0 && showFilter ? (
    <ContainerForm onSubmit={fetchConfirmedIrregularity} className="animeLeft">
      <TitleButtons 
        handleBtnBackClick={() => setRedirectTo(RedirectTo.dashboard)}
      >
        <h3>Irregularidades Confirmadas</h3>
      </TitleButtons>
      
      <strong>Parâmetros de pesquisa obrigatórios</strong>

      <InputGrid>
        <div>
          <label htmlFor="cpfCnpj">CPF Agente</label>
          <MaskedInput
            mask={maskCpfCnpj}
            placeholder="CPF Agente"
            guide={false}
            type="text"
            value={cpfCnpj}
            onChange={(event) => setCpfCnpj(event.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="startDate">Data início da autuação</label>
          <input
            placeholder="Data de início"
            type="date"
            value={startDate}
            required
            onChange={(event) => setStartDate(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="endDate">Data fim da autuação</label>
          <input
            placeholder="Data de fim"
            type="date"
            value={endDate}
            required
            onChange={(event) => setEndDate(event.target.value)}
          />
        </div>
        {loading ? (
          <button type="submit" disabled>
            Carregando...
          </button>
        ) : (
          <button type="submit">Visualizar dados</button>
        )}
      </InputGrid>
    </ContainerForm>
  ) : (
    <Container className="animeLeft">
      <VehicleImageModal
        isOpen={isVehicleImageModalOpen}
        onRequestClose={toggleVehicleImageModal}
        vechicleImageUrl={vechicleImageUrl}
      />
      <ConfirmedIrregularityDetail 
        isOpen={isDetailModalOpen}
        onRequestClose={toggleDetailModal}
        detailsData={detailsData}
        refreshList={() => setRefresh(moment().format())}
      />
      {
        profile!=='agent' ? (
          <>
            <TitleButtons 
              handleBtnBackClick={() => {
                setConfirmedIrregularityData([])
                setShowFilter(true)
              }}
            >
              <span style={{display:'flex', alignItems:'center'}}>
                <h3>Irregularidades Confirmadas</h3>
                <IconButton onClick={() => setRefresh(moment().format())}>
                  <FontAwesomeIcon icon={faSync} className={ loading ? 'fa-spin' : '' } title="Atualizar" style={{fontSize:'1.1rem'}} color='var(--text-title)' />
                </IconButton>
              </span>
            </TitleButtons>
            <strong>{`Agente: ${cpfCnpj}`}</strong>
            <strong>{`Periodo da Autuação: ${moment(startDate).format('DD/MM/YYYY')} a ${moment(endDate).format('DD/MM/YYYY')}`}</strong>
          </>
        ) : (
          <span style={{display:'flex', alignItems:'center'}}>
            <h3>Irregularidades Confirmadas</h3>
            <IconButton onClick={() => setRefresh(moment().format())}>
              <FontAwesomeIcon icon={faSync} className={ loading ? 'fa-spin' : '' } title="Atualizar" style={{fontSize:'1.1rem'}} color='var(--text-title)' />
            </IconButton>
          </span>
        )
      }
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          data={confirmedIrregularityData}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </Container>
  )
}
