import { useEffect, useState } from 'react'
import { Box, Column } from 'rbx'
import { BoxHeader } from '../../shared/BoxHeader'
import Controls from '../../shared/Controls/Controls'
import { toast } from 'react-toastify'
import { TimeRuleService } from '../../../services/api-zad'
import moment from 'moment'

export const TimeRuleForm = ({ itemSelected={}, closeModal, refreshList }) => {
  const [id, setId] = useState('')
  const [minHour, setMinHour] = useState('')
  const [maxHour, setMaxHour] = useState('')

  const [stateButton, setStateButton] = useState('')

  console.log('VacancyForm - Renderer')

  useEffect(() => {
    setId(itemSelected.id)
    setMinHour(moment(itemSelected.minHour).format('HH:mm'))
    setMaxHour(moment(itemSelected.maxHour).format('HH:mm'))
  },[itemSelected])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setStateButton('loading')
    try {
      const response = await TimeRuleService.update(id, { minHour, maxHour })
      toast.success(response.data.message)
      refreshList()
      closeModal()
    } catch (e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setStateButton('')
  }

  return (
    <Box>
      <BoxHeader title={'Editar horário de '+ itemSelected.weekday} handleClose={closeModal} />
      <form onSubmit={handleSubmit}>
        <Column.Group className='mb-0' breakpoint='mobile'>
          <Column>
            <Controls.TextField 
              label="Hora inicial"
              name="minHour"
              type="time"
              value={minHour}
              onChange={e => setMinHour(e.target.value)}
              required
            />
          </Column>
          <Column>
            <Controls.TextField 
              label="Hora final"
              name="maxHour"
              type="time"
              value={maxHour}
              onChange={e => setMaxHour(e.target.value)}
              required
            />
          </Column>
        </Column.Group>
        <hr className='hr' />
        <Controls.ActionButtons
          linkName="Fechar"
          handleClickBack={closeModal}
          buttonState={stateButton}
          buttonName='Atualizar'
        />
      </form>
    </Box>
  )
}
