import { Images } from '../../utils/images'

export const ModalHeader = ({ children, title, handleClose }) => {
  return (
    <>
      <div className="is-flex is-justify-content-space-between is-align-items-center">
        <div className='is-flex is-align-items-start'>
          <h2> { title } </h2>

          { children }
        </div>

        <button
          type="button"
          onClick={handleClose}
          className="react-modal-close"
        >
          <img src={Images.closeImg} alt="Close modal" />
        </button>
      </div>

      <hr className="hr" />
    </>
  )
}
