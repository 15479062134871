import { LoginForm } from "../../components/Forms/Login";
import LayoutHomePage from "../LayoutHomePage";

export default function LoginPage() {
  return (
    <LayoutHomePage>
      <LoginForm />
    </LayoutHomePage>
  );
}
