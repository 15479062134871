import { useEffect, useState } from 'react'
import { Box, Label } from 'rbx'
import { BoxHeader } from '../../shared/BoxHeader'
import Controls from '../../shared/Controls/Controls'
import { toast } from 'react-toastify'
import { AdvertisingService } from '../../../services/api-zad'
import moment from 'moment'
import { SearchPartiner } from '../../shared/SearchPartiner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandshake } from '@fortawesome/free-regular-svg-icons'

export const AdvertisingForm = ({ itemSelected={}, closeModal, refreshList }) => {
  const [id, setId] = useState('')
  const [partiner, setPartiner] = useState({})
  const [title, setTitle] = useState('')
  const [category, setCategory] = useState('')
  const [description, setDescription] = useState('')
  const [image1, setImage1] = useState('')
  const [previewImage1, setPreviewImage1] = useState('')
  const [image2, setImage2] = useState('')
  const [previewImage2, setPreviewImage2] = useState('')
  const [expirationDate, setExpirationDate] = useState('')
  const [source, setSource] = useState('')

  const [stateButton, setStateButton] = useState('')

  // console.log('AdvertisingForm', 'Rendered')

  useEffect(() => {
    const setFields = (item) => {
      setId(item?.id || '')
      setPartiner(item?.partiner || {})
      setCategory(item?.category || 'ADS')
      setTitle(item?.title || '')
      setDescription(item?.description || '')
      setImage1(item?.image1 || '')
      setImage2(item?.image2 || '')
      setExpirationDate(moment(item?.expirationDate).format('YYYY-MM-DD') || '')
      setSource(item?.source || '')
    }

    setFields(itemSelected)
  }, [itemSelected])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!image1) return toast.error('Imagem 1 é obrigatória');
    // if (!image2) toast.error('Imagem 2 é obrigatória')

    setStateButton('loading')
    try {
      const formData = new FormData()
      if (partiner?.id) formData.append('parceiroId', partiner.id)
      formData.append('category', category)
      formData.append('title', title)
      formData.append('description', description)
      if (previewImage1) formData.append('image1', image1, image1?.name)
      if (previewImage2) formData.append('image2', image2, image2?.name)
      formData.append('expirationDate', expirationDate+'T23:59')
      formData.append('source', source)
      // formData.append('latitude', latitude)
      // formData.append('longitude', longitude)

      const response = id
        ? await AdvertisingService.update(id, formData)
        : await AdvertisingService.create(formData)
      toast.success(response.data.message)
      refreshList()
      closeModal()
    } catch (e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setStateButton('')
  }

  const previewImage = (previewImg, setPreviewImg) => {
    setPreviewImg(URL.createObjectURL(previewImg))
  }

  return (
    <Box>
      <BoxHeader title={id ? 'Editar Post' : 'Novo Post'} handleClose={closeModal} />
      <form onSubmit={handleSubmit}>
        <SearchPartiner addPartiner={setPartiner} />

        <Label className="mb-2 ml-2 is-size-6">
          <FontAwesomeIcon icon={faHandshake} className='mr-2' />
          {partiner?.name||'selecione um Parceiro'}
        </Label>

        <Controls.SelectField
          label="Categoria"
          name="category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          options={[
            { value: 'ADS', description: 'Publicidade' },
            { value: 'SERVICE', description: 'Serviço' }
          ]}
          required
        />
        <Controls.TextField
          label="Título"
          name="title"
          value={title}
          onChange={e => setTitle(e.target.value)}
          placeholder="Título do post"
          autoFocus
          required
        />
        <Controls.TextareaField
          label="Descrição"
          name="description"
          value={description}
          onChange={e => setDescription(e.target.value)}
          placeholder="Descrição do post"
          rows={8}
          required
        />

        <hr className='hr' />

        {
          previewImage1
            ? <img src={previewImage1} alt='Imagem 1' />
            : image1
              ? <img src={image1} alt='Imagem 1' />
              : <></>
        }
        <Controls.TextField
          label="Imagem 1"
          type="file"
          accept="image/png, image/jpeg"
          onChange={e => {
            setImage1(e.target.files[0])
            previewImage(e.target.files[0], setPreviewImage1)
          }}
          placeholder="Imagem 1"
        />

        <hr className='hr' />

        {
          previewImage2
            ? <img src={previewImage2} alt='Imagem 2' />
            : image2
              ? <img src={image2} alt='Imagem 2' />
              : <></>
        }
        <Controls.TextField
          label="Imagem 2"
          type="file"
          accept="image/png, image/jpeg"
          onChange={e => {
            setImage2(e.target.files[0])
            previewImage(e.target.files[0], setPreviewImage2)
          }}
          placeholder="Imagem 2"
        />

        <hr className='hr' />

        <Controls.TextField
          label="Data de expiração"
          type="date"
          name="expirationDate"
          value={expirationDate}
          onChange={e => setExpirationDate(e.target.value)}
          required
        />
        <Controls.TextField
          label="Endereço Web da postagem (fonte)"
          name="source"
          value={source}
          onChange={e => setSource(e.target.value)}
          placeholder="Endereço Web da postagem (fonte)"
          required
        />
        <hr className='hr' />
        <Controls.ActionButtons
          linkName="Fechar"
          handleClickBack={closeModal}
          buttonState={stateButton}
          buttonName={id ? 'Atualizar' : 'Cadastrar'}
        />
      </form>
    </Box>
  )
}
