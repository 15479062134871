import { useEffect, useState } from 'react'
import { Box, Column, Icon, Title } from 'rbx'
import { Timer } from '../../shared/Timer'
import { useFetch } from '../../../hooks/useFetch'
import { API_URL } from '../../../services/api'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { toNumber } from '../../../helpers/toCurrency'

export const DistributorWallet = () => {
  const [time, setTime] = useState(null)

  const {
    response: walletResponse,
    load: walletLoad,
    refresh: walletRefresh
  } = useFetch(`${API_URL}/centralzad/distributor/wallet`)

  const wallet = walletResponse?.data || {}

  const minutes = 5

  useEffect(() => {
    setTime(moment())

    if (!walletLoad) {
      const interval = setInterval(() => {
        walletRefresh()
        setTime(moment())
      }, minutes * 60 * 1000);
  
      return () => clearInterval(interval)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const Cell = ({ summary }) => {
    return (
      <Column textAlign='centered' className='is-half-mobile pb-0'>
        <div className='p-4' style={{ border: '1px solid lightgrey', borderRadius: '0.3rem' }}>
          <Title
            size={5}
            style={{ color: summary.title === 'id distribuidor' ? 'var(--text-title)' : summary.title === 'respeito' ? '#28a745' : summary.title === 'irregular' ? '#dc3545' : Number(summary.percent) >= 0.66 ? '#dc3545' : Number(summary.percent) >= 0.33 ? '#ffc107' : '#28a745' }}
          >
            {toNumber(summary.value)}
          </Title>
          <Title subtitle size={7}>{summary.title.toUpperCase()}</Title>
        </div>
      </Column>
    )
  }

  return (
    <Box>
      <header>
        <Title size={4}>Carteira do Distribuidor</Title>
        <Title subtitle size={6} style={{ fontSize:'0.9rem' }}>
          Atualiza em: <Timer startDate={time} paidMinutes={minutes} reverse={true} format='mm[:]ss'/>
          {
            walletLoad && (
              <Icon className='fa-spin ml-2' size='small' style={{fontSize:'0.8rem'}}>
                <FontAwesomeIcon icon={faSyncAlt} />
              </Icon>
            )
          }
        </Title>
      </header>


      <header className="mt-4">
        <Title size={6} className="ml-2">APP</Title>
      </header>
      <Column.Group className='mt-0' gapSize={1} breakpoint='mobile' multiline>
        <Cell summary={{ title: 'id distribuidor', value: wallet.app?.codigo || "0" }} />
        <Cell summary={{ title: 'bloqueados', value: wallet.app?.saldoBloqueado || "0" }} />
        <Cell summary={{ title: 'desbloqueados', value: wallet.app?.saldoDesbloqueado || "0" }} />
        <Cell summary={{ title: 'ativados', value: wallet.app?.totalAtivado || "0" }} />
      </Column.Group>

      <header className="mt-4">
        <Title size={6} className="ml-2">PDV</Title>
      </header>
      <Column.Group className='mt-0 mb-2' gapSize={1} breakpoint='mobile' multiline>
        <Cell summary={{ title: 'id distribuidor', value: wallet.pdv?.codigo || "0" }} />
        <Cell summary={{ title: 'bloqueados', value: wallet.pdv?.saldoBloqueado || "0" }} />
        <Cell summary={{ title: 'desbloqueados', value: wallet.pdv?.saldoDesbloqueado || "0" }} />
        <Cell summary={{ title: 'ativados', value: wallet.pdv?.totalAtivado || "0" }} />
      </Column.Group>
    </Box>
  )
}
