import styled from 'styled-components';
import { darken } from 'polished';

const colors = {
  green: '#33CC95',
  red: '#E52E4D',
};

export const RadioBox = styled.button`
  height: 4rem;
  border: 1px solid #d7d7d7;
  border-radius: 0.25rem;

  background: ${(props) =>
    props.isActive ? colors[props.activeColor] : 'transparent'};

  display: flex;
  align-items: center;
  justify-content: center;

  transition: border-color 0.2s;

  &:hover {
    border-color: ${darken(0.1, '#d7d7d7')};
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    filter: ${(props) =>
      props.isActive ? 'brightness(0) invert(1);' : 'none'};
  }

  span {
    display: inline-block;
    font-size: 1rem;
    max-width: 5.1rem;
    color: ${(props) =>
      props.isActive ? 'var(--shape)' : 'var(--text-title)'};

    span {
      font-size: 1.5rem;
      font-weight: 600;
      color: ${(props) => (props.isActive ? 'var(--shape)' : 'var(--blue)')};
    }
  }
`;
