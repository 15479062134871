import { useState } from 'react'
import { Box, Column } from 'rbx'
import { BoxHeader } from '../../shared/BoxHeader'
import Controls from '../../shared/Controls/Controls'
import { ProblemService } from '../../../services/api-zad'
import { toast } from 'react-toastify'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export const ContactUsAnswer = ({ itemSelected = {}, closeModal, setReloadList }) => {
  const [answer, setAnswer] = useState(itemSelected.answer || '')
  const [stateButton, setStateButton] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    setStateButton('loading')
    try {
      const response = await ProblemService.answer(itemSelected.id, { answer })
      setReloadList(moment().format())
      toast.success(response.data.message)
    } catch (e) {
      console.error(e)
      if (e.response.data)
        toast.error(e.response.data.message)
      else
        toast.error(`${e.response.status}: ${e.response.data.errors}`)
    }
    setStateButton('')
    closeModal()
  }

  return (
    <Box>
      <BoxHeader title='Resposta' handleClose={closeModal} />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0">
          <small><strong>Usuário do contato</strong></small>
          <Link to={{ pathname: `/user/search/${itemSelected.user.cpfCnpj}` }} style={{ color:'#363636' }}>
            <FontAwesomeIcon icon={faSearch} className='is-size-6 px-2' style={{width: 'inherit'}} />
          </Link>
          <br />
          {itemSelected.user.name}
        </Column>
        <Column className="pb-0">
          <small><strong>Data do contato</strong></small><br />
          {itemSelected.reportDate}
        </Column>
      </Column.Group>
      <hr className="hr" />

      <small><strong>Descrição</strong></small><br />
      {itemSelected.title}<br />
      - {itemSelected.description}
      <hr className="hr" />

      {
        itemSelected.solved ? (
          <>
            <Column.Group className="mb-0">
              <Column className="pb-0">
                <small><strong>Respondido por</strong></small><br />
                {itemSelected.answerBy.name}
              </Column>
              <Column className="pb-0">
                <small><strong>Data da resposta</strong></small><br />
                {itemSelected.answerAt}
              </Column>
            </Column.Group>

            <hr className="hr" />
            <small><strong>Resposta</strong></small><br />
            - {itemSelected.answer}
          </>
        ) : (
          <form onSubmit={handleSubmit}>
            <small><strong>Resposta</strong></small><br />
            <Controls.TextareaField
              name='answear'
              placeholder='Escreva sua resposta aqui'
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              required
            />
            <Controls.ActionButtons
              linkName="Fechar"
              handleClickBack={closeModal}
              buttonState={stateButton}
              buttonName='Responder'
            />
          </form>
        )
      }
    </Box>
  )
}
