import { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { API_URL } from '../../../../services/api';
import { Container } from './styles';
import { env } from '../../../../env';
import { ModalHeader } from '../../../shared/ModalHeader';
import { useFetch } from '../../../../hooks/useFetch';
import { Box, Dropdown } from 'rbx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Card } from '../CardModal/card';
import { CardForm } from '../CardModal/form';
import MaskedInput from 'react-text-mask';
import { maskCardCvv } from '../../../../utils/masks';
import { ValueContainer } from '../CadsModal/styles';
import { toCurrency } from '../../../../helpers/toCurrency';
import { LoadingModal } from '../../../Modals/LoadingModal';

export function RenewParkingModal({ isOpen, onRequestClose, vehicle }) {
  const [cardCvv, setCardCvv] = useState('');
  const [cardSelected, setCardSelected] = useState({})
  const [modal, setModal] = useState({})
  const [loadingRenew, setLoadingRenew] = useState('');
  // const [loadingFinish, setLoadingFinish] = useState('');

  const profile = sessionStorage.getItem('profile')
  const discount = sessionStorage.getItem('discount');

  const { 
    response: cadResponse,
    load: cadLoad
  } = useFetch(`${API_URL}/transactions`)

  const cadQuantity = cadResponse?.data?.cads || 0

  const { 
    response: cardsResponse,
    refresh: cardsRefresh
  } = useFetch(`${API_URL}/cards`)

  const cards = cardsResponse?.data?.cards || []

  const { 
    response: responseEducationalPeriod 
  } = useFetch(`${API_URL}/educational-period`)

  const educationalPeriod = responseEducationalPeriod?.data || {}

  const handleRenewParking = async (e) => {
    e.preventDefault();
    if (!window.confirm('Confirma estender o estacionamento ?')) return

    setLoadingRenew(true);

    let route = `${API_URL}/parkings`;

    let body = {
      licensePlate: vehicle.licensePlate,
      imei: env.IMEI_DEFAULT,
      firebaseToken: ''
    }

    if (cadQuantity <= 0) {
      route = `${API_URL}/pay-and-renew-parking`
      body = {
        licensePlate: vehicle.licensePlate,
        firebaseToken: '',
        cardId: cardSelected.pagarmeCardId || '',
        cardCvv
      }
    }

    const res = await fetch(route, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();
    setLoadingRenew(false);
    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setTimeout(() => window.location.reload(), 3000);
    }
  };

  // const handleFinishParking = async () => {
  //   if (!window.confirm('Confirma o encerramento ?')) return

  //   setLoadingFinish(true);

  //   const res = await fetch(`${API_URL}/finish-parkings`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: sessionStorage.getItem('token'),
  //     },
  //     body: JSON.stringify({ licensePlate }),
  //   });

  //   const response = await res.json();
  //   setLoadingFinish(false);
  //   if (!res.ok) {
  //     toast.error(response.message);
  //   } else {
  //     toast.success(response.message);
  //     setTimeout(onRequestClose, 3000);
  //   }
  // };

  const DropdownCreditCard = ({ cardsData }) => {
    return (
      <Dropdown>
        <Dropdown.Trigger>
          <h3 className='m-0 is-clickable'>
            <FontAwesomeIcon icon={faCreditCard} className='mr-2' />
            Selecione o Cartão de Crédito
          </h3>
        </Dropdown.Trigger>
        <Dropdown.Menu>
          <Dropdown.Content>
            <Dropdown.Item
              onClick={openCardForm}
            >
              <Box className='is-flex is-align-items-center'>
                <div
                  className='is-flex p-2 is-align-items-center ml-3 is-clickable'
                  style={{ borderRadius:'100%', backgroundColor:'var(--blue)' }}
                >
                  <FontAwesomeIcon icon={faPlus} style={{ color:'var(--shape)', fontSize:'0.7rem' }} />
                </div>
                <span className='ml-2 is-size-6' style={{ color:'var(--text-title)' }}>Novo Cartão de Crédito</span>
              </Box>
            </Dropdown.Item>
            <Dropdown.Divider />
            {cardsData.reverse().map((card, index) => (
              <Dropdown.Item
                key={'card_' + index}
                onClick={() => setCardSelected(card) }>
                <Card card={card} />
              </Dropdown.Item>
            ))}
          </Dropdown.Content>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const closeModal = () => setModal({})

  const openCardForm = () => {
    setModal({
      show: <CardForm
        isOpen={true}
        onRequestClose={closeModal}
        cardsRefresh={cardsRefresh}
      />
    })
  }

  if (cadLoad) {
    return <LoadingModal />
  }

  return (
    <>
      { modal.show }

      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <Container onSubmit={handleRenewParking}>
          <ModalHeader
            title={'Veículo Estacionado: ' + vehicle.licensePlate}
            handleClose={onRequestClose}
          />

          <p><span>Regra:</span> {vehicle.ruleName}</p>

          {
            vehicle.renewable
              ? vehicle.maxCadReached && <p>Você já atingiu o máximo de renovação.</p>
              : <p>Este estacionamento não pode ser renovado.</p>
          }

          {
            vehicle.renewable
              ? vehicle.maxCadReached
                ? <></>
                : <p>Aqui você pode Estender a permanência do seu estacionamento.</p>
              : <></>
          }

          {
            !educationalPeriod.valid && profile !== 'driver' && cadQuantity <= 0 && vehicle.renewable && 
              <>
                <hr className='hr'></hr>

                <DropdownCreditCard cardsData={cards} />
                {
                  Object.keys(cardSelected).length > 0 && 
                    <div>
                      <Card card={cardSelected} />
                      <MaskedInput
                        mask={maskCardCvv}
                        placeholder="CVV"
                        guide={false}
                        type="text"
                        autoFocus
                        value={cardCvv}
                        onChange={(event) => setCardCvv(event.target.value)}
                        required
                      />
                    </div>
                }

                {
                  profile === 'pdv' &&
                    <ValueContainer>
                      <>
                        <h3>
                          Desconto:{' '}
                          <span style={{ color:'var(--green)' }}>
                            { toCurrency(1 * env.CAD_VALUE * (discount / 100)) }
                          </span>
                        </h3>
                        <h3>
                          Valor à pagar:{' '}
                          <span>
                            { toCurrency(1 * env.CAD_VALUE - 1 * env.CAD_VALUE * (discount / 100)) }
                          </span>
                        </h3>
                      </>
                    </ValueContainer>
                }
              </>
          }

          <hr className='hr'></hr>

          {
            vehicle.renewable && (
              vehicle.maxCadReached ? (
                <></>
              ) : (
                loadingRenew ? (
                  <button type="submit" disabled> ESTENDENDO ... </button>
                ) : (
                  <button type="submit"> ESTENDER </button>
                )
              )
            )
          }
        </Container>
      </Modal>
    </>
  );
}
