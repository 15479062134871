import { faCheck, faEdit, faInfoCircle, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Control, Field, Icon, Modal, Progress, Table } from 'rbx'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { ProblemService } from '../../../services/api-zad'
import { PaginationCustom } from '../../shared/PaginationCustom'
import { ContactUsAnswer } from '../answer'
import { ContactUsSearch } from '../search'

const configModal = { active: false, page: '' }

export const ContactUsList = () => {
  const [filter, setFilter] = useState({})
  const [items, setItems] = useState([])
  const [pageOfItems, setPageOfItems] = useState([])

  const [activeProgress, setActiveProgress] = useState(true)
  const [filterLoading, setFilterLoading] = useState(null)
  const [modal, setModal] = useState(configModal)

  const [reloadList, setReloadList] = useState('')

  useEffect(() => {
    fetchProblems()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[reloadList, filter])
  
  const fetchProblems = async () => {
    try {
      const params = new URLSearchParams(filter)
      const response = await ProblemService.index(params)
      setItems(response.data.data);
    } catch(e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setActiveProgress(false)
    setFilterLoading(null)
  }

  const setPageSearch = () => { 
    setModal({
      active: true, 
      page: <ContactUsSearch filter={filter} setFilter={setFilter} closeModal={closeModal} setFilterLoading={setFilterLoading} />
    }) 
  }

  const setPageAnswer = (item) => {
    setModal({
      active: true,
      page: <ContactUsAnswer itemSelected={item} closeModal={closeModal} setReloadList={setReloadList} />
    })
  }

  const closeModal = () => {
    setModal(configModal)
  }

  const tableHeader = () => (
    <Table.Row>
      <Table.Heading>Data</Table.Heading>
      <Table.Heading>Motivo</Table.Heading>
      <Table.Heading>Descrição</Table.Heading>
      <Table.Heading textAlign='centered'>Respondido?</Table.Heading>
      <Table.Heading textAlign='centered'>Ações</Table.Heading>
    </Table.Row>
  )

  const tableBody = () => {
    return (pageOfItems.length === 0) ? (
      <Table.Row>
        <Table.Cell colSpan="9">
          { (activeProgress) ? <Progress size='small' color='custom-purple' /> : 'Não há problemas relatados.' }
        </Table.Cell>
      </Table.Row>
    ) : (
      pageOfItems.map((item, index) => (
        <Table.Row key={index}>
          <Table.Cell>{item.reportDate}</Table.Cell>
          <Table.Cell>{item.title}</Table.Cell>
          <Table.Cell>{item.shortDescription}</Table.Cell>
          <Table.Cell textAlign='centered'>
            {item.solved && <FontAwesomeIcon icon={faCheck} color='green' />}
          </Table.Cell>
          <Table.Cell textAlign='centered'>
            <Icon size={'medium'} className='is-clickable ml-2' title='Resposta' onClick={() => setPageAnswer(item) }>
              <FontAwesomeIcon icon={item.solved ? faInfoCircle : faEdit } />
            </Icon>
          </Table.Cell>
        </Table.Row>
      ))
    )
  }

  const onChangePage = (ppageOfItems) => setPageOfItems(ppageOfItems)

  return (
    <>
      <Box>
        <div className='is-flex is-justify-content-space-between'>
          <Field kind="group" multiline className='is-align-items-center mb-0'>
            <Control className='mb-0'>
              <Button size={'small'} onClick={setPageSearch} state={filterLoading} title='Pesquisar'>
                <FontAwesomeIcon icon={faSearch} /> <span className='ml-2'>Filtrar</span>
              </Button>
            </Control>
            {
              Object.keys(filter).length > 0 && (
                <Control className='mb-0'>
                  <Button type='button' size={'small'} onClick={() => setFilter({})} title='Limpar pesquisar'>
                    <FontAwesomeIcon icon={faTimes} /> <span className='ml-2'>Limpar Filtro</span>
                  </Button>
                </Control>
              ) 
            }
          </Field>
        </div>
        <div className="table-container">
          <hr className='hr my-3' />
          <Table hoverable fullwidth>
            <Table.Head>
              { tableHeader() }
            </Table.Head>
            <Table.Body>
              { tableBody() }
            </Table.Body>
          </Table>
        </div>
        <PaginationCustom items={items} onChangePage={onChangePage} />
      </Box>
      <Modal closeOnBlur={true} closeOnEsc={true} active={modal.active} onClose={closeModal}>
        <Modal.Background onClick={closeModal} />
        <Modal.Content>
          { modal.page }
        </Modal.Content>
      </Modal>
    </>
  )
}
