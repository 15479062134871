import { RadioBox } from './styles';

export const RadioBoxCad = ({ cads, isActive, onClick }) => {
  return (
    <RadioBox
      type="button"
      activeColor="green"
      isActive={isActive}
      onClick={onClick}
    >
      <span>
        <span>{cads}</span> Cartões
      </span>
    </RadioBox>
  )
}

export const RadioBoxPayment = ({ label, image, isActive, onClick }) => {
  return (
    <RadioBox
      type="button"
      activeColor="green"
      isActive={isActive}
      onClick={onClick}
    >
      <img src={image} alt={label} />
      <span>{label}</span>
    </RadioBox>
  )
}
