import { Card, Content, Title, Tag, Icon } from 'rbx';
import { cpfOrCnpjMask, celPhoneMaskWithCountryCode, } from '../../../commons/masks';
import { Controls } from '../Controls';
import moment from 'moment';
import './styles.scss'
import UserService from '../../../services/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { toast } from 'react-toastify';

export const AppDetail = ({ userData, setItemSelected }) => {
  const [loading, setLoading] = useState('')

  const handleRefresh = async () => {
    setLoading('loading')
    await UserService.reload();
    setItemSelected(-Math.random())
    setLoading('')
  }

  const copyClipboard = (text) => {
    navigator.clipboard.writeText(text)
    toast.success('Copiado: ' + text)
  }

  return (
    <Card>
      {userData && (
        <>
          <Card.Header>
            <Card.Header.Title className='is-justify-content-space-between'>
              <span className='is-flex is-align-items-center'>
                Ficha Técnica
                <Icon 
                  className={ loading ? 'ml-1 is-clickable fa-spin' : 'ml-1 is-clickable' }
                  title="Atualizar"
                  onClick={handleRefresh}>
                  <FontAwesomeIcon icon={faSyncAlt} />
                </Icon>
              </span>
              <span>
                { userData.deleted && <Tag color="danger" className="mr-1">Bloqueado</Tag> }
                { !userData.accountActivated && <Tag color="danger" className="mr-1">Inativo</Tag> }
                <Tag 
                  color="custom-purple is-clickable" 
                  title={userData.code}
                  onClick={() => copyClipboard(userData.code)}
                >
                  {userData.profile}
                </Tag>
              </span>
            </Card.Header.Title>
          </Card.Header>
          <Card.Content>
            <Title size={5}>{userData.name}</Title>
            {
              ['pdv','user','frotista'].includes(userData.profile2) ? (
                <p>CPF/CNPJ: <span>{cpfOrCnpjMask(userData.cpf_cnpj)}</span></p>
              ) : (
                <p>Matrícula: <span>{ userData.cpf_cnpj }</span></p>
              )
            }
            {userData.profile2 === 'pdv' && <p>Código: <span>{userData.pdvCode}</span></p>}           
            <p>Fone: <span>{celPhoneMaskWithCountryCode(userData.phone)}</span></p>
            <p className='is-clickable' onClick={() => copyClipboard(userData.email)} title='Copiar Email'>
              Email: <span>{userData.email}</span>
            </p>
            <p>Nascido em: <span>{moment(userData.birthDay).format('DD/MM/YYYY')}</span></p>
            <p>Cliente desde: <span>{userData.customerSince}</span></p>
            {
              userData.deleted && (
                <p style={{ color:'var(--red)' }}>
                  Bloqueado em:
                  <span>{moment(userData.deletedAt).format('DD/MM/YYYY')}</span>
                </p>
              )
            }
            {
              ['monitor'].includes(userData.profile2) && (
                <p>
                  Última localização:
                  <span>
                    {
                      userData.locationHistory
                        ? moment(userData.locationHistory.createdAt).format('DD/MM/YYYY HH:mm:ss')
                        : '-'
                    }
                  </span>
                </p>
              )
            }
            {parseFloat(userData.discount) > 0 && <p>Desconto: <span>{userData.discount}%</span></p>}
            <hr className='hr' />
            <p>Carteira: <span>{userData.cads} CAD</span></p>
            <hr className='hr' />
            <Content>
              <Controls.VehiclesList userData={userData} />
            </Content>
          </Card.Content>
        </>
      )}
    </Card>
  );
};
