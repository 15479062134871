import { Icon, Title } from 'rbx'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const BoxHeader = ({ title, handleClose }) => {
  return (
    <>
      <div className="is-flex is-justify-content-space-between is-align-items-center">
        <Title size={5} className="mb-0">{ title }</Title>
        <Icon className='is-clickable' onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} />
        </Icon>
      </div>
      <hr className="hr" />
    </>
  )
}
