import TextFieldMask from './TextFieldMask';

export const PhoneField = ({ name, label, value, onChange, ...other }) => {
  return (
    <TextFieldMask 
      label={label}
      name={name}
      mask={'(99) 99999-9999'}
      value={value}
      onChange={onChange}
      {...other}
    />
  )
}
