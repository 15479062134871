import { useState } from 'react'
import { Box, Button, Icon, Progress, Table } from 'rbx'
import { PaginationCustom } from '../../shared/PaginationCustom'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { HolidayService } from '../../../services/api-zad'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { faCheck, faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { HolidaySearch } from '../search'


export const HolidayList = ({ reload, setItemSelected }) => {
  const [filter, setFilter] = useState({})
  const [items, setItems] = useState([])
  const [pageOfItems, setPageOfItems] = useState([])

  const [activeProgress, setActiveProgress] = useState(true)
  const [disableLoading, setDisableLoading] = useState(null)
  const [filterLoading, setFilterLoading] = useState(null)

  // console.log('HolidayList', 'Renderer')

  useEffect(() => {
    fetchHolidays()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[reload, filter])
  
  const fetchHolidays = async () => {
    try {
      const response = await HolidayService.index(filter)
      setItems(response.data.data);
    } catch(e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setActiveProgress(false)
    setDisableLoading(null)
    setFilterLoading(null)
  }

  const disableHoliday = async (item) => {
    try {
      if (window.confirm('Confirma a desativação do Feriado?')) {
        setDisableLoading(item)
        const response = await HolidayService.disable(item.id)
        toast.success(response.data.message)
        fetchHolidays()
      }
    } catch (e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
  }

  const tableHeader = () => (
    <Table.Row>
      <Table.Heading>Data</Table.Heading>
      <Table.Heading>Descrição</Table.Heading>
      <Table.Heading>Permanente</Table.Heading>
      <Table.Heading></Table.Heading>
    </Table.Row>
  )

  const tableBody = () => {
    return (pageOfItems.length === 0) ? (
      <Table.Row>
        <Table.Cell colSpan="9">
          { (activeProgress) ? <Progress size='small' color='custom-purple' /> : 'Não há feriados cadastrados.' }
        </Table.Cell>
      </Table.Row>
    ) : (
      pageOfItems.map((item, index) => (
        <Table.Row key={index} style={{ color: item.disabled ? 'red' : '' }}>
          <Table.Cell>{moment(item.date).format('DD/MM/YYYY')}</Table.Cell>
          <Table.Cell>{item.description}</Table.Cell>
          <Table.Cell>{item.permanent && <FontAwesomeIcon icon={faCheck} color={'green'} />}</Table.Cell>
          <Table.Cell textAlign='right'>
            <Icon size={'medium'} className='is-clickable ml-2' title='Editar' onClick={() => setItemSelected(item)}>
              <FontAwesomeIcon icon={faEdit} />
            </Icon>
            {
              !item.disabled && (
                <Icon size={'medium'} className='is-clickable ml-2' title='Desativar' onClick={() => disableHoliday(item)}>
                  {
                    disableLoading?.id === item.id ? (
                      <FontAwesomeIcon icon={faSpinner} pulse />
                    ) : (
                      <FontAwesomeIcon icon={faTrashAlt} />
                    )
                  }
                </Icon>
              )
            }
          </Table.Cell>
        </Table.Row>
      ))
    )
  }

  const onChangePage = (ppageOfItems) => setPageOfItems(ppageOfItems)

  return (
    <Box>
      <div className='is-flex is-justify-content-space-between'>
        <HolidaySearch setFilter={setFilter} filterLoading={filterLoading} setFilterLoading={setFilterLoading} />
        <Button type='button' size="small" color="custom-purple" rounded onClick={() => setItemSelected({})}>
          Novo
        </Button>
      </div>
      <div className="table-container">
        <hr className='hr m-0' />
        <Table hoverable fullwidth>
          <Table.Head>
            { tableHeader() }
          </Table.Head>
          <Table.Body>
            { tableBody() }
          </Table.Body>
        </Table>
      </div>
      <PaginationCustom items={items} onChangePage={onChangePage} />
    </Box>
  )
}
