export const cpfMask = (value) => (
  value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/^(.{14}).+/, '$1')
)

export const cnpjMask = (value) => (
  value
    .replace(/\D/g,'') //Remove tudo o que não é dígito
    .replace(/(\d{2})(\d)/,'$1.$2') //Coloca um ponto entre o terceiro e o quarto dígitos
    .replace(/(\d{3})(\d)/,'$1.$2') //Coloca um ponto entre o terceiro e o quarto dígitos
    .replace(/(\d{3})(\d)/,'$1/$2') //Coloca um ponto entre o terceiro e o quarto dígitos
    .replace(/(\d{4})(\d{1,2})/,'$1-$2') //Coloca um hífen entre o terceiro e o quarto dígitos
    .replace(/^(.{18}).+/, '$1')
)

export const cpfOrCnpjMask = (value='') => (
  (value.replace(/\D/g,'').length > 11) ? cnpjMask(value) : cpfMask(value)
)

export const dateMask = (value) => (
  (!value) ? '' :
  value
    .replace(/\D/g, '') 
    .replace(/(\d{2})(\d)/, '$1/$2') 
    .replace(/(\d{2})(\d{1,4})/, '$1/$2')
    .replace(/^(.{10}).+/, '$1')
)

export const phoneMask = (value) => (
  value
    .replace(/\D/g, '') 
    .replace(/(\d)/, '($1') 
    .replace(/(\d{2})(\d)/, '$1) $2') 
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/^(.{14}).+/, '$1')
)

export const celPhoneMask = (value='') => (
  (value.replace(/\D/g, '').length > 10) ?
    value
      .replace(/\D/g, '') 
      .replace(/(\d)/, '($1') 
      .replace(/(\d{2})(\d)/, '$1) $2') 
      .replace(/(\d{5})(\d{1,4})/, '$1-$2')
      .replace(/^(.{16}).+/, '$1')
   :
    phoneMask(value)
)

export const phoneMaskWithCountryCode = (value) => (
  value
    .replace(/\D/g, '') 
    .replace(/(\d)/, '+$1') 
    .replace(/(\d{2})(\d)/, '$1 ($2') 
    .replace(/(\d{2})(\d)/, '$1) $2') 
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/^(.{19}).+/, '$1') 
)

export const celPhoneMaskWithCountryCode = (value='') => (
  (value.replace(/\D/g, '').length > 12) ?
    value
      .replace(/\D/g, '') 
      .replace(/(\d)/, '+$1') 
      .replace(/(\d{2})(\d)/, '$1 ($2') 
      .replace(/(\d{2})(\d)/, '$1) $2') 
      .replace(/(\d{5})(\d{1,4})/, '$1-$2')
      .replace(/^(.{20}).+/, '$1') 
   :
   phoneMaskWithCountryCode(value)
)

export const placaMask = (value="") => {
  value = value.replace(/[^A-Z0-9]/g, '');
  let result = "";
  for (let i=0; i<value.length; i++) { 
    if (i<=2)
      result += /[A-Z]/.test(value.charAt(i)) ? value.charAt(i) : '';
    if (i===3 || i>=5)
      result += /[0-9]/.test(value.charAt(i)) ? value.charAt(i) : '';
    if (i===4)
      result += /[A-J0-9]/.test(value.charAt(i)) ? value.charAt(i) : '';
  }
  return result.replace(/([A-Z]{3})([0-9])/, '$1-$2').replace(/^(.{8}).+/, '$1');
}

export const cepMask = (value) => (
  value
    .replace(/\D/g, '') 
    .replace(/(\d{5})(\d)/, '$1-$2') 
    .replace(/^(.{9}).+/, '$1')
)

export const latMask = (value) => (
  value
    .replace(/\D/g, '') 
    .replace(/(\d)/, '-$1') 
    .replace(/(\d{1})(\d)/, '$1.$2') 
    .replace(/^(.{10}).+/, '$1')
)

export const lngMask = (value) => (
  value
    .replace(/\D/g, '') 
    .replace(/(\d)/, '-$1') 
    .replace(/(\d{2})(\d)/, '$1.$2') 
    .replace(/^(.{11}).+/, '$1')
)

export const numberMask = (value) => (
  value
    .replace(/\D/g, '') 
    .replace(/^(.{20}).+/, '$1')
)

export const unMask = (value='') => (
  value
    .replace(/[^A-Z0-9]/g, '')
)

export const monetaryMask = (value) => (
  value.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
)

export const currencyMask = (value) => (
  value
    .replace(/\D/g,"") 
    .replace(/(\d{1})(\d{11})$/,"$1.$2")  //coloca ponto antes dos últimos 11 digitos
    .replace(/(\d{1})(\d{8})$/,"$1.$2")  //coloca ponto antes dos últimos 8 digitos
    .replace(/(\d{1})(\d{5})$/,"$1.$2")  //coloca ponto antes dos últimos 5 digitos
    .replace(/(\d{1})(\d{1,2})$/,"$1,$2") //coloca virgula antes dos últimos 2 digitos
    .replace(/^(.{18}).+/, '$1')
)

export const milharMask = (value) => (
  value
    .replace(/\D/g,"") 
    .replace(/(\d{1})(\d{9})$/,"$1.$2")  //coloca ponto antes dos últimos 11 digitos
    .replace(/(\d{1})(\d{6})$/,"$1.$2")  //coloca ponto antes dos últimos 8 digitos
    .replace(/(\d{1})(\d{3})$/,"$1.$2")  //coloca ponto antes dos últimos 5 digitos
    .replace(/^(.{18}).+/, '$1')
)

export const percentMask = (value) => (
  value
    .replace(/\D/g, '') 
    .replace(/(\d+)/, '$1%')
    .replace(/^(.{3}).+/, '$1')
)

