import * as styled from "styled-components";

export default styled.createGlobalStyle`
  :root {
    --background: #f0f2f5;
    --blue: #0c4891;
    --blue2: #0033cc;
    --green: #33cc95;
    --red: #e52e4d;
    --purple: #5d5fef;
    --cian: #00afef;

    --text-title: #363f5f;
    --text-body: #969cb3;
    --shape: #ffffff;
    --container: #e5e5e5;
    --input-background: #e7e9ee;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
  }

  html {
    height: auto;

    @media (max-width: 1080px) {
      font-size: 93.75%; // 15px
    }

    @media (max-width: 768px) {
      font-size: 87.5%; // 14px
    }
  }

  body {
    background: var(--background);
    -webkit-font-smoothing: antialiased;
    height: auto;
  }

  body,
  input,
  select,
  textarea,
  button {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    font-weight: 600;
    color: var(--text-title);
  }

  a {
    text-decoration: none;
    color: #ffffff;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-modal-content {
    width: 100%;
    max-width: 630px;
    max-height: 95%;
    overflow: auto;
    background: var(--shape);
    padding: 2.5rem;
    position: relative;
    border-radius: 0.25rem;

    ::-webkit-scrollbar {
      // Width of vertical scroll bar
      width: 8px;
      // Height of horizontal scroll bar
      height: 10px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 0.25rem;
      background: #c2c9d2;
    }
  }

  .modal-content {
    ::-webkit-scrollbar {
      // Width of vertical scroll bar
      width: 4px;
      // Height of horizontal scroll bar
      height: 5px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 0.25rem;
      background: #c2c9d2;
    }
  }

  .react-modal-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: 0;
    background: transparent;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  .loading {
    display: flex;
    margin: 15rem auto;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 10px solid #ccc;
    border-right-color: transparent;
    animation: loading 1s infinite;
  }

  @keyframes loading {
    to {
      transform: rotate(360deg);
    }
  }

  .animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s forwards;
  }

  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }

  .z-index-5 {
    z-index: 5;
  }

  .z-index-10 {
    z-index: 10;
  }

  .z-index-15 {
    z-index: 15;
  }

  .z-index-20 {
    z-index: 20;
  }

  @media (max-width: 768px) {
    .react-modal-overlay {
      align-items: revert;
      justify-content: revert;
      z-index: 4;
    }

    .react-modal-content {
      max-height: 100%;
      padding: 1rem 1.5rem;
    }
  }
`;
