import { useState, useEffect } from 'react';
import { Pagination } from "rbx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export const PaginationCustom = ({ items, onChangePage, initialPage = 1, pageSize = 10 }) => {
  const [pager, setPager] = useState({});

  useEffect(()=>{
    if (items && items.length >= 0)
      setPage(initialPage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[items]);

  // console.log('PaginationCustom', 'Rendered')

  const setPage = (page) => {
    // get new pager object for specified page
    const pagerTemp = getPager(items.length, page);

    if (page < 1 || page > pagerTemp.totalPages) return;

    // get new page of items from items array
    const pageOfItems = items.slice(pagerTemp.startIndex, pagerTemp.endIndex + 1);

    // update state
    setPager(pagerTemp);

    // call change page function in parent component
    onChangePage(pageOfItems);
  }

  const getPager = (totalItems, currentPage) => {
      // default to first page
      currentPage = currentPage || 1;

      // calculate total pages
      const totalPages = Math.ceil(totalItems / pageSize);

      const startPage = 1;
      const endPage = totalPages;

      // calculate start and end item indexes
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

      // create an array of pages to ng-repeat in the pager control
      const pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

      // return object with all pager properties required by the view
      return {
          totalItems: totalItems,
          currentPage: currentPage,
          pageSize: pageSize,
          totalPages: totalPages,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pages: pages
      };
  }

  return (
    <>
      {
        (pager.pages && pager.pages.length > 1) ?
          <Pagination align="right" size="small" >
            <Pagination.List style={{listStyle: 'none'}}>
              { 
                (pager.currentPage === 1) ? 
                <Pagination.Link align="previous" disabled>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </Pagination.Link> : 
                <Pagination.Link align="previous" onClick={()=>setPage(pager.currentPage - 1)}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </Pagination.Link>
              }
              {
                (pager.currentPage <= 2) ? '' : 
                <>
                  <Pagination.Link onClick={()=>setPage(pager.startPage)}>{pager.startPage}</Pagination.Link>
                  <Pagination.Ellipsis />
                </>
              }
              { (pager.currentPage === 1) ? '' : <Pagination.Link onClick={()=>setPage(pager.currentPage-1)}>{pager.currentPage-1}</Pagination.Link> }
              <Pagination.Link current>{pager.currentPage}</Pagination.Link>
              { (pager.currentPage === pager.totalPages) ? '' : <Pagination.Link onClick={()=>setPage(pager.currentPage+1)}>{pager.currentPage+1}</Pagination.Link> }
              {
                (pager.currentPage >= pager.totalPages-1) ? '' :
                <>
                  <Pagination.Ellipsis />
                  <Pagination.Link onClick={()=>setPage(pager.endPage)}>{pager.endPage}</Pagination.Link>
                </>
              }
              { 
                (pager.currentPage === pager.totalPages) ? 
                <Pagination.Link align="next" disabled>
                  <FontAwesomeIcon icon={faChevronRight} />
                </Pagination.Link> : 
                <Pagination.Link align="next" onClick={()=>setPage(pager.currentPage + 1)}>
                  <FontAwesomeIcon icon={faChevronRight} />
                </Pagination.Link>
              }
            </Pagination.List>
          </Pagination> :
          ''
      }
    </>
  )

}
