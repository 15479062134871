import styled from 'styled-components';

export const Container = styled.form`
  h2 {
    color: var(--text-title);
    font-size: 1.5rem;
    // margin-bottom: 1.5rem;
  }

  div {
    & + input {
      margin-top: 1rem;
    }
  }

  input {
    width: 100%;
    padding: 0 1.5rem;
    height: 3rem;
    border-radius: 0.25rem;

    border: 1px solid #d7d7d7;
    background: var(--input-background);

    font-weight: 500;
    font-size: 1.2rem;
    letter-spacing: 5px;

    &::placeholder {
      color: var(--text-body);
    }

    & + input {
      margin-top: 1rem;
    }
  }

  button[type='button'] {
    width: 100%;
    padding: 0 1.5rem;
    height: 3rem;
    background: var(--green);
    color: #fff;
    border-radius: 1rem;
    border: 0;
    font-size: 1rem;
    // margin-top: 1.5rem;
    font-weight: 600;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  button[type='submit'] {
    width: 100%;
    padding: 0 1.5rem;
    height: 3rem;
    background: var(--red);
    color: #fff;
    border-radius: 1rem;
    border: 0;
    font-size: 1rem;
    // margin-top: 1.5rem;
    font-weight: 600;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const Result = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: var(--text-title);
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  img {
    cursor: pointer;
  }
`;

export const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  input + select,
  input + input {
    margin-top: 0;
  }

  input + input {
    margin-bottom: 1rem;
  }
`;

