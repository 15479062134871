import { Field, Control, Label, Radio } from 'rbx'

const RadioButton = ({ name, radios=[], onChange, ...other }) => {
  return (
    <Field>
      <Control>
        {
          radios.map((radio, index) => 
              <Label>
                <Radio 
                  key={index}
                  name={name} 
                  value={radio.value}
                  onChange={onChange}
                  {...other}
                /> {radio.label}
              </Label>
          )
        }
      </Control>
    </Field>
  )
}

export default RadioButton;
