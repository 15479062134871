import { Layout } from '../../components/Layout'
import { AdvertisingList } from '../../components/Advertising/list'

export const AdvertisingPage = () => {
  return (
    <Layout controllerName='Publicidade'>
      <AdvertisingList />
    </Layout>
  )
}
