import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import UserService from "../services/user"

export const useUser = (id) => {
  const [user, setUser] = useState('')

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await UserService.findBy('id', id)
        setUser(response.data.data)
      } catch (e) {
        if (e.response.data)
          toast.error(e.response.data.message)
        else
          toast.error(e.message)
      }
    }
    fetchUser()
  }, [id])

  return {
    user,
    setUser
  }
}