import { Control, Label, Field, Input, Icon } from "rbx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons'
import InputMask from "react-input-mask";
import './styles.scss'

export const ZipCodeField = ({ name, label, value, onChange, onBlur, onClick, loading, ...other }) => {
  return (
    <>
      {(label) && <Label>{label}</Label>}
      <Field>
        <Control iconRight>
          <InputMask mask={'99999-999'} value={value} onChange={onChange} onBlur={onBlur} maskPlaceholder={null}>
            <Input 
              type={name}
              name={name}
              {...other}
            />
          </InputMask>
          <Icon color="black" align="right" onClick={onClick} title="Informe um CEP e clique para pesquisar">
            {
              loading ? <FontAwesomeIcon className='fa-spin' icon={faSpinner} /> : <FontAwesomeIcon icon={faSearch} />
            }
          </Icon>
        </Control>
      </Field>
    </>
  )
}
