import styled from 'styled-components';

export const Container = styled.div`
  background: var(--shape);
  border: 1px solid lightgrey;
  border-radius: 6px;
  color: var(--text-title);
  padding: 2rem 2rem;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
  //   rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  background: var(--shape);

  header,
  p {
    margin-top: 0 !important;
  }

  div + div {
    margin-top: 0 !important;
  }
`;

export const InfoBoard = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.columns ? '1fr '.repeat(props.columns) : '1fr 1fr 1fr 1fr'};
  gap: 1rem;
  background-color: transparent !important;
  padding: 1rem 0 1.55rem 0 !important;

  @media (max-width: 425px) {
    grid-template-columns: ${(props) => props.columnsBreak ? '1fr '.repeat(props.columns) : '1fr 1fr'};
  }
`;

export const InfoCard = styled.div`
  border: 1px solid lightgrey;
  border-radius: 6px;
  // background-color: transparent !important;
  // border: 1px solid var(--text-body);
  // box-shadow: rgb(0 0 0 / 16%) 0px 10px 36px 0px,
  //   rgb(0 0 0 / 6%) 0px 0px 0px 1px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 7rem;
  padding: 1rem 0 !important;

  span {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--green);
  }

  span.quantity {
    font-size: 1.2rem;
    font-weight: 500;
  }

  strong {
    display: block;
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--text-body);
    margin-top: 0.5rem;
  }

  .red {
    color: var(--red);
  }

  .blue {
    color: var(--blue);
  }

  .purple {
    color: var(--purple);
  }
`;
