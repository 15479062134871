import moment from 'moment'
import { Box, Column } from 'rbx'
import { BoxHeader } from '../../shared/BoxHeader'

export const VehicleCompare = ({ closeModal, irregularity={}, parking={} }) => {
  const irregularityDuration = moment.utc( moment(irregularity.endDate).diff(moment(irregularity.startDate)) ).format('HH[h] mm[m] ss[s]')
  const parkingDuration = moment.utc( moment(parking.endDate).diff(moment(parking.startDate)) ).format('HH[h] mm[m] ss[s]')

  return (
    <Box>
      <BoxHeader title={'Placa: ' + (irregularity.licensePlate || parking.licensePlate)} handleClose={closeModal} />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
        </Column>
        <Column className="pb-0">
          <small><strong>Aviso de Irregularidade</strong></small>
        </Column>
        <Column className="pb-0">
          <small><strong>Ativação do CAD</strong></small>
        </Column>
      </Column.Group>
      <hr className="hr" />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
          <small><strong>Data: </strong></small>
        </Column>
        <Column className="pb-0">
          {irregularity ? moment(irregularity.startDate).format('DD/MM/YYYY') : '-'}
        </Column>
        <Column className="pb-0">
          {parking ? moment(parking.startDate).format('DD/MM/YYYY') : '-'}
        </Column>
      </Column.Group>
      <hr className="hr" />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
          <small><strong>Início: </strong></small>
        </Column>
        <Column className="pb-0">
          {irregularity ? moment(irregularity.startDate).format('HH:mm:ss') : '-'}
        </Column>
        <Column className="pb-0">
          {parking ? moment(parking.startDate).format('HH:mm:ss') : '-'}
        </Column>
      </Column.Group>
      <hr className="hr" />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
          <small><strong>Fim: </strong></small>
        </Column>
        <Column className="pb-0">
          {
            irregularity
              ? irregularity.endDate ? moment(irregularity.endDate).format('HH:mm:ss') : 'Irregular'
              : '-'
          }
        </Column>
        <Column className="pb-0">
          {
            parking
              ? parking.endDate ? moment(parking.endDate).format('HH:mm:ss') : 'Ativo'
              : '-'
          }
        </Column>
      </Column.Group>
      <hr className="hr" />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
          <small><strong>Duração: </strong></small>
        </Column>
        <Column className="pb-0">
          {
            irregularity
              ? irregularity.endDate ? irregularityDuration : '-'
              : '-'
          }
        </Column>
        <Column className="pb-0">
          {
            parking
              ? parking.endDate ? parkingDuration : '-'
              : '-'
          }
        </Column>
      </Column.Group>
      <hr className="hr" />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
          <small><strong>Pago em: </strong></small>
        </Column>
        <Column className="pb-0">
          { irregularity?.paidAt ? moment(irregularity.paidAt).format('DD/MM/YYYY HH:mm:ss') : '-' }
        </Column>
        <Column className="pb-0">
          { '-' }
        </Column>
      </Column.Group>
      <hr className="hr" />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
          <small><strong>Local: </strong></small>
        </Column>
        <Column className="pb-0">
          {irregularity ? irregularity.local : '-'}<br/>
          {
            irregularity.reference ? (
              `Referencia: ${irregularity.reference}`
            ) : ('')
          }
        </Column>
        <Column className="pb-0">
          {parking ? parking.addressName : '-'}
        </Column>
      </Column.Group>
      <hr className="hr" />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
          <small><strong>Feito por: </strong></small>
        </Column>
        <Column className="pb-0">
          {irregularity.createdBy || '-'}
        </Column>
        <Column className="pb-0">
          {parking.createdBy || '-'}
        </Column>
      </Column.Group>
      <hr className="hr" />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
          <small><strong>Perfil: </strong></small>
        </Column>
        <Column className="pb-0">
          {irregularity ? 'Monitor' : '-'}
        </Column>
        <Column className="pb-0">
          {parking ? parking.profileFrom : '-'}
        </Column>
      </Column.Group>
    </Box>
  )
}
