import styled from 'styled-components';

export const Container = styled.main`
  max-width: 920px;
  margin: 0 auto;
  padding: 2.5rem 1rem;

  @media (max-width: 768px) {
    padding: 0 !important;
    max-width: 100vw;
  }
`;
