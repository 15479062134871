import { useState } from "react";
import { TextField } from '../Controls/TextField';
import './styles.scss'

const initialValues = {
  activeSuggestion: 0,
  filteredSuggestions: [],
  showSuggestions: false
}

const Autocomplete = ({ suggestions, name, label, placeholder=null, value, setValue, focus=false, ...other }) => {
  const [state, setState] = useState(initialValues);

  const handleChange = (e) => {
    const { value } = e.target;

    const filteredSuggestions = suggestions.filter(
      suggestion => suggestion.toLowerCase().indexOf(value.toLowerCase()) > -1
    );

    setState({
      activeSuggestion: 0,
      filteredSuggestions: filteredSuggestions,
      showSuggestions: true
    });

    setValue( value.toUpperCase() );
  }

  const handleClick = (e) => {
    setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false
    });
    setValue( e.target.innerText );
  };

  const handleKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = state;

    if (e.keyCode === 13) {
      setState({
        activeSuggestion: 0,
        showSuggestions: false
      });
      setValue( filteredSuggestions[activeSuggestion] );
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      setState({ 
        ...state,
        activeSuggestion: activeSuggestion - 1 
      });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      setState({ 
        ...state,
        activeSuggestion: activeSuggestion + 1 
      });
    }
  };

  const SuggestionsListComponent = () => {
    const { activeSuggestion, filteredSuggestions, showSuggestions } = state;
    let suggestionsList = "";

    if (showSuggestions && value) {
      if (filteredSuggestions.length) {
        suggestionsList = (
          <ul className="suggestions">
            {
              filteredSuggestions.map((suggestion, index) =>
                <li 
                  key={index}
                  className={index===activeSuggestion ? "suggestion-active" : ""} 
                  onClick={handleClick}
                >
                  {suggestion}
                </li>
              )
            }
          </ul>
        );
      }
    }

    return suggestionsList;
  }

  return (
    <>
      <TextField 
        label={label}
        name={name}
        placeholder={placeholder}
        value={value}
        autoFocus
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        autoComplete='off'
        {...other}
      />
      <SuggestionsListComponent />
    </>
  )
}

export default Autocomplete;
