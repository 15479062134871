import {
  faChevronUp,
  faMobileAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Columns, Container, Footer } from "react-bulma-components";
import { Images } from "../../../utils/images";
import "./FooterHome.css";
import { env } from "../../../env";

export const FooterHome = () => {
  return (
    <Footer className="footerhome">
      <Container>
        <Columns>
          <Columns.Column size={'one-quarter'}>
            <img src={Images.logoTransitarGray50} alt="" className="logoTransitarGray50" />
          </Columns.Column>
          <Columns.Column>
            <p>
              { env.COMPANY }
              <br />
              { env.COMPANY_ADDRESS }
              <br />
              CEP: { env.COMPANY_ZIPCOD }
              <br />
              <FontAwesomeIcon icon={faPhone} /> { env.COMPANY_CONTACT_1 } |{" "}
              <FontAwesomeIcon icon={faMobileAlt} /> { env.COMPANY_CONTACT_2 }
            </p>
          </Columns.Column>
          <Columns.Column size={'one-quarter'} textAlign={"right"}>
            <a href="#topo" className="topo">
              <FontAwesomeIcon icon={faChevronUp} />
            </a>
          </Columns.Column>
        </Columns>
      </Container>
    </Footer>
  );
};
