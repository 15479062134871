import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2rem;
  // margin-top: -10rem;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }

  /* @media (max-width: 350px) {
    div {
      padding: 1rem !important;
    }
  } */

  a {
    max-width: 30em;
  }

  }
  div {
    background: var(--shape);
    padding: 2rem 2rem;
    border-radius: 0.25rem;
    color: var(--text-title);
    height: fit-content;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & + header {
        margin-top: 1rem;
      }
    }

    h2,
    h6 {
      display: block;
      font-size: 1.5rem;
      font-weight: 600;
    }

    p {
      display: block;
      font-size: 0.9rem;
      font-weight: 400;
      color: var(--text-body);
      margin-top: 1rem;
    }

    strong {
      display: block;
      font-size: 1rem;
      font-weight: 500;
      line-height: 2rem;

      span {
        font-size: 2.5rem;
        font-weight: 600;
        color: var(--blue);
      }
    }

    div {
      background: var(--container);
    }
  }
`;

export const Group = styled.span`
  display: block;

  div {
    @media (max-width: 425px) {
      /* padding: 1rem !important; */
      max-width: 90vw;
    }
  }

  div + div {
    margin-top: 2rem;
  }
`;
