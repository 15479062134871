import { faDollarSign } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from 'rbx'
import { env } from '../../../../../env'

export const HistoricBuy = ({ payment, onClick }) => {
  return (
    <Box
      className='is-clickable'
      onClick={onClick}
    >
      <div className='is-flex'>
        <div className='my-auto' style={{ flex:'0 1 30px' }}>
          <FontAwesomeIcon className='is-size-4' icon={faDollarSign} style={{ color:'var(--blue)' }} />
        </div>

        <div style={{ color:'var(--text-body)', flex:'1 0' }}>
          <strong className='is-block'>{payment.quantity} {env.CAD_DESC}</strong>
          <span className='is-block'>{payment.date}</span>
          <span>{payment.payment}</span>
        </div>

        <strong className='is-size-5' style={{ color:'var(--blue)' }}>
          {payment.value}
        </strong>
      </div>
    </Box>
  )
}
