import { useState } from 'react'
import { Box } from 'rbx'
import { Images } from '../../../../../utils/images'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { CardService } from '../../../../../services/api-zad'
import { toast } from 'react-toastify'

export const Card = ({ card, cardsRefresh, destroy = false }) => {
  const [destroyLoading, setDestroyLoading] = useState(null)

  const handleDestroy = async (item) => {
    try {
      if (window.confirm('Confirma a exclusão do Cartão?')) {
        setDestroyLoading(item)
        const response = await CardService.destroy(item.id)
        toast.success(response.data.message)
        cardsRefresh()
      }
    } catch (e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setDestroyLoading(null)
  }

  return (
    <Box className='p-2'>
      <div className='is-flex'>
        <div className='is-flex' style={{ flex:'0 1 65px' }}>
          <img src={Images[card?.brand?.toLocaleLowerCase()]} alt="CreditCard" />
        </div>

        <div className='px-3' style={{ flex:'1 0' }}>
          <strong style={{ color:'var(--blue)', fontSize:'1.2rem' }}>**** {card.lastDigits}</strong>
          <span className='is-block' style={{ color: card.expired ? 'var(--red)' : 'var(--text-body)' }}>
            {card.expirationDate} {card.expired ? ' - Expirado' : ''}
          </span>
        </div>

        {
          destroy &&
            <div
              className='is-flex is-justify-content-right p-2 is-clickable is-size-5'
              onClick={() => handleDestroy(card)}
            >
              {
                destroyLoading?.id === card.id
                  ? <FontAwesomeIcon icon={faSpinner} pulse />
                  : <FontAwesomeIcon icon={faTrashAlt} style={{ color:'var(--red)' }} />
              }
            </div>
        }
      </div>
    </Box>
  )
}
