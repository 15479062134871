import addBlueImg from '../assets/add-blue.svg'
import arrowImg from '../assets/arrow.svg'
import arrowLeftImg from '../assets/arrow-left.png'
import barcodeImg from '../assets/barcode.svg'
import barcodeWhiteImg from '../assets/barcode-white.svg'
import closeImg from '../assets/close.svg'
import creditCardImg from '../assets/creditCard.svg'
import editImg from '../assets/edit.svg'
import gpsImg from '../assets/gps.svg'
import homeImg from '../assets/home.png'
import infoImg from '../assets/info.svg'
import photoImg from '../assets/photo.svg'
import pixImg from '../assets/pix.svg'
import pixWhiteImg from '../assets/pix-white.svg'
import giftImg from '../assets/gift.png';
import refreshImg from '../assets/refresh.svg'
import removeBlueImg from '../assets/remove-blue.svg'
import sellImg from '../assets/sell.svg'
import successImg from '../assets/success.svg'
import trafficCopImg from '../assets/traffic-cop-icon-19.png'
import pinMonitorOnline from '../assets/pin-monitor-online.png'
import pinMonitorOffline from '../assets/pin-monitor-offline.png'
import buyImg from '../assets/buy.svg';
import logoutImg from "../assets/logout.svg";
import moneyImg from '../assets/money.svg';
import searchBlackImg from '../assets/search-black.svg';
import addImg from '../assets/add.svg';
import carImg from '../assets/car.svg';
import busImg from '../assets/bus.svg';
import truckImg from '../assets/truck.svg';
import motocycleImg from '../assets/motorcycle.svg';
import burgerIconImg from '../assets/burger.svg'
import userIconImg from '../assets/user-avatar.png'

import appFtz01 from '../assets/app-ftz-01.png'
import appFtz02 from '../assets/app-ftz-02.png'
import appFtz03 from '../assets/app-ftz-03.png'
import appFtz04 from '../assets/app-ftz-04.png'
import appIconBlue from '../assets/app-icon-blue.svg'
import appIconCian from '../assets/app-icon-cian.svg'
import appIconGray from '../assets/app-icon-gray.svg'
import bgPhoto from '../assets/bg-photo.png'
import logoZonaAzul from '../assets/logo-zona-azul.png'
import logoTransitarGray50 from '../assets/logo-transitar-gray50.png'
import logoTransitarGray90 from '../assets/logo-transitar-gray90.png'
import visa from '../assets/visa.svg'
import amex from '../assets/amex.svg'
import mastercard from '../assets/mastercard.svg'
import discover from '../assets/discover.svg'
import paypal from '../assets/paypal.svg'
import diners from '../assets/diners.svg'
import jcb from '../assets/jcb.svg'
import elo from '../assets/elo.svg'
import generic from '../assets/generic.svg'
import hipercard from '../assets/hipercard.svg'

// Sobral
import sobralLogoZad from '../clients/sobral/assets/logo-zad.png'
import sobralLogoZad02 from '../clients/sobral/assets/logo-zad-02.png'
import sobralImgLoja01 from '../clients/sobral/assets/imgLoja01.png'
import sobralImgLoja02 from '../clients/sobral/assets/imgLoja02.png'
import sobralImgLoja03 from '../clients/sobral/assets/imgLoja03.png'
import sobralImgLoja04 from '../clients/sobral/assets/imgLoja04.png'
import sobralImgLoja05 from '../clients/sobral/assets/imgLoja05.png'

// Fortaleza
import fortalezaLogoZad from '../clients/fortaleza/assets/logo-zad.png'
import fortalezaLogoZad02 from '../clients/fortaleza/assets/logo-zad-02.png'
import fortalezaImgLoja01 from '../clients/fortaleza/assets/imgLoja01.png'
import fortalezaImgLoja02 from '../clients/fortaleza/assets/imgLoja02.png'
import fortalezaImgLoja03 from '../clients/fortaleza/assets/imgLoja03.png'
import fortalezaImgLoja04 from '../clients/fortaleza/assets/imgLoja04.png'
import fortalezaImgLoja05 from '../clients/fortaleza/assets/imgLoja05.png'

import { env } from '../env'

const imagesZad = {
  addBlueImg,
  arrowImg,
  arrowLeftImg,
  barcodeImg,
  barcodeWhiteImg,
  closeImg,
  creditCardImg,
  editImg,
  gpsImg,
  homeImg,
  infoImg,
  photoImg,
  pixImg,
  pixWhiteImg,
  giftImg,
  refreshImg,
  removeBlueImg,
  sellImg,
  successImg,
  trafficCopImg,
  pinMonitorOnline,
  pinMonitorOffline,
  buyImg,
  logoutImg,
  moneyImg,
  searchBlackImg,
  addImg,
  carImg,
  busImg,
  truckImg,
  motocycleImg,
  burgerIconImg,
  userIconImg,

  appFtz01,
  appFtz02,
  appFtz03,
  appFtz04,
  appIconBlue,
  appIconCian,
  appIconGray,
  bgPhoto,
  logoZonaAzul,
  logoTransitarGray50,
  logoTransitarGray90,

  visa,
  amex,
  mastercard,
  discover,
  paypal,
  diners,
  jcb,
  elo,
  generic,
  hipercard,
}

const imagesClientZad = {
  sobralLogoZad,
  sobralLogoZad02,
  sobralImgLoja01,
  sobralImgLoja02,
  sobralImgLoja03,
  sobralImgLoja04,
  sobralImgLoja05,

  fortalezaLogoZad,
  fortalezaLogoZad02,
  fortalezaImgLoja01,
  fortalezaImgLoja02,
  fortalezaImgLoja03,
  fortalezaImgLoja04,
  fortalezaImgLoja05,
}

const clientName = env.ZAD_CITY.toLocaleLowerCase()

export const Images = {
  ...imagesZad,
  logoZad:   imagesClientZad[`${clientName}LogoZad`],
  logoZad02: imagesClientZad[`${clientName}LogoZad02`],
  imgLoja01: imagesClientZad[`${clientName}ImgLoja01`],
  imgLoja02: imagesClientZad[`${clientName}ImgLoja02`],
  imgLoja03: imagesClientZad[`${clientName}ImgLoja03`],
  imgLoja04: imagesClientZad[`${clientName}ImgLoja04`],
  imgLoja05: imagesClientZad[`${clientName}ImgLoja05`],
}
