import { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Card, Icon, Title } from 'rbx'
import { CardHeader } from './CardHeader'
import Controls from './Controls/Controls'
import { useFetch } from '../../hooks/useFetch'
import { API_URL } from '../../services/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import UserService from '../../services/user'
import { toast } from 'react-toastify'

export const UserRemove = () => {
  const [redirectTo, setRedirectTo] = useState('')
  const [stateButton, setStateButton] = useState('')

  const {
    response: inactiveResponse,
    load: inactiveLoad,
    refresh: inactiveRefresh
  } = useFetch(`${API_URL}/user/inactive-statistic`)

  const userStatistic = inactiveResponse?.data||{}

  if (redirectTo)
    return <Redirect to={{pathname: redirectTo}} />

  const goToFind = () => setRedirectTo('/dashboard')

  const handleSubmit = async (e) => {
    if (window.confirm('Confirma a notificação e exclusão de usuários inativos?')) {
      e.preventDefault()
      setStateButton('loading')
      try {
        const response = await UserService.notifyAccountDeletion()
        toast.success(response.data.message)
      } catch (e) {
        if (e.response)
          toast.error(e.response.data.message)
        else
          toast.error(e)
      }
      setStateButton('')
    }
  }

  return (
    <Card>
      <CardHeader handleClose={goToFind}>
        <div className='is-flex is-align-items-center'>
          Remover usuários inativos
          <Icon 
            className={ inactiveLoad ? 'ml-1 is-clickable fa-spin' : 'ml-1 is-clickable' }
            title="Atualizar"
            onClick={inactiveRefresh}>
            <FontAwesomeIcon icon={faSyncAlt} />
          </Icon>
        </div>
      </CardHeader>
      <Card.Content>
        <div className="is-flex is-justify-content-space-between is-align-items-center px-2">
          <span className='is-size-5'>Usuários inativos: </span>
          <Title subtitle>{userStatistic.inactive}</Title>
        </div>
        <hr className='hr' />
        <div className="is-flex is-justify-content-space-between is-align-items-center px-2">
          <span className='is-size-5'>Inativos com mais de 30 dias: </span>
          <Title subtitle>{userStatistic.inactiveMoreThan30d}</Title>
        </div>
        <hr className='hr' />
        <div className="is-flex is-justify-content-space-between is-align-items-center px-2">
          <span className='is-size-5'>Inativos a serem notificados e excluídos: </span>
          <Title subtitle className='has-text-danger'>{userStatistic.inactiveMoreThan30d}</Title>
        </div>
        <hr className='hr' />
        <form onSubmit={handleSubmit}>
          <Controls.ActionButtons
            linkName='Fechar'
            handleClickBack={goToFind}
            buttonState={stateButton} 
            buttonName='Remover'
          />
        </form>
      </Card.Content>
    </Card>
  )
}
