import { FinedIrregularityList } from "../../../components/Agent/FinedIrregularity/list";
import { Layout } from "../../../components/Layout";

export default function FinedIrregularityPage() {
  return (
    <Layout>
      <FinedIrregularityList />
    </Layout>
  );
}
