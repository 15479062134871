import { Notification } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import './styles.css'

export const EducationalPeriodNotification = ({ isEducationalPeriod=false, message='' }) => {
  return (
    isEducationalPeriod && (
      <Notification className='is-success is-light is-educational-period py-2 px-4 mb-3' textAlign='centered'>
        <div className='is-size-5 has-text-weight-medium has-text-centered p-2'>
          <FontAwesomeIcon icon={faCheckCircle} className='mr-2' />
          Período Educativo
        </div>
        { message }
      </Notification>
    )
  )
}
