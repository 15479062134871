import Modal from 'react-modal'

export const LoadingModal = () => {
  return (
    <Modal
      isOpen={true}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <div className='loading'></div>
    </Modal>
  )
}
