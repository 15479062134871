import TextFieldMask from './TextFieldMask'

const LicensePlateField = ({ name, label, value, onChange, ...other }) => {
  const digLetter  = /[a-zA-Z]/;
  const digNumber  = /[0-9]/;
  const digSpecial = /[0-9A-Ja-j]/;
  const mask = [digLetter, digLetter, digLetter, '-', digNumber, digSpecial, digNumber, digNumber];

  return (
    <TextFieldMask 
      label={label}
      name={name}
      mask={mask}
      value={value}
      onChange={onChange}
      {...other}
    />
  )

}

export default LicensePlateField
