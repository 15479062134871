import Modal from 'react-modal';
import Routes from './routes';
import GlobalStyle from './styles/global';

Modal.setAppElement('#root');

export default function App() {
  return (
    <>
      <Routes />
      <GlobalStyle />
    </>
  );
}
