import { useState } from 'react'
import { Box, Button, Icon, Modal, Progress, Table } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { PaginationCustom } from '../../shared/PaginationCustom'
import { useFetch } from '../../../hooks/useFetch'
import { API_URL } from '../../../services/api'
import { AreaForm } from '../form'
import { AreaMap } from '../map'

const configModal = { active: false, page: '', map: false }

export const AreaList = () => {
  const [pageOfItems, setPageOfItems] = useState([])
  const [modal, setModal] = useState(configModal)

  const { 
    response: areaResponse,
    load: areLoad,
    refresh: areaRefresh
  } = useFetch(`${API_URL}/areas`)

  const items = areaResponse?.data || []

  // console.log('AreaList', 'Rendered')

  const setPageForm = (item) => {
    setModal({
      active: true,
      page: <AreaForm itemSelected={item||{}} closeModal={closeModal} refreshList={areaRefresh} />,
      map: false
    })
  }

  const setPageMap = () => {
    setModal({
      active: true,
      page: <AreaMap items={items} closeModal={closeModal} />, 
      map: true
    })
  }

  const closeModal = () => setModal(configModal)

  const onChangePage = (ppageOfItems) => setPageOfItems(ppageOfItems)

  const tableHeader = () => (
    <Table.Row>
      <Table.Heading textAlign='centered' style={{ width: '20%' }}>Área</Table.Heading>
      <Table.Heading textAlign='centered' style={{ width: '30%' }}>Total de Vagas</Table.Heading>
      <Table.Heading textAlign='centered' style={{ width: '30%' }}>Cor no mapa</Table.Heading>
      <Table.Heading textAlign='centered' style={{ width: '20%' }}>Ações</Table.Heading>
    </Table.Row>
  )

  const tableBody = () => {
    return (pageOfItems.length === 0) ? (
      <Table.Row>
        <Table.Cell colSpan="9">
          { (areLoad) ? <Progress size='small' color='custom-purple' /> : 'Nenhuma área foi encontrada.' }
        </Table.Cell>
      </Table.Row>
    ) : (
      pageOfItems.map((item) => (
        <Table.Row key={Math.random()} style={{ color: item.disabled ? 'red' : '' }}>
          <Table.Cell textAlign='centered'>{item.name}</Table.Cell>
          <Table.Cell className='px-5' textAlign='centered'>{item.totalVacancies}</Table.Cell>
          <Table.Cell textAlign='centered'>
            <span className='bd-color' style={{ backgroundColor: item.color }}></span>
          </Table.Cell>
          <Table.Cell textAlign='centered'>
            {
              item.name !== 'unmapped' && 
              <Icon size={'medium'} className='is-clickable ml-2' title='Editar' onClick={() => setPageForm(item)}>
                <FontAwesomeIcon icon={faEdit} />
              </Icon>
            }
          </Table.Cell>
        </Table.Row>
      ))
    )
  }

  return (
    <>
      <Box>
        <div className='is-flex is-justify-content-flex-end'>
          <Button className="mr-3" type='button' size="small" color="custom-purple" rounded onClick={setPageMap}>
            Ver no Mapa
          </Button>
          <Button type='button' size="small" color="custom-purple" rounded onClick={setPageForm}>
            Nova
          </Button>
        </div>
        <div className="table-container">
          <hr className='hr my-3' />
          <Table hoverable fullwidth>
            <Table.Head>
              { tableHeader() }
            </Table.Head>
            <Table.Body>
              { tableBody() }
            </Table.Body>
          </Table>
        </div>
        <PaginationCustom items={items} onChangePage={onChangePage} />
      </Box>
      <Modal closeOnBlur={true} closeOnEsc={true} active={modal.active} onClose={closeModal}>
        <Modal.Background onClick={closeModal} />
        <Modal.Content style={{ width: modal.map ? 'calc(100vw - 40px)' : '640px' }}>
          { modal.page }
        </Modal.Content>
      </Modal>
    </>
  )
}
