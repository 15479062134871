import { Column } from 'rbx';
import { OccupationByArea } from '../../Adm/OccupationByArea';

export function DashboardPrefecture() {
  return (
    <Column.Group centered>
      <Column size='half'>
        <OccupationByArea />
      </Column>
    </Column.Group>
  )
}
