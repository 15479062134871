import { IrregularVehicles } from "../../components/Adm/IrregularVehicles";
import { Layout } from "../../components/Layout";

export default function IrregularVehiclesPage() {
  return (
    <Layout>
      <IrregularVehicles />
    </Layout>
  )
}
