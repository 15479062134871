import { useState } from 'react'
import { Control, Icon, Input } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AddressItem, AddressList, Container, ItemIcon } from './styles'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import UserService from '../../../services/user'

export const SearchPartiner = ({ addPartiner }) => {
  const [searchPartiner, setSearchPartiner] = useState('')
  const [partinerList, setPartinerList] = useState([])

  const selectPartiner = async (partiner) => {
    setPartinerList([])
    setSearchPartiner('')
    addPartiner(partiner)
  }

  const fetchPartiners = async (value) => {
    if (value.length > 5) {
      // nothing to do
    } else if (value.length >= 4 ) {
      // const params = new URLSearchParams({ name: value })
      const response = await UserService.findBy('name', value)
      setPartinerList(response.data.data)
    } else {
      if (partinerList.length) setPartinerList([])
    }
  }

  return (
    <Container>
      <Control iconLeft>
        <Input 
          id='search'
          placeholder="Pesquisar parceiro"
          autoComplete='off'
          value={searchPartiner}
          onChange={(event) => {
            fetchPartiners(event.target.value)
            setSearchPartiner(event.target.value)
          }}
        />
        <Icon align="left">
          <FontAwesomeIcon icon={faSearch} />
        </Icon>
        <AddressList references={searchPartiner}>
          {
            partinerList.length ? (
              partinerList.map((partiner, i) => (
                <AddressItem key={i} onClick={() => selectPartiner(partiner)}>
                  <ItemIcon /> { partiner.name }
                </AddressItem>
              ))
            ) : (
              <AddressItem>
                Nenhum parceiro encontrado.
              </AddressItem>
            )
          }
        </AddressList>
      </Control>
    </Container>
  )
}
