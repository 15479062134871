import { useState } from 'react';
import { maskPlate } from '../../../utils/masks';
import MaskedInput from 'react-text-mask';
import Timer from 'react-compound-timer';
import { API_URL } from '../../../services/api';
import { Container, Grid } from './styles';
import { RegisterIrregularVehicleModal } from '../../Modals/RegisterIrregularVehicleModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

export function SearchVehicle({ registrarIrregularidade = false }) {
  const [licensePlate, setLicensePlate] = useState('');
  const [lastLicensePlate, setLastLicensePlate] = useState('');
  const [loading, setLoading] = useState('');
  const [result, setResult] = useState({});
  const [
    isRegisterIrregularVehicleModalOpen,
    setIsRegisterIrregularVehicleModalOpen,
  ] = useState(false);

  function handleOpenRegisterIrregularVehicleModal() {
    setIsRegisterIrregularVehicleModalOpen(true);
  }

  function handleCloseRegisterIrregularVehicleModal() {
    setIsRegisterIrregularVehicleModalOpen(false);
    setLastLicensePlate('');
    setResult({});
  }

  const verifyVehicle = async (e) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      licensePlate: licensePlate.replace('-', ''),
    };

    const res = await fetch(`${API_URL}/monitors/find-vehicle`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();
    setResult(response);

    if (!res.ok) {
      // toast.error(response.message);
    } else {
      // toast.success(response.message);
      setLastLicensePlate(licensePlate);
      setLicensePlate('');
    }

    setLoading(false);
  };

  return (
    <Container>
      <RegisterIrregularVehicleModal
        isOpen={isRegisterIrregularVehicleModalOpen}
        onRequestClose={handleCloseRegisterIrregularVehicleModal}
        lastLicensePlate={lastLicensePlate}
      />

      <form onSubmit={verifyVehicle}>
        <header>
          <h3>Verificar situação do veículo estacionado</h3>
        </header>

        <Grid>
          <MaskedInput
            mask={maskPlate}
            placeholder="Placa"
            guide={false}
            type="text"
            minLength={7}
            value={licensePlate.toUpperCase()}
            onChange={(event) => setLicensePlate(event.target.value)}
            required
          />

          {loading ? (
            <button type="submit" disabled>
              Verificando
            </button>
          ) : (
            <button type="submit">Verificar</button>
          )}
        </Grid>
      </form>

      {result?.data?.freeParking && (
        <Grid>
          {result?.data?.licensePlate}: {result?.message}
          <FontAwesomeIcon icon={faCheckCircle} size="4x" color='var(--green)' />
        </Grid>
      )}

      {result?.data?.irregular && (
        <Grid>
          O veículo {result?.data?.licensePlate} está registrado como irregular!
          <span>
            Tempo restante:
            <strong className="timer irregular" >
              <Timer initialTime={result?.data?.timeReaming} direction="backward">
                {() => (
                  <>
                    <Timer.Hours />h:
                    <Timer.Minutes />m:
                    <Timer.Seconds />s
                  </>
                )}
              </Timer>
            </strong>
          </span>
        </Grid>
      )}

      {result?.data?.parked && (
        <Grid>
          O veículo {result?.data?.licensePlate} está estacionado com cartões!
          <span>
            Tempo restante:
            <strong className="timer">
              <Timer initialTime={result?.data?.timeReaming} direction="backward">
                {() => (
                  <>
                    <Timer.Hours />h:
                    <Timer.Minutes />m:
                    <Timer.Seconds />s
                  </>
                )}
              </Timer>
            </strong>
          </span>
        </Grid>
      )}

      {result?.data?.freeParking === false && 
       result?.data?.irregular === false && 
       result?.data?.parked === false && (
        <Grid>
          O veículo {result?.data?.licensePlate} não está utilizando cartões nesse momento!
          {
            registrarIrregularidade && (
              <button className="red" onClick={handleOpenRegisterIrregularVehicleModal} >
                Registrar irregularidade
              </button>
            )
          }
        </Grid>
      )}
    </Container>
  );
}
