import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  text-align: right;
  
  span {
    color: ${(props) => props.size >= props.maxSize-parseInt(props.maxSize/5) ? 'var(--red)' : 'var(--blue)' };
    font-size: .9rem;
  }
`