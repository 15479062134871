import { ResetPasswordForm } from '../../components/Forms/ResetPassword';
import LayoutHomePage from '../LayoutHomePage';

export default function ResetPasswordPage() {
  return (
    <LayoutHomePage>
      <ResetPasswordForm />
    </LayoutHomePage>
  );
}
