import { ContactUsList } from '../../components/ContactUs/list'
import { Layout } from '../../components/Layout'

export const ContactUsPage = () => {
  return (
    <Layout controllerName='Fale Conosco' breadcrumb='Dashboard / Fale Conosco'>
      <ContactUsList />
    </Layout>
  )
}
