import { useState } from 'react';
import { RenewParkingModal } from '../Modals/RenewParkingModal';
import Timer from 'react-compound-timer';
import { Images } from '../../../utils/images';
import { Container, IconButton } from './styles';
import { Box, Button } from 'rbx';
import moment from 'moment';
import { CancelScheduleParkingModal } from '../Modals/CancelScheduleParkingModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useFetch } from '../../../hooks/useFetch';
import { API_URL } from '../../../services/api';
import { DevelopmentNotification } from '../../shared/DevelopmentNotification';
import { FreeTimeNotification } from '../../shared/FreeTimeNotification';
import { EducationalPeriodNotification } from '../../shared/EducationalPeriodNotification';

export function VehiclesParked() {
  const [modal, setModal] = useState({})

  const { 
    response: responseTimeRuleToday 
  } = useFetch(`${API_URL}/time-rule/today`)

  const timeRuleToday = responseTimeRuleToday?.data || {}

  const { 
    response: responseScheduleParking, 
    refresh: refreshScheduleParking 
  } = useFetch(`${API_URL}/parkings/schedule/active`)
  
  const vehiclesSchedule = responseScheduleParking?.data || []

  const { 
    response: responseVehiclesParked, 
    refresh: refreshVehiclesParked 
  } = useFetch(`${API_URL}/parkings`)
  
  const vehiclesParked = responseVehiclesParked?.data || []

  const { 
    response: responseEducationalPeriod 
  } = useFetch(`${API_URL}/educational-period`)

  const educationalPeriod = responseEducationalPeriod?.data || {}

  const closeModal = (callback) => {
    if (callback) callback()
    setModal({})
  }

  const openRenewParkingModal = (vehicleLicensePlate) => {
    setModal({
      show: <RenewParkingModal
        isOpen={true}
        onRequestClose={() => closeModal(refreshVehiclesParked)}
        vehicleLicensePlate={vehicleLicensePlate}
        vehicleParkedData={ vehiclesParked?.find( veh => veh.licensePlate === vehicleLicensePlate ) }
      />
    })
  }

  const openCancelScheduleParkingModal = (vehicleLicensePlate) => {
    setModal({
      show: <CancelScheduleParkingModal
        isOpen={true}
        onRequestClose={() => closeModal(refreshScheduleParking)}
        vehicleSchedule={vehiclesSchedule?.find(vs => vs.licensePlate === vehicleLicensePlate)}
      />
    })
  }

  return (
    <Container className='container-div'>
      { modal.show }

      <header>
        <h2>Veículos estacionados</h2>
      </header>

      <DevelopmentNotification />

      <FreeTimeNotification isFreeTime={timeRuleToday?.validTimeForParking === false} />

      <EducationalPeriodNotification isEducationalPeriod={educationalPeriod.valid} message={educationalPeriod.message} />

      {vehiclesParked.length > 0 ? (
        vehiclesParked?.map((vehicle) => (
          <Box key={vehicle.licensePlate} className='vehicle is-flex'>
            <img src={Images.carImg} alt="Carro" />
            <div className='is-flex is-flex-direction-column'>
              <strong>{vehicle.licensePlate}</strong>
              <strong className="timer">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <Timer
                  initialTime={vehicle.timeReaming}
                  direction="backward"
                  checkpoints={[{
                    time: 0, callback: () => window.location.reload()
                  }]}
                >
                  {
                  vehicle.timeReaming !== 0 &&
                    <strong style={{ fontSize:'0.9rem', color:'var(--shape)' }}>
                      <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
                    </strong>
                  }
                </Timer>
              </strong>
            </div>
            <IconButton
              onClick={() => openRenewParkingModal(vehicle.licensePlate)}
            >
              <img src={Images.addImg} alt="Renovar estacionamento" />
            </IconButton>
          </Box>
        ))
      ) : (
        <p>Nenhum veículo estacionado.</p>
      )}

      {vehiclesSchedule.length > 0 && (
        vehiclesSchedule?.map((vehicle) => (
          <Box key={vehicle.licensePlate} className='vehicle is-flex'>
            <img src={Images.carImg} alt="Carro" />
            <span>
              <strong>{vehicle.licensePlate}</strong>
              <Button
                size='small'
                fullwidth
                rounded
                style={{ background:'var(--green)', color:'var(--shape)', fontSize:'0.9rem', fontWeight:'500', minWidth:'12rem', width:'100%', paddingTop:'0', paddingBottom:'0', height:'2.2rem' }}
                title={ 'Inicio: '+moment(vehicle.startDate).format('DD/MM HH:mm')}
                onClick={() => {
                  openCancelScheduleParkingModal(vehicle.licensePlate)
                }}
              >
                <FontAwesomeIcon icon={faClock} className='mr-2' />
                {moment(vehicle.startDate).format('DD/MM HH:mm')}
              </Button>
            </span>
            <IconButton
              onClick={() => {
                openCancelScheduleParkingModal(vehicle.licensePlate);
              }}
            >
              <img src={Images.addImg} alt="Cancelar Agendamento" />
            </IconButton>
          </Box>
        ))
      )}
    </Container>
  );
}
