import { Layout } from '../../components/Layout'
import { VacancyList } from '../../components/Vacancy/list'

export const VacancyPage = () => {
  return (
    <Layout controllerName='Locais de estacionamento'>
      <VacancyList />
    </Layout>
  )
}
