import { useState, useEffect } from 'react';
import { Card, Table, Modal, Box, Button, Progress, Icon, Column } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import UserService from '../../../services/user';
import { PaginationCustom } from '../../shared/PaginationCustom';
import { toast } from 'react-toastify';
import { CardHeader } from '../../shared/CardHeader';
import { BoxHeader } from '../../shared/BoxHeader';
import { toCurrency, toNumber } from '../../../helpers/toCurrency'

export const AppSaleHistoryList = ({ userData, goToMenu }) => {
  const [sales, setSales] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [activeProgress, setActiveProgress] = useState(true);
  const [activeModal, setActiveModal] = useState(false);
  const [selectedSale, setSelectedSale] = useState(null);

  const cadSale = sales.reduce((total, sale) => (total + sale?.quantity), 0)

  useEffect(() =>{
    fetchPurchases();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  const fetchPurchases = async () => {
    try {
      const response = await UserService.saleHistory(userData.id);
      setSales(response.data.data);
    } catch(e) {
      if (e.response.data)
        toast.error(e.response.data.message)
      else
        toast.error(e.response.message)
    }
    setActiveProgress(false);
  }
  
  const toggleModal = () => setActiveModal(!activeModal);

  const openModal = (index) => {
    toggleModal();
    setSelectedSale(index);
  }

  const onChangePage = (ppageOfItems) => {
    setPageOfItems(ppageOfItems);
  }

  const renderModal = () => {
    if (selectedSale !== null) {
      const sale = pageOfItems[selectedSale];
      return (
        <Box>
          <BoxHeader title='Detalhes da Venda' handleClose={toggleModal} />
          <Column.Group className='mb-0' breakpoint='mobile'>
            <Column className='pb-0'>
              <small><strong>Data da venda</strong></small><br />
              {sale.date}
            </Column>
            <Column className='pb-0'>
              <small><strong>Quantidade CAD</strong></small><br />
              {sale.quantity} por {toCurrency(sale.value)}
            </Column>
          </Column.Group>

          <hr className="hr" />
          <Column.Group className='mb-0' breakpoint='mobile'>
            <Column className='pb-0'>
              <small><strong>Forma Pagamento</strong></small><br />
              {sale.payment}
            </Column>
            <Column className='pb-0'>
              <small><strong>Canal de Venda</strong></small><br />
              {sale.channel}
            </Column>
          </Column.Group>

          <hr className="hr" />
          <Column.Group className='mb-0' breakpoint='mobile'>
            <Column className='pb-0'>
              <small><strong>Código Autenticação</strong></small><br />
              {sale.authenticateCode}
            </Column>
            <Column className='pb-0'>
              <small><strong>ID Pagarme</strong></small><br />
              {sale.pagarmeTransactionId}
            </Column>
          </Column.Group>

          <hr className="hr" />
          <small><strong>Situação</strong></small><br />
          {sale.statusBr.toUpperCase()}

        </Box>
      )
    }
  }

  return (
    <>
      <Card>
        <CardHeader handleClose={goToMenu}>Histórico de vendas</CardHeader>
        <Card.Content>
          <strong>Resumo:</strong>
          <p className="mb-0">
            {toNumber(cadSale)} CAD vendidos
          </p>
          <hr className='hr my-2'/>
          <div className="table-container">
            <Table fullwidth>
              <Table.Head>
                <Table.Row>
                  <Table.Heading >Data</Table.Heading>
                  <Table.Heading textAlign={'centered'}>CAD</Table.Heading>
                  {/* <Table.Heading textAlign={'right'} className="is-hidden-mobile">Valor</Table.Heading> */}
                  <Table.Heading className="is-hidden-mobile">Forma</Table.Heading>
                  <Table.Heading className="is-hidden-mobile">Status</Table.Heading>
                  <Table.Heading></Table.Heading>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {
                  (pageOfItems.length === 0) ? (
                    <Table.Row key={0}>
                      <Table.Cell colSpan="9">
                        { activeProgress ? <Progress size='small' color='custom-purple' /> : 'Não há vendas para este usuário.' }
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    pageOfItems.map((sale, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>{sale.date}</Table.Cell>
                        <Table.Cell textAlign={'centered'}>{sale.quantity}</Table.Cell>
                        {/* <Table.Cell textAlign={'right'} className="is-hidden-mobile">{toDecimal(sale.value)}</Table.Cell> */}
                        <Table.Cell className="is-hidden-mobile">{sale.payment}</Table.Cell>
                        <Table.Cell className="is-hidden-mobile">{sale.payment === 'Cortesia' ? sale.payment : sale.statusBr}</Table.Cell>
                        <Table.Cell>
                          <Icon size={'medium'} className='is-clickable ml-2' title='Detalhes' onClick={() => openModal(index)} >
                            <FontAwesomeIcon icon={faInfoCircle} size='lg' />
                          </Icon>
                        </Table.Cell>
                      </Table.Row>
                    ))
                  )
                }
              </Table.Body>
            </Table>
          </div>
          <div className="is-flex is-justify-content-space-between is-align-items-center">
            <Button type="button" onClick={goToMenu} color='danger' outlined>
              Fechar
            </Button>
            <PaginationCustom items={sales} onChangePage={onChangePage} />
          </div>
        </Card.Content>
      </Card>

      <Modal closeOnBlur={true} closeOnEsc={true} active={activeModal} onClose={toggleModal}>
        <Modal.Background onClick={toggleModal} />
        <Modal.Content>
          { renderModal() }
        </Modal.Content>
        <Modal.Close />
      </Modal>
    </>
  )
}
