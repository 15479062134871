import { Route, Redirect } from 'react-router-dom';
import { RedirectTo } from '../utils/redirectTo';

const ProtectedRoute = (props) => {
  const token = sessionStorage.getItem('token')

  if (token) return <Route {...props} />
  
  return <Redirect to={RedirectTo.login} />
};

export default ProtectedRoute;
