import { useEffect, useState } from 'react'
import { Box, Column } from 'rbx'
import { BoxHeader } from '../../shared/BoxHeader'
import Controls from '../../shared/Controls/Controls'
import { toast } from 'react-toastify'
import { AreaService } from '../../../services/api-zad'

export const AreaForm = ({ itemSelected={}, closeModal, refreshList }) => {
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [color, setColor] = useState('')
  const [perimeter, setPerimeter] = useState('')

  const [stateButton, setStateButton] = useState('')

  // console.log('VacancyForm - Renderer')

  useEffect(() => {
    if (itemSelected.id) {
      setId(itemSelected.id)
      setName(itemSelected.name)
      setColor(itemSelected.color)
      setPerimeter(itemSelected.perimeter)
    } else {
      setId('')
      setName('')
      setColor('')
      setPerimeter('')
    }
  },[itemSelected])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setStateButton('loading')
    try {
      const response = id
        ? await AreaService.update(id, { name, perimeter, color })
        : await AreaService.create({ name, perimeter, color })
      toast.success(response.data.message)
      refreshList()
      closeModal()
    } catch (e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setStateButton('')
  }

  return (
    <Box>
      <BoxHeader title={id ? 'Editar Área: '+ itemSelected.name : 'Nova Área'} handleClose={closeModal} />
      <form onSubmit={handleSubmit}>
        <Column.Group className='mb-0' breakpoint='mobile'>
          <Column>
            <Controls.TextFieldMask
              label="Número da Área"
              name="name"
              mask={'99'}
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder="Número da área"
              autoFocus
              required
            />
          </Column>
          <Column>
            <Controls.TextField
              label="Cor no Mapa"
              name="color"
              type="color"
              value={color}
              onChange={e => setColor(e.target.value)}
              placeholder="Cor da área no mapa"
              required
            />
          </Column>
        </Column.Group>
        <Controls.TextareaField
          label="Perímetro da Área"
          name="perimeter"
          value={perimeter}
          onChange={e => setPerimeter(e.target.value)}
          placeholder="Lista de coordenadas que definem o perímetro da área"
          rows={7}
          required
        />
        <hr className='hr' />
        <Controls.ActionButtons
          linkName="Fechar"
          handleClickBack={closeModal}
          buttonState={stateButton}
          buttonName={id ? 'Atualizar' : 'Cadastrar'}
        />
      </form>
    </Box>
  )
}
