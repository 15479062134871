import { Notification } from 'rbx'
import { env } from '../../../env'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import './styles.css'

export const DevelopmentNotification = () => {
  return (
    env.isDevelopment && (
      <Notification className='is-danger is-light is-development py-2 px-4 mb-3' textAlign='centered'>
        <div className='is-size-6 has-text-weight-medium has-text-centered p-2'>
          <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
          Ambiente de Testes
        </div>
        As operações que você realizar são apenas simulações, e não criam movimentações reais.
      </Notification>
    )
  )
}
