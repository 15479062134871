import styled from 'styled-components';

export const Container = styled.div`
  header {
    padding: 0.7rem 0;
  }

  div.box {
    border: 1px solid rgb(210 210 210 / 1);
    border-radius: 0.5rem;
    padding: 1rem 1rem !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    strong {
      display: flex;
      justify-content: center;
    }
  }

  div.vehicle {
    background: var(--shape) !important;
    margin-top: 0rem !important;
    margin-bottom: 0.8rem !important;
  }

  div + div {
    margin-top: 1rem !important;
  }

  strong {
    & + strong,
    & + button {
      margin-top: 0.5rem;
    }
  }

  .timer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    min-width: 12rem;
    background: var(--blue);
    border-radius: 1rem;
    color: var(--shape);
    // font-size: 1rem;
    font-weight: 500;
    padding: 0.3rem 0;
  }

  @media (max-width: 768px) {
    padding-bottom: 3rem !important;
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
    margin-top: -1rem;
    padding-top: 0 !important;

    header {
      background: var(--shape);
      border-bottom: 2px dashed rgb(210 210 210 / 1);
      padding: 0.7rem 0;
      position: sticky;
      top: 0;
      z-index: 2;
      margin-bottom: 1rem;
    }
  }
`;

export const IconButton = styled.button`
  height: 34px;
  padding: 8px;
  border: none;
  background: transparent;
`;
