import styled from "styled-components";

export const IconButtonContainer = styled.span`
  display: inline-block !important;
  position: relative;
  margin-left: -42px;
`;

export const IconButton = styled.button`
  padding: 5px;
  border: none;
  background: var(--input-background);
  cursor: pointer;
  vertical-align: middle;
  border-radius: 0.25rem;
  outline: none;
  box-shadow: none;
`;