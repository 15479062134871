import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { cpfOrCnpjMask } from '../../../utils/masks';
import { API_URL } from '../../../services/api';
// import Loading from '../../../helpers/Loading';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { Container, ContainerForm, InputGrid, ChartGrid } from '../styles';
import moment from 'moment';
import { TitleButtons } from '../../TitleButtons';
import { RedirectTo } from '../../../utils/redirectTo';
import { Redirect } from 'react-router-dom';
import { TableCell, TableFooter, TableRow } from '@material-ui/core';
import { toDecimal, toNumber } from '../../../helpers/toCurrency';
import { Column } from 'rbx';

export function FinancialAppPdv() {
  const [financialAppPdvData, setFinancialAppPdvData] = useState({});
  const [totalCadSold, setTotalCadSold] = useState({});
  const [transactionTypes, setTransactionTypes] = useState({});
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [userType, setUserType] = useState('');
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState('')

  const fetchFinancialAppPdv = async (e) => {
    e.preventDefault();
    setLoading(true);

    let body = {};

    if (userType !== '') body = { userType }

    const res = await fetch(
      `${API_URL}/adm/financial-history?startDate=${startDate}&endDate=${endDate}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify(body),
      },
    );

    const response = await res.json();
    const data = response.data;

    if (response.statusCode !== 200) {
      toast.error(response.message);
      setLoading(false);
      return;
    }

    if (response.data === null) {
      toast.error(
        'Não foi localizado nenhum registro com os dados informados.',
      );
      setLoading(false);
      return;
    }

    setFinancialAppPdvData(data?.indexFinancial);
    setTotalCadSold(data?.totalCadSold);
    setTransactionTypes(data?.transactionTypes);
    setLoading(false);
  };

  if (redirectTo)
    return <Redirect to={{ pathname: redirectTo }} />

  const theme = createMuiTheme({
    typography: {
      fontFamily: `"Poppins", "Roboto", "Arial", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    // overrides: {
    //   MUIDataTableToolbar: { root: { display: 'none' } },
    // },
  });

  theme.shadows = [];

  const columns = [
    {
      name: 'userType',
      label: 'Usuário',
      options: { filter: true, sort: false, display: true },
    },
    {
      name: 'cpfCnpj',
      label: 'Cpf ou Cnpj',
      options: { filter: false, sort: false, display: true },
    },
    {
      name: 'dateAndHour',
      label: 'Data e hora',
      options: { filter: false, sort: true, display: true },
    },
    {
      name: 'cadQuantity',
      label: 'CAD',
      options: { filter: true, sort: true, display: true },
    },
    {
      name: 'value',
      label: 'Valor',
      options: { filter: true, sort: true, display: true },
    },
    {
      name: 'transactionType',
      label: 'Transação',
      options: { filter: true, sort: true, display: true },
    },
  ];

  const data = [];
  for (var i = 0; i < financialAppPdvData.length; i++) {
    const userType = financialAppPdvData[i].userType?.toUpperCase()
    data.push({
      userType: userType==='USER' ? 'APP' : userType,
      cpfCnpj: cpfOrCnpjMask(financialAppPdvData[i].cpfCnpj),
      dateAndHour: financialAppPdvData[i].dateAndHour,
      cadQuantity: financialAppPdvData[i].cadQuantity,
      value: toDecimal(financialAppPdvData[i].value),
      transactionType: financialAppPdvData[i].transactionType,
    })
  }

  const options = {
    responsive: 'standard',
    draggableColumns: {
      enabled: true,
    },
    selectableRows: 'none',
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50],
    print: 'false',
    filter: 'false',
    download: 'true',
    onDownload: (buildHead, buildBody, columns, data) => {
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    },
    downloadOptions: {
      filename: 'FinanceiroAppPdv.csv',
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: false,
      },
    },
    textLabels: {
      body: {
        noMatch: 'Nenhum registro encontrado.',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'resultado(s) de', // 1-10 of 30
      },
      toolbar: {
        search: 'Pesquisar',
        viewColumns: 'Selecionar Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        title: 'FILTROS',
        reset: 'limpar',
        all: 'Todos',
      },
      viewColumns: {
        title: 'Mostrar Colunas',
        titleAria: 'Mostrar/Esconder Colunas da Tabela',
      },
    },
    customToolbarSelect: () => {},
    expandableRows: false,
    expandableRowsOnClick: false,
    customTableBodyFooterRender: (opts) => {
      // console.dir(opts)
      const cadQuantity = opts.data.reduce((t, item) => (t + item.data[3]), 0)
      const value = opts.data.reduce((t, item) => (t + Number(item.data[4].replace('.','').replace(',','.'))), 0)

      if (opts.count === 0) return null

      return (
        <TableFooter>
          <TableRow className='MuiTableRow-root MUIDataTableHeadRow-root-117 MuiTableRow-head'>
            {opts.selectableRows !== 'none' ? <TableCell /> : null}
            {opts.columns.map((col, index) => {
              if (col.display === 'true') {
                if (col.name === 'userType') {
                  return (
                    <TableCell key={index} className='MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-212 MUIDataTableHeadCell-fixedHeader-213' style={{ fontSize: '0.875rem' }}>
                      <div>Total</div>
                    </TableCell>
                  )
                } else if (col.name === 'cadQuantity') {
                  return (
                    <TableCell key={index} className='MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-212 MUIDataTableHeadCell-fixedHeader-213' style={{ fontSize: '0.875rem' }}>
                      <div>{toNumber(cadQuantity)}</div>
                    </TableCell>
                  )
                } else if (col.name === 'value') {
                  return (
                    <TableCell key={index} className='MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-212 MUIDataTableHeadCell-fixedHeader-213' style={{ fontSize: '0.875rem' }}>
                      <div>{toDecimal(value)}</div>
                    </TableCell>
                  )
                } else {
                  return <TableCell key={index} />
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      )
    }
  };

  // if (loading) return <Loading />;
  return financialAppPdvData.length === undefined ? (
    <ContainerForm onSubmit={fetchFinancialAppPdv} className="animeLeft">
      <TitleButtons
        handleBtnBackClick={ () => setRedirectTo(RedirectTo.dashboard) }
      >
        <h3>Financeiro APP e PDV</h3>
      </TitleButtons>

      <strong>Preencha os campos abaixo</strong>

      <InputGrid>
        <Column.Group className='mb-0'>
          <Column>
            <label htmlFor="startDate">Data de início</label>
            <input
              placeholder="Data de início"
              type="date"
              value={startDate}
              onChange={(event) => setStartDate(event.target.value)}
              required
            />
          </Column>
          <Column>
            <label htmlFor="endDate">Data de fim</label>
            <input
              placeholder="Data de fim"
              type="date"
              value={endDate}
              onChange={(event) => setEndDate(event.target.value)}
              required
            />
          </Column>
        </Column.Group>

        <div>
          <label htmlFor="userType">Tipo de usuário</label>
          <select
            type="text"
            value={userType}
            onChange={(event) => setUserType(event.target.value)}
          >
            <option value="" defaultValue>
              APP e PDV
            </option>
            <option value="user">APP</option>
            <option value="pdv">PDV</option>
          </select>
        </div>

        {loading ? (
          <button type="submit" disabled>
            Carregando...
          </button>
        ) : (
          <button type="submit">Visualizar dados</button>
        )}
      </InputGrid>
    </ContainerForm>
  ) : (
    <Container className="animeLeft">
      <TitleButtons
        handleBtnBackClick={ () => setFinancialAppPdvData({}) }
      >
        <h3>Financeiro APP e PDV</h3>
      </TitleButtons>

      <strong>{`Período: ${moment(startDate).format('DD/MM/YYYY')} a ${moment(endDate).format('DD/MM/YYYY')}`}</strong>

      <ChartGrid>
        <div>
          <h6>
            Perfil de Usuário
          </h6>
          <ResponsiveContainer width="100%" aspect={2}>
            <BarChart
              width={100}
              height={300}
              data={totalCadSold}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="nome" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="CAD" fill="#33CC95" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div>
          <h6>Forma de pagamento</h6>
          <ResponsiveContainer width="100%" aspect={2}>
            <BarChart
              width={500}
              height={300}
              data={transactionTypes}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="nome" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="Valor" fill="#5D5FEF" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </ChartGrid>
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          title='Registros de compras'
          data={data}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </Container>
  );
}
