import { useEffect, useState } from 'react'
import { Box } from 'rbx'
import { BoxHeader } from '../../shared/BoxHeader'
import Controls from '../../shared/Controls/Controls'
import { toast } from 'react-toastify'
import { DomainBlacklistService } from '../../../services/api-zad'

export const DomainBlacklistForm = ({ itemSelected={}, closeModal, refreshList }) => {
  const [id, setId] = useState('')
  const [domain, setDomain] = useState('')

  const [stateButton, setStateButton] = useState('')

  // console.log('DomainBlacklistForm', 'Rendered')

  useEffect(() => {
    if (itemSelected.id) {
      setId(itemSelected.id)
      setDomain(itemSelected.domain)
    } else {
      setId('')
      setDomain('')
    }
  },[itemSelected])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setStateButton('loading')
    try {
      const response = id
        ? await DomainBlacklistService.update(id, { domain })
        : await DomainBlacklistService.create({ domain })
      toast.success(response.data.message)
      refreshList()
      closeModal()
    } catch (e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setStateButton('')
  }

  return (
    <Box>
      <BoxHeader title={id ? 'Editar Domínio: '+ itemSelected.domain : 'Novo Domínio'} handleClose={closeModal} />
      <form onSubmit={handleSubmit}>
        <Controls.TextField
          label="Domínio"
          name="domain"
          value={domain}
          onChange={e => setDomain(e.target.value)}
          placeholder="Ex: google.com"
          autoFocus
          required
        />
        <hr className='hr' />
        <Controls.ActionButtons
          linkName="Fechar"
          handleClickBack={closeModal}
          buttonState={stateButton}
          buttonName={id ? 'Atualizar' : 'Cadastrar'}
        />
      </form>
    </Box>
  )
}
