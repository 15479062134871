export const cpfValidate = (value) => {
  if (!value) return [true, '']

  let cpf = value.toString().replace( /\D/g, "" );
  let cpfInvalido = [
      "00000000000","11111111111","22222222222","33333333333","44444444444",
      "55555555555","66666666666","77777777777","88888888888","99999999999"
  ];
  let result = true;
  if (cpfInvalido.indexOf(cpf) > -1) {
      result = false;
  } else {
    // calcula primeiro digito
    let a = [], b = 0, c = 11, x = 0;
    for (let i=0; i<11; i++){
        a[i] = cpf.charAt(i);
        if (i < 9) { b += (a[i] * --c) }
    }
    a[9] = ((x = b % 11) < 2) ? 0 : 11-x;
    // calcula segundo digito
    b = 0;
    c = 11;
    for (let y=0; y<10; y++) { 
      b += (a[y] * c--); 
    }
    a[10] = ((x = b % 11) < 2) ? 0 : 11-x;
    // compara digitos calculados e defini o resultado
    result = (parseInt(cpf.charAt(9)) === a[9] && parseInt(cpf.charAt(10)) === a[10]);
  }
  return [result, (result) ? 'CPF Válido.' : 'CPF Inválido.'];
}

export const cnpjValidate = (value) => {
    if (!value) return [true, '']

    let valida = [6,5,4,3,2,9,8,7,6,5,4,3,2];
    let dig1 = 0, dig2 = 0;

    let cnpj = value.toString().replace( /\D/g, "" );
    let digito = parseInt(cnpj.substring(cnpj.length, cnpj.length-2));

    for(let i = 0; i<valida.length; i++){
        dig1 += (i>0) ? parseInt(cnpj.charAt(i-1)) * valida[i] : 0;
        dig2 += parseInt(cnpj.charAt(i)) * valida[i];
    }
    dig1 = ((dig1%11)<2) ? 0 : 11-(dig1%11);
    dig2 = ((dig2%11)<2) ? 0 : 11-(dig2%11);

    let result = ((dig1*10)+dig2 === digito);
    return [result, (result) ? 'CNPJ Válido.' : 'CNPJ Inválido.'];
}

export const cpfOrCnpjValidate = (value) => (
  (value.replace( /\D/g,'').length > 11) ? cnpjValidate(value) : cpfValidate(value)
)

