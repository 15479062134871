import Modal from 'react-modal'
import { useState } from 'react'
import { toast } from 'react-toastify'
import '../styles.css'
import { DriverService } from '../../../../../services/api-zad'
import { ModalHeader } from '../../../../shared/ModalHeader'
import { PasswordInput } from '../../../../shared/PasswordInput'

export const DriverForm = ({ isOpen, onRequestClose, driversRefresh }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (password !== passwordConfirmation) {
      toast.error('As senhas não coincidem')
      return
    }

    setLoading(true)
    try {
      const response = await DriverService.create({ name, email, password })
      toast.success(response.data.message)
      driversRefresh()
      setTimeout(closeModal, 3000)
    } catch (e) {
      if (e.response)
        toast.error(e.response.data.message)
      else
        toast.error(e.message)
    }
    setLoading(false)
  }

  const closeModal = () => {
    setName('')
    setEmail('')
    setPassword('')
    setPasswordConfirmation('')
    onRequestClose()
  }

  return (
    <Modal
    isOpen={isOpen}
    onRequestClose={closeModal}
    overlayClassName="react-modal-overlay z-index-5"
    className="react-modal-content"
  >
    <ModalHeader title='Novo Motorista' handleClose={onRequestClose} />

    <form className='driver-form' onSubmit={handleSubmit}>
      <input
        placeholder="Nome"
        maxLength={60}
        value={name}
        autoFocus
        onChange={(event) => setName(event.target.value)}
        required
      />
      <input
        placeholder="E-mail"
        type="email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <div className='columns is-mobile my-1'>
        <div className='column pb-1'>
          <PasswordInput
            placeholder="Senha"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            minLength={6}
            required
          />
        </div>
        <div className='column pb-0'>
          <PasswordInput
            placeholder="Confirme a Senha"
            value={passwordConfirmation}
            onChange={(event) => setPasswordConfirmation(event.target.value)}
            minLength={6}
            required
          />
        </div>
      </div>

      <hr className='hr'></hr>

      {loading ? (
        <button className="btn" type="submit" disabled>
          Salvando
        </button>
      ) : (
        <button className="btn" type="submit">Salvar</button>
      )}
    </form>
  </Modal>
  )
}
