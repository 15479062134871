import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './helpers/ProtectedRoute';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import IrregularVehiclesPage from './pages/IrregularVehiclesPage';
// import NotFoundPage from './pages/NotFoundPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ActivatePage from './pages/ActivatePage';
import ResendCodePage from './pages/ResendCodePage';
import ParkingPage from './pages/ParkingPage';
import MonitorsPage from './pages/MonitorsPage';
import MonitorsNowPage from './pages/MonitorsNowPage';
import FinancialAppPdvPage from './pages/FinancialAppPdvPage';
import FinancialMonitorPage from './pages/FinancialMonitorPage';

import AgentIrregularVehiclesPage from './pages/AgentPage/IrregularVehicles';
import AgentConfirmedIrregularityPage from './pages/AgentPage/ConfirmedIrregularity';
import AgentFinedIrregularityPage from './pages/AgentPage/FinedIrregularity'

import { UserDatasheetPage } from './pages/UserPage/datasheet/index';
import { UsearSearchPage } from './pages/UserPage/search';
import { RedirectTo } from './utils/redirectTo';
import { UserNewPage } from './pages/UserPage/new';
import { UserEditPage } from './pages/UserPage/edit';
import { HolidayPage } from './pages/HolidayPage';
import { ContactUsPage } from './pages/ContactUsPage';
import { VacancyPage } from './pages/VacancyPage';
import { AreaPage } from './pages/AreaPage';
import { VehicleHistoricPage } from './pages/VehicleHistoricPage';
import { AccountabilityPage } from './pages/AccountabilityPage';
import { DataExtractionPage } from './pages/DataExtractionPage';
import { TimeRulePage } from './pages/TimeRulePage';
import { RegionRulePage } from './pages/RegionRulePage';
import { DomainBlacklistPage } from './pages/DomainBlacklistPage';
import { UserRemovePage } from './pages/UserPage/remove';
import ServiceTermsPage from './pages/ServiceTermsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import { HomePage } from './pages/HomePage';
import Logout from './components/Forms/Logout';
import RegisterPage from './pages/RegisterPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ProtectedRouteAdm from './helpers/ProtectedRouteAdm';
import ProtectedRouteNotFound from './helpers/ProtectedRouteNotFound';
import { AdvertisingPage } from './pages/AdvertisingPage';

const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path={RedirectTo.home} component={HomePage} />
          <Route exact path={RedirectTo.login} component={LoginPage} />
          <Route exact path={RedirectTo.logout} component={Logout} />
          <Route exact path={RedirectTo.register} component={RegisterPage} />
          <Route exact path={RedirectTo.activate} component={ActivatePage} />
          <Route exact path={RedirectTo.resendCode} component={ResendCodePage} />
          <Route exact path={RedirectTo.forgotPassword} component={ForgotPasswordPage} />
          <Route exact path={RedirectTo.resetPassword} component={ResetPasswordPage} />
          <Route exact path={RedirectTo.serviceTerms} component={ServiceTermsPage} />
          <Route exact path={RedirectTo.privacyPolicy} component={PrivacyPolicyPage} />

          <ProtectedRoute exact path={RedirectTo.dashboard} component={DashboardPage} />

          <ProtectedRouteAdm exact path={RedirectTo.parking} component={ParkingPage} />
          <ProtectedRouteAdm exact path={RedirectTo.monitors} component={MonitorsPage} />
          <ProtectedRouteAdm exact path="/financialAppPdv" component={FinancialAppPdvPage} />
          <ProtectedRouteAdm exact path="/financialMonitor" component={FinancialMonitorPage} />
          <ProtectedRouteAdm exact path="/monitorsNow" component={MonitorsNowPage} />
          <ProtectedRouteAdm exact path="/irregularVehicles" component={IrregularVehiclesPage} />

          {/*Rotas do Usuário Agent / Fiscal*/}
          <ProtectedRouteAdm exact path="/agent/irregularVehicles/:area" component={AgentIrregularVehiclesPage} />
          <ProtectedRouteAdm exact path="/agent/confirmedIrregularity" component={AgentConfirmedIrregularityPage} />
          <ProtectedRouteAdm exact path="/agent/finedIrregularity" component={AgentFinedIrregularityPage} />

          {/* Rotas do Usuário Suporte */}
          <ProtectedRouteAdm exact path='/user/new' component={UserNewPage} />
          <ProtectedRouteAdm exact path='/user/new/:cpf' component={UserNewPage} />
          <ProtectedRouteAdm exact path='/user/edit' component={UserEditPage} />
          <ProtectedRouteAdm exact path='/user/search' component={UsearSearchPage} />
          <ProtectedRouteAdm exact path='/user/search/:cpf' component={UsearSearchPage} />
          <ProtectedRouteAdm exact path='/user/datasheet' component={UserDatasheetPage} />
          <ProtectedRouteAdm exact path='/user/remove' component={UserRemovePage} />
          <ProtectedRouteAdm exact path='/holidays' component={HolidayPage} />
          <ProtectedRouteAdm exact path='/contactUs' component={ContactUsPage} />
          <ProtectedRouteAdm exact path='/vacancy' component={VacancyPage} />
          <ProtectedRouteAdm exact path='/areas' component={AreaPage} />
          <ProtectedRouteAdm exact path='/vehicleHistoric' component={VehicleHistoricPage} />
          <ProtectedRouteAdm exact path='/accountability' component={AccountabilityPage} />
          <ProtectedRouteAdm exact path='/dataExtraction' component={DataExtractionPage} />
          <ProtectedRouteAdm exact path='/timeRules' component={TimeRulePage} />
          <ProtectedRouteAdm exact path='/regionRules' component={RegionRulePage} />
          <ProtectedRouteAdm exact path='/domainBlacklist' component={DomainBlacklistPage} />
          <ProtectedRouteAdm exact path='/advertising' component={AdvertisingPage} />

          <ProtectedRouteNotFound path={RedirectTo.notFound} component={DashboardPage} />
        </Switch>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
