import { useState } from 'react'
import { Box, Button, Icon, Modal, Progress, Table } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import moment from 'moment'
import { useFetch } from '../../../hooks/useFetch'
import { API_URL } from '../../../services/api'
import { PaginationCustom } from '../../shared/PaginationCustom'
import { RegionRuleForm } from '../form'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

const configModal = { active: false, page: '' }

export const RegionRuleList = () => {
  const [pageOfItems, setPageOfItems] = useState([])
  const [modal, setModal] = useState(configModal)
  
  const { 
    response: regionRuleResponse,
    load: regionRuleLoad,
    refresh: regionRulerefresh
  } = useFetch(`${API_URL}/region-rules/index`)

  const items = regionRuleResponse?.data || []

  // console.log('RegionRuleList', 'Rendered')

  const setPageForm = (item) => {
    setModal({
      active: true,
      page: <RegionRuleForm itemSelected={item||{}} closeModal={closeModal} refreshList={regionRulerefresh} />
    })
  }

  const closeModal = () => setModal(configModal)

  const onChangePage = (ppageOfItems) => setPageOfItems(ppageOfItems)

  const tableHeader = () => (
    <Table.Row>
      <Table.Heading>Descrição</Table.Heading>
      <Table.Heading textAlign='centered'>Tempo</Table.Heading>
      <Table.Heading textAlign='centered'>Tolerância</Table.Heading>
      <Table.Heading textAlign='centered'>Com Renovação?</Table.Heading>
      <Table.Heading textAlign='centered'>Visível ao usuário?</Table.Heading>
      <Table.Heading textAlign='centered'>Ações</Table.Heading>
    </Table.Row>
  )

  const tableBody = () => {
    return (pageOfItems.length === 0) ? (
      <Table.Row>
        <Table.Cell colSpan="9">
          { (regionRuleLoad) ? <Progress size='small' color='custom-purple' /> : 'Nenhuma regra de estacionamento foi encontrada.' }
        </Table.Cell>
      </Table.Row>
    ) : (
      pageOfItems.map((item) => (
        <Table.Row key={item.id}>
          <Table.Cell>{item.name}</Table.Cell>
          <Table.Cell textAlign='centered'>{moment.utc(item.baseTime*60*1000).format('HH:mm [h]')}</Table.Cell>
          <Table.Cell textAlign='centered'>{moment.utc(item.tolerance*60*1000).format('HH:mm [h]')}</Table.Cell>
          <Table.Cell textAlign='centered'>
            {
              item.renewable
                ? <FontAwesomeIcon icon={faCheck} color={'green'} />
                : <FontAwesomeIcon icon={faTimes} color={'red'} />
            }
          </Table.Cell>
          <Table.Cell textAlign='centered'>
            {
              item.visible
                ? <FontAwesomeIcon icon={faCheck} color={'green'} />
                : <FontAwesomeIcon icon={faTimes} color={'red'} />
            }
          </Table.Cell>
          <Table.Cell textAlign='centered'>
            <Icon size={'medium'} className='is-clickable ml-2' title='Editar' onClick={() => setPageForm(item)}>
              <FontAwesomeIcon icon={faEdit} />
            </Icon>
          </Table.Cell>
        </Table.Row>
      ))
    )
  }

  return (
    <>
      <Box>
        <div className='is-flex is-justify-content-end'>
          <Button type='button' size="small" color="custom-purple" rounded onClick={setPageForm}>
            Nova
          </Button>
        </div>
        <hr className='hr my-3' />
        <div className="table-container">
          <Table hoverable fullwidth>
            <Table.Head>
              { tableHeader() }
            </Table.Head>
            <Table.Body>
              { tableBody() }
            </Table.Body>
          </Table>
        </div>
        <PaginationCustom items={items} onChangePage={onChangePage} />
      </Box>
      <Modal closeOnBlur={true} closeOnEsc={true} active={modal.active} onClose={closeModal}>
        <Modal.Background onClick={closeModal} />
        <Modal.Content>
          { modal.page }
        </Modal.Content>
      </Modal>
    </>
  )
}
