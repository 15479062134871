import { Layout } from "../../components/Layout";
import { DashboardAdm } from "../../components/Adm/Dashboard";
import { DashboardAgent } from "../../components/Agent/Dashboard";
import { DashboardPrefecture } from "../../components/Prefecture/Dashboard";
import { DashboardDiretoria } from "../../components/Diretoria/Dashboard";
import { DashboardMonitor } from "../../components/Monitor/Dashboard";

import { Header } from '../../components/AppPdv/Header';
import { Container } from './styles';
import { DashboardAppPdv } from "../../components/AppPdv/Dashboard";

export default function DashboardPage() {
  const profile = sessionStorage.getItem("profile");

  if (["user","pdv","frotista","driver"].includes(profile)) {
    return (
      <>
        <Header />
        <Container>
          <DashboardAppPdv />
        </Container>
      </>
    )
  }

  return (
    <Layout>
      { ["adm","support"].includes(profile) && <DashboardAdm /> }
      { profile === "monitor" && <DashboardMonitor /> }
      { profile === "agent" && <DashboardAgent /> }
      { profile === "prefecture" && <DashboardPrefecture /> }
      { profile === "diretoria" && <DashboardDiretoria /> }
    </Layout>
  );
}
