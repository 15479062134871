import { useState } from 'react';
import { Control, Label, Field, Input, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import './styles.scss'

const visible = {
  on:  {visible: true, type: "text", icon: faEye},
  off: {visible: false, type: "password", icon: faEyeSlash}
}

export const PasswordField = ({ name, label, value, onChange, ...other }) => {
  const [status, setStatus] = useState(visible.off);

  const handleClickEye = () => {
    setStatus(status.visible ? visible.off : visible.on)
  }

  return (
    <>
      {(label) && <Label>{label}</Label>}
      <Field>
        <Control iconRight>
          <Input 
            type={status.type}
            name={name}
            placeholder='●●●●●●'
            pattern=".{6,12}"
            title="Informe de 6 a 12 caracteres"
            maxLength="12"
            value={value}
            onChange={onChange}
            {...other}
          />
          <Icon 
            className={status.type==='password' ? 'close-eye' : ''}
            color="black"
            align="right" 
            onClick={handleClickEye} 
          >
            <FontAwesomeIcon icon={status.icon} />
          </Icon>
        </Control>
      </Field>
    </>
  )
}
