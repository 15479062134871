import { FinancialMonitor } from "../../components/Adm/FinancialMonitor";
import { Layout } from "../../components/Layout";

export default function FinancialMonitorPage() {
  return (
    <Layout>
      <FinancialMonitor />
    </Layout>
  );
}
