import { HeaderHome } from "../../components/AppPdv/HeaderHome"
import { env } from "../../env"
import { Container, Content } from "./styles"

export default function PrivacyPolicyPage() {
  return (
    <Container>
      <HeaderHome />
      <Content>
        <h1>Política de Privacidade</h1>

        <p>
          Obrigado por usar o Zona Azul Digital - ZAD DIGITAL, ou simplesmente 
          ZAD DIGITAL. Nossa missão é ajudar você a utilizar o sistema rotativo
          digital Zona Azul Digital - ZAD DIGITAL de uma maneira mais simples e 
          totalmente digital.
        </p>
        <p>
          Este documento foi elaborado para ajudar você a entender quais
          informações coletamos, como as usamos e quais escolhas você tem sobre
          elas. Alguns conceitos aqui utilizados são técnicos, mas tentamos
          explicar tudo da maneira mais simples e clara possível. Estamos à
          disposição para responder suas dúvidas e comentários sobre esta
          política.
        </p>
        <p>
          <b>
            <i>NÓS COLETAMOS INFORMAÇÕES DE ALGUMAS MANEIRAS DIFERENTES:</i>
          </b>
        </p>
        <ol>
          <li>
            <b>QUANDO VOCÊ NOS FORNECE OU NOS DÁ PERMISSÃO PARA OBTÊ-LA:</b>

            <p>
              Quando se inscreve no Zona Azul Digital - ZAD DIGITAL ou o utiliza, você nos
              fornece certas informações voluntariamente. Isso inclui: nome,
              endereço de e-mail, número de telefone, placa de veículo, cartão
              de crédito, comentários e qualquer outra informação que você nos
              der. Você também pode optar por compartilhar dados de localização
              conosco. Se você comprar Cartão Azul Digital (CAD) no Zona Azul Digital -
              ZAD DIGITAL, coletaremos informações de pagamento, informações de contato
              (endereço e número de telefone) e detalhes sobre o que você
              comprou.
            </p>

            <p>
              Obtemos apenas informações técnicas, quando você usa o Zona Azul Digital -
              ZAD DIGITAL não armazenamos dados sensíveis, ou seja, informações sobre
              origem racial ou ética ou convicções religiosas. Em consonância
              com o que dispõe a LEI Nº 13.709, DE 14 DE AGOSTO DE 2018.
            </p>
          </li>
          <li>
            <b>
              TAMBÉM OBTEMOS INFORMAÇÕES TÉCNICAS QUANDO VOCÊ USA O ZONA AZUL
            </b>
            <p>
              Sempre que você usa qualquer site, aplicativo móvel ou outro
              serviço da Internet, determinadas informações são criadas e
              registradas automaticamente. O mesmo acontece quando você usa o
              Zona Azul Digital - ZAD DIGITAL . Veja alguns tipos de informações que coletamos:
            </p>

            <p>
              Informações sobre o dispositivo no qual você está usando o Zona
              Azul Digital - ZAD DIGITAL , incluindo o tipo de dispositivo, o sistema
              operacional, configurações, identificadores exclusivos do
              dispositivo e dados de falhas que nos ajudam a entender quando
              algo não funciona. A coleta de algumas dessas informações ou de
              todas elas muitas vezes depende do tipo de dispositivo que você
              está usando e das configurações relacionadas. Por exemplo,
              diferentes tipos de informações estão disponíveis dependendo de
              você estar usando um smartphone com sistema operacional iOS ou
              Android. Para saber mais sobre quais informações seu dispositivo
              disponibiliza para nós, verifique também as políticas do
              fabricante do dispositivo ou do provedor do software.
            </p>
          </li>

          <li>
            <b>O QUE FAZEMOS COM AS INFORMAÇÕES QUE COLETAMOS.</b>
          </li>
          <p>
            Usa as informações que coletamos para fornecer um melhor ser para
            você. Nós nos comprometemos a mostrar conteúdo que seja relevante,
            interessante e pessoal para você. Para fazer isso, é necessário que
            usemos suas informações para:
          </p>

          <p>
            Identificá-lo quando você usar o Zona Azul Digital - ZAD DIGITAL e processar suas
            transações;
          </p>
          <p>
            Recomendar locais de interesses que você pode gostar com base em sua
            atividade no Zona Azul Digital - ZAD DIGITAL ;
          </p>
          <p>Responder suas dúvidas ou comentários.</p>

          <p>
            Além disso, as informações obtidas são fundamentais para a natureza
            do serviço que prestamos. Também temos um interesse legítimo em
            melhorar o Zona Azul Digital - ZAD DIGITAL, manter nosso relacionamento com você e
            proteger os usuários. Ambos nos beneficiamos quando usamos suas
            informações para:
          </p>

          <p>
            Realizar análises sobre quem está usando o Zona Azul Digital - ZAD DIGITAL e o que
            essas pessoas estão fazendo;
          </p>
          <p>Melhorar o Zona Azul Digital - ZAD DIGITAL e oferecer novos recursos;</p>
          <p>
            Enviar atualizações e novidades por e-mail ou notificações,
            dependendo das suas configurações. Você pode decidir parar de
            receber essas notificações atualizando suas configurações.
          </p>
          <p>
            Trabalhar com as autoridades e manter o Zona Azul Digital - ZAD DIGITAL
            seguro. Podemos receber solicitações de informações de contas por
            parte de autoridades, como a polícia ou o poder judiciário.
          </p>

          <p>
            Temos um interesse legítimo em veicular anúncios que sejam
            relevantes, interessantes e pessoais para você, a fim de gerar
            receita. Para promover esses interesses, usamos as informações que
            coletamos para:
          </p>

          <p>
            Decidir quais anúncios mostrar para você. Por exemplo, se você
            sempre estaciona em locais próximo a restaurantes, poderemos lhe
            mostrar anúncios de restaurantes. Personalizamos o conteúdo do
            anúncio que mostramos, identificando seus interesses com base em
            suas atividades, bem como usando as informações que recebemos de
            parceiros de anúncio ou de terceiros.
          </p>
          <p>
            Dizer aos nossos parceiros de anúncio os tipos de produtos em que
            você pode estar interessado. Por exemplo, se você visitar um local
            destinado a festas, poderemos informar a um estabelecimento de
            entretenimento que você está mais interessado em festas do que,
            digamos, em jantares. Isso nos ajuda e aos nossos parceiros de
            anúncio a garantir que o conteúdo e os anúncios exibidos sejam
            relevantes para você.
          </p>

          <li>
            <b>COMO TRANSFERIR SUAS INFORMAÇÕES</b>
          </li>
          <p>
            Ao usar nossos produtos e serviços, você nos autoriza a transferir e
            armazenar suas informações fora do seu país de origem, para os fins
            descritos nesta política. As proteções à privacidade e os direitos
            das autoridades de acessar suas informações pessoais nesses países
            poderão não ser equivalentes àqueles aplicados em seu país de
            origem.
          </p>

          <li>
            <b>ESCOLHAS QUE VOCÊ TEM SOBRE SUAS INFORMAÇÕES</b>
          </li>

          <p>
            Nosso objetivo é oferecer a você escolhas simples e significativas
            sobre suas informações. Caso tenha uma conta do Zona Azul Digital - ZAD DIGITAL ,
            muitas das escolhas que você tem estão incorporadas diretamente no
            Zona Azul Digital - ZAD DIGITAL ou nas suas configurações. Você pode, por exemplo:
          </p>
          <p>
            Editar informações no seu perfil a qualquer momento. No caso de CPF
            ou CNPJ, é necessário contatar a Distribuidora;
          </p>
          <li>
            <b>POR QUANTO TEMPO MANTEMOS SUAS INFORMAÇÕES</b>
          </li>
          <p>
            Nós mantemos suas informações somente no período em que esteja
            cadastrado, ativo ou ainda que inativo, na plataforma, isso para
            fornecer o Zona Azul Digital - ZAD DIGITAL para você e cumprir as finalidades
            descritas nesta política. Encerrado o cadastro e não havendo
            necessidade de mantermos suas informações, para cumprir nossas
            obrigações legais ou regulamentares, nós as removeremos de nossos
            sistemas ou as despersonalizaremos para que não possamos identificar
            você.
          </p>
          <li>
            <b>NOSSA POLÍTICA SOBRE INFORMAÇÕES DE CRIANÇAS</b>
          </li>
          <p>
            Não há restrição de idade para o uso do Zona Azul Digital - ZAD DIGITAL .
            Entretanto, é necessário ter um CPF/CNPJ para fazer o cadastro.
          </p>
          <li>
            <b>SUAS OPÇÕES</b>
          </li>
          <p>
            Você tem opções em relação às informações que temos sobre você
            descritas abaixo. Para exercer essas opções, entre em contato
            conosco:
          </p>
          <p>
            Acessar as informações que mantemos sobre você. Normalmente,
            compartilharemos essas informações com você dentro de 30 dias após
            você nos pedir;
          </p>
          <p>
            Ter suas informações corrigidas ou excluídas parcialmente. Você pode
            atualizar suas informações nas suas configurações. Se você tiver
            problemas para atualizar as informações ou quiser que nós as
            excluamos, entre em contato conosco;
          </p>
          <p>
            Reclamar para uma entidade reguladora. Se você acha que não
            cumprimos as leis de proteção de dados, você tem o direito de
            apresentar uma reclamação à sua autoridade local de supervisão.
          </p>

          <li>
            <b>COMO FAZEMOS ALTERAÇÕES NESSAS POLÍTICAS</b>
          </li>
          <p>
            Podemos alterar esta política de tempos em tempos e, se o fizermos,
            publicaremos as alterações nesta página. Se continuar a usar o Zona
            Azul Digital - ZAD DIGITAL depois que essas alterações entrarem em vigor, você
            estará concordando com a nova política. Caso as mudanças sejam
            significativas, poderemos ou não fornecer um aviso mais destacado ou
            obter sua permissão, conforme exigido por lei.
          </p>
        </ol>
        <p>
          <b>FALE CONOSCO</b>
        </p>
        <p>
          A melhor maneira de nos contatar é por meio do e-mail da Central de
          Ajuda: <b>{ env.SUPPORT_EMAIL }</b>. Se você reside dentro ou
          fora do Brasil, a {env.SERVICE_TERMS_COMPANY}, inscrita
          no CNPJ {env.SERVICE_TERMS_CNPJ}, é responsável pelas suas informações. Você
          também pode entrar em contato através do endereço {env.SERVICE_TERMS_ADDRESS}.
        </p>
        {/* <br />
        <p className="em-vigor">
          <b>
            <i>Em vigor a partir de 10 de agosto de 2022.</i>
          </b>
        </p> */}
      </Content>
    </Container>
  );
}
