import { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { IrregularVehiclesList } from "../../../components/Agent/IrregularVehicles/list";
import { RedirectTo } from "../../../utils/redirectTo";
import { Layout } from "../../../components/Layout";

export default function IrregularVehiclesPage() {
  const { area } = useParams();

  const [redirectToDashboard, setRedirectToDashboard] = useState(false);

  if (redirectToDashboard)
    return <Redirect to={{ pathname: RedirectTo.dashboard }} />;

  return (
    <Layout>
      <IrregularVehiclesList
        area={area}
        handleClickBtnBack={() => setRedirectToDashboard(true)}
      />
    </Layout>
  );
}
