import { Images } from '../utils/images';
import styled from "styled-components";

export const Container = styled.section`
  min-height: 100vh;
  background: url(${Images.bgPhoto}) no-repeat center;
  background-size: cover;
`;

export const Cover = styled.div`
  max-width: 50vw;

  span {
    color: var(--green);
  }

  @media (max-width: 768px) {
    display: none;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    justify-content: center;
  }

  @media (max-width: 321px) {
    grid-template-columns: 1fr;
  }
`;

export const Content = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10rem;
  max-width: 1120px;
  min-height: 680px;
  margin: 0 auto;
  align-items: center;
  justify-items: center;
  padding: 4rem 1rem;
  color: #fff;
  flex-grow: 1;

  h1 {
    font-size: 4rem;
    color: var(--shape);
  }

  p {
    font-size: 2.5rem;
    margin-top: 1rem;
  }

  span a {
    color: var(--text-title);
  }

  @media (max-width: 1023px) {
    gap: 2rem;
  }

  @media (max-width: 1407px) {
    padding: 0rem 1rem;
    min-height: 484px;

    h1 {
      font-size: 3.5rem;
    }
  }

  @media (max-width: 1215px) {
    h1 {
      font-size: 3rem;
    }
    p {
      font-size: 2rem;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 1rem;
    // grid-template-columns: 1fr;
    // gap: 0rem;

    h1 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1.5rem;
      margin-top: 1rem;
    }
  }
`;
