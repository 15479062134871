import Modal from 'react-modal';
import { Container, InfoContainer } from './styles';
import { ModalHeader } from '../../../../../shared/ModalHeader';
import { useState } from 'react';
import { ApiZad } from '../../../../../../services/api-zad';
import { toast } from 'react-toastify';

export function DetailHistoricBuyModal({ isOpen, onRequestClose, detailsData }) {
  const [loading, setLoading] = useState(false);

  const emailUser = sessionStorage.getItem('email');

  const handleProof = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { res, response } = await ApiZad.post('/purchase-voucher/email', { email: emailUser, authenticateCode: detailsData.authenticateCode })

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
    }
    setLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay z-index-5"
      className="react-modal-content"
    >
      <Container onSubmit={handleProof}>
        <ModalHeader title='Comprovante de Compra' handleClose={onRequestClose} />

        <InfoContainer>
          <p>
            Data e hora
            <br />
            <span>{detailsData.date}</span>
          </p>
          <p>
            Forma de pagamento
            <br />
            <span>{detailsData.payment}</span>
          </p>
        </InfoContainer>

        <InfoContainer>
          <p>
            Quantidade de Cartões
            <br />
            <span>{detailsData.quantity}</span>
          </p>
          <p>
            Valor em reais
            <br />
            <span>{detailsData.value}</span>
          </p>
        </InfoContainer>

        <InfoContainer>
          <p>
            Código de autenticação
            <br />
            <span>{detailsData.authenticateCode}</span>
          </p>
          <p>
            Distribuidora
            <br />
            <span>{detailsData.distributorData?.corporateName}</span>
          </p>
        </InfoContainer>

        <hr className='hr'></hr>

        {loading ? (
          <button type="submit" disabled>
            Enviando
          </button>
        ) : (
          <button type="submit">
            Enviar para meu e-mail
          </button>
        )}
      </Container>
    </Modal>
  );
}
