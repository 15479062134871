import { Field, Label, Control, Input } from 'rbx'
import InputMask from 'react-input-mask'

const TextFieldMask = ({ children, name, label, mask, value, onChange, onBlur, ...other }) => {
  return (
    <>
      {(label) && <Label>{label}</Label>}
      <Field kind="addons">
        <Control expanded >
          <InputMask mask={mask} value={value} onChange={onChange} onBlur={onBlur} maskPlaceholder={null}>
            <Input 
              type={name}
              name={name}
              {...other}
            />
          </InputMask>
        </Control>
        {children}
      </Field>
    </>
  )

}

export default TextFieldMask
