import { faChevronRight, faCreditCard, faEllipsisV, faHistory, faSignOutAlt, faSync, faUsers } from "@fortawesome/free-solid-svg-icons"
import { faUser } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import './styles.css'
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { Dropdown, Icon } from "rbx"
import { UserModal } from "../../Modals/UserModal"
import { useContext, useState } from "react"
import { useFetch } from "../../../hooks/useFetch"
import { API_URL } from "../../../services/api"
import { celPhoneMask, cpfOrCnpjMask } from "../../../utils/masks"
import AuthContext from "../../../contexts/AuthContext"
import { HistoricModal } from "../Modals/HistoricModal"
import { CardModal } from "../Modals/CardModal"
import { env } from "../../../env"
import { useMedia } from '../../../hooks/useMedia';
import { DriverModal } from "../Modals/DriverModal"

const ItemMenu = ({ icon, title, onClick }) => {
  return (
    <div
      className='item-menu--group is-flex is-align-items-center is-justify-content-space-between is-clickable px-4 py-2'
      style={{ width:'100%' }}
      onClick={onClick}
    >
      <div className='item-menu'>
        <Icon className='px-3'>
          <FontAwesomeIcon icon={icon} />
        </Icon>
        <span>{ title }</span>
      </div>
      <Icon className='item-menu--chevron px-3'>
        <FontAwesomeIcon icon={faChevronRight} />
      </Icon>
    </div>
  )
}

const DropdownMore = ({ creditCardItemClick, driverItemClick, up, drivers=false, frotista=false }) => {
  const { logout } = useContext(AuthContext)

  return (
    <Dropdown align="right" className={`footer-menu--plus ${up ? 'is-up' : ''}`} style={{ width:'100%' }}>
      <Dropdown.Trigger style={{ width:'100%' }}>
        <ItemMenu icon={faEllipsisV} title={'Mais'} />
      </Dropdown.Trigger>
      <Dropdown.Menu>
        <Dropdown.Content>
          <Dropdown.Item onClick={() => window.location.reload()}>
            <FontAwesomeIcon icon={faSync} className="mr-3" /> Atualizar
          </Dropdown.Item>
          {
            !drivers &&
            <Dropdown.Item onClick={creditCardItemClick}>
              <FontAwesomeIcon icon={faCreditCard} className="mr-3" /> Meus Cartões
            </Dropdown.Item>
          }
          {
            frotista &&
            <Dropdown.Item onClick={driverItemClick}>
              <FontAwesomeIcon icon={faUsers} className="mr-3" /> Meus Motoristas
            </Dropdown.Item>
          }
          <Dropdown.Item onClick={logout}>
            <FontAwesomeIcon icon={faSignOutAlt} className="mr-3" style={{ color:'var(--red)' }}/> Sair
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export const FooterMenu = () => {
  const mobile = useMedia('(max-width: 768px)');
  const [modal, setModal] = useState({})

  const profile = sessionStorage.getItem('profile');
  const id = sessionStorage.getItem('id');
  const {
    response: userResponse
  } = useFetch(`${API_URL}/users/${id}`)

  const user = userResponse?.data || {}

  if (Object.keys(user).length) {
    user.cpf_cnpj = cpfOrCnpjMask(user.cpf_cnpj);
    user.phone = celPhoneMask(user.phone.replace('+55', ''))
  }

  const closeModal = () => setModal({})

  const openHistoricModal = () => {
    setModal({
      show: <HistoricModal
        isOpen={true}
        onRequestClose={closeModal}
      />
    })
  }

  const openUserModal = () => {
    setModal({
      show: <UserModal
        isOpen={true}
        onRequestClose={closeModal}
        userData={user}
      />
    })
  }

  const openCardModal = () => {
    setModal({
      show: <CardModal
        isOpen={true}
        onRequestClose={closeModal}
      />
    })
  }

  const openDriverModal = () => {
    setModal({
      show: <DriverModal
        isOpen={true}
        onRequestClose={closeModal}
      />
    })
  }

  return (
    <>
      { modal.show }

      <div className='footer-menu'>
        <ItemMenu icon={faUser} title={'Perfil'} onClick={openUserModal} />

        <a href={ `https://${mobile ? 'api' : 'web'}.whatsapp.com/send?phone=${env.WHATSAPP}` } target="_blank" rel="noreferrer" style={{ width:'100%' }}>
          <ItemMenu icon={faWhatsapp} title={'Contato'} />
        </a>

        <ItemMenu icon={faHistory} title={'Histórico'} onClick={openHistoricModal} />

        <DropdownMore
          creditCardItemClick={openCardModal}
          driverItemClick={openDriverModal}
          up={mobile}
          drivers={profile === 'driver'}
          frotista={profile === 'frotista'}
        />
      </div>
    </>
  )
}
