import { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { Images } from '../../../utils/images';
import { ModalHeader } from '../../shared/ModalHeader';
import { maskCpfCnpj, maskQuantityCads, unMask } from '../../../utils/masks';
import MaskedInput from 'react-text-mask';
import { API_URL } from '../../../services/api';
import {
  Container,
  IconButton,
  IconButtonContainer,
  RadioContainerPayment,
  RadioBox,
  InputsGrid,
} from './styles';

export function RechargeUserModal({ isOpen, onRequestClose }) {
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState('');
  const [userCads, setUserCads] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState('pix');
  const [loading, setLoading] = useState('');

  const handleRechargeUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      userId,
      quantity,
      paymentMethod,
    };

    const res = await fetch(`${API_URL}/adm/transactions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    // console.log(body);
    // console.log(response);

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setCpfCnpj('');
      setUserId(null);
      setUserName('');
      setUserCads('');
      setQuantity(1);
      setPaymentMethod('');
    }

    setLoading(false);
  };

  const findUserId = async (e) => {
    e.preventDefault();

    const res = await fetch(`${API_URL}/adm/users?cpfCnpj=${unMask(cpfCnpj)}`, {
      method: 'GET',
      cache: 'no-store',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token'),
      },
    });

    const response = await res.json();

    if (!res.ok) {
      toast.error(response?.message);
      setUserId(null);
      setUserName('');
      setUserCads('');
    } else {
      // toast.success(response?.message);
      setUserId(response?.data?.id);
      setUserName(response?.data?.name);
      setUserCads(response?.data?.cads);
    }
  };

  const RadioBoxPayment = ({ label, method, image }) => {
    return (
      <RadioBox
        type="button"
        onClick={() => setPaymentMethod(method)}
        isActive={paymentMethod === method}
        activeColor="green"
      >
        <img src={image} alt={label} />
        <span>{label}</span>
      </RadioBox>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container onSubmit={handleRechargeUser}>
        <ModalHeader title='Recarregar Cartões' handleClose={onRequestClose} />

        <h3>Identifique o usuário:</h3>
        <>
          <MaskedInput
            mask={maskCpfCnpj}
            placeholder="Cpf ou Cnpj"
            guide={false}
            type="text"
            value={cpfCnpj}
            onChange={(event) => setCpfCnpj(event.target.value)}
            autoFocus
            required
          />
          <IconButtonContainer>
            <IconButton onClick={findUserId}>
              <img src={Images.searchBlackImg} alt="Pesquisar" />
            </IconButton>
          </IconButtonContainer>
        </>

        {userId && (
          <>
            <InputsGrid>
              <h3>
                Nome: <span>{userName}</span>
              </h3>
              <h3>
                Carteira: <span>{userCads} cartões</span>
              </h3>
            </InputsGrid>

            <MaskedInput
              mask={maskQuantityCads}
              placeholder="Quantidade"
              guide={false}
              type="text"
              value={quantity}
              onChange={(event) => setQuantity(event.target.value)}
              required
            />

            <h3>Forma de pagamento:</h3>

            <RadioContainerPayment>
              <RadioBoxPayment label='Pix' method='pix' image={Images.pixImg} />
              <RadioBoxPayment label='Crédito' method='credit_card' image={Images.creditCardImg} />
              <RadioBoxPayment label='Cortesia' method='cortesia' image={Images.giftImg} />
            </RadioContainerPayment>

            {loading ? (
              <button type="submit" disabled>
                Inserindo cartões...
              </button>
            ) : (
              <button type="submit">Inserir cartões</button>
            )}
          </>
        )}
      </Container>
    </Modal>
  );
}
