import { useState } from 'react'
import Modal from 'react-modal'
import { useFetch } from '../../../../hooks/useFetch'
import { API_URL } from '../../../../services/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { CardForm } from './form'
import { Card } from './card'
import { LoadingModal } from '../../../Modals/LoadingModal'
import { ModalHeader } from '../../../shared/ModalHeader'

export const CardModal = ({ isOpen, onRequestClose }) => {
  const [modal, setModal] = useState({})

  const { 
    response: cardsResponse,
    load: cardsLoad,
    refresh: cardsRefresh
  } = useFetch(`${API_URL}/cards`)

  const cards = cardsResponse?.data?.cards || []

  // console.log('CardModal:', 'Rendered')

  const closeModal = () => setModal({})

  const openCardForm = () => {
    setModal({
      show: <CardForm
        isOpen={true}
        onRequestClose={closeModal}
        cardsRefresh={cardsRefresh}
      />
    })
  }

  if (cardsLoad) {
    return <LoadingModal />
  }

  return (
    <>
      { modal.show }

      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <ModalHeader title='Meus Cartões' handleClose={onRequestClose}>
          <div
            className='is-flex p-2 is-align-items-center ml-3 is-clickable'
            style={{ borderRadius:'100%', backgroundColor:'var(--blue)' }}
            onClick={openCardForm}
          >
            <FontAwesomeIcon icon={faPlus} style={{ color:'var(--shape)', fontSize:'0.9rem' }} />
          </div>
        </ModalHeader>

        <div>
          {cards.reverse().map((card, index) => (
              <Card key={'card_' + index} card={card} cardsRefresh={cardsRefresh} destroy />
          ))}
        </div>
      </Modal>
    </>
  )
}
