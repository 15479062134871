import { useRef, useEffect } from 'react';
import { Field, Label, Control, Input } from "rbx";

export const TextField = ({ name, label, placeholder=null, value, onChange, focus=false, children, ...other }) => {
  const textField = useRef();

  useEffect(()=>{
    if (focus) textField.current.focus();
  },[focus]);

  return (
    <>
      {(label) && <Label>{label}</Label>}
      <Field kind="addons">
        <Control expanded>
          <Input 
            ref={textField}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            {...other}
          />
        </Control>
        {children}
      </Field>
    </>
  )
}
