import { ConfirmedIrregularityList } from '../../../components/Agent/ConfirmedIrregularity/list';
import { Layout } from '../../../components/Layout';

export default function ConfirmedIrregularityPage() {
  return (
    <Layout>
      <ConfirmedIrregularityList 
        filtro={true}
        title="Irregularidades Autuadas" 
      />
    </Layout>
  );
}
