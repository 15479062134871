import { useCallback, useState } from 'react'
import { GoogleMap, OverlayView, Polygon, useJsApiLoader } from '@react-google-maps/api'
import Loading from '../../../helpers/Loading'
import { env } from '../../../env'

export const ZadMap = ({ children, areasMap=[], mapContainerStyle={}, zoom=16, ...other }) => {
  const [, setMap] = useState(null)
  const [mapCenter] = useState({ lat: env.ZAD_MAP_LAT, lng: env.ZAD_MAP_LNG })
  const [loadComponent, setLoadedComponent] = useState(false)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: env.GEOCODE_KEY,
    libraries: ['visualization']
  })

  const onLoadMap = useCallback(function callback(map) {
    setMap(map)
    setLoadedComponent(true)
  }, [])

  const onUnmountMap = useCallback(function callback(map) {
    setMap(null)
  }, [])
  
  const mediaLatLng = (paths=[]) => {
    let lat = 0
    let lng = 0
    if (paths.length > 0) {
      lat += paths.reduce((total, position) => (total + position.lat), 0) / paths.length
      lng += paths.reduce((total, position) => (total + position.lng), 0) / paths.length
    }
    return { lat, lng}
  }

  const areas = () => {
    const areas = []
    for (const area of areasMap) {
      if (area.perimeter) {
        areas.push(
          {
            name: area.name,
            options: {
              fillColor: area.color,
              fillOpacity: .2,
              strokeColor: area.color,
              strokeOpacity: 0.5,
              strokeWeight: 2,
              clickable: true,
              draggable: false,
              editable: false,
              geodesic: false,
              zIndex: 1
            },
            paths: area.perimeter.split('),(').map(latLng => (
              { lng: Number(latLng.split(',')[0].replaceAll('(','')), lat: Number(latLng.split(',')[1].replaceAll(')','')) }
            )),
            position: mediaLatLng(area.perimeter.split('),(').map(latLng => (
              { lng: Number(latLng.split(',')[0].replaceAll('(','')), lat: Number(latLng.split(',')[1].replaceAll(')','')) }
            )))
          }
        )
      }
    }
    return areas
  }

  const divStyle = {
    color: 'var(--text-title)',
    background: 'white',
    border: '2px dashed var(--text-title)',
    borderRadius: '0.5rem',
    padding: 10,
    textAlign: 'center',
    width: '90px'
  }

  return isLoaded
    ? <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={mapCenter}
        zoom={zoom}
        onLoad={onLoadMap}
        onUnmount={onUnmountMap}
        {...other}
      >
        {
          areas().map((area) => (
            <OverlayView
              key={Math.random()}
              position={area.position}
              mapPaneName={OverlayView.OVERLAY_LAYER}
            >
              <div style={divStyle}>
                <h2>AREA {area.name}</h2>
              </div>
            </OverlayView>
          ))
        }

        {
          !loadComponent && (
            areas().map((area) => (
              <Polygon
                key={Math.random()}
                paths={area.paths}
                options={area.options}
              />
            ))
          )
        }

        {
          children
        }
      </GoogleMap>
    : <Loading />
}
