import { Box } from "rbx"
import { useEffect } from "react"
import { useState } from "react"
import { unMask } from "../../../commons/masks"
import { useFetch } from "../../../hooks/useFetch"
import { API_URL } from "../../../services/api"
import { BoxHeader } from "../../shared/BoxHeader"
import Controls from "../../shared/Controls/Controls"

export const ContactUsSearch = ({ filter, setFilter, closeModal, setFilterLoading }) => {
  const { response: reasonResponse } = useFetch(`${API_URL}/problems-types`)

  const [cpfCnpj, setCpfCnpj] = useState(filter?.cpfCnpj||'')
  const [startDate, setStartDate] = useState(filter?.startDate||'')
  const [endDate, setEndDate] = useState(filter?.endDate||'')
  const [reason, setReason] = useState(filter?.reason||'')
  const [reasons, setReasons] = useState([])
  const [description, setDescription] = useState(filter?.description||'')
  const [answer, setAnswer] = useState(filter?.answer||'')
  const [solved, setSolved] = useState(filter?.solved||'')

  const [stateButton, setStateButton] = useState('')

  useEffect(() => {
    setReasons(reasonResponse?.data||[])
  }, [reasonResponse?.data])

  const handleSubmit = (e) => {
    e.preventDefault()
    setFilterLoading('loading')
    setFilter({
      cpfCnpj: unMask(cpfCnpj), startDate, endDate, reason, description, answer, solved
    })
    setStateButton('')
    closeModal()
  }

  return (
    <Box>
      <BoxHeader title='Filtar por' handleClose={closeModal} />
      <form onSubmit={handleSubmit}>
        <Controls.CpfCnpjField 
          label="CPF ou CNPJ do contato"
          name="cpfCnpj"
          placeholder='Informe o CPF ou CNPJ do contato'
          value={cpfCnpj}
          onChange={(e) => setCpfCnpj(e.target.value)}
          autoFocus
        />
        <Controls.TextField
          label="Data do contato [inicio]"
          name="startDate"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <Controls.TextField
          label="Data do contato [fim]"
          name="endDate"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <Controls.SelectField
          label="Motivo"
          name="reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          options={reasons.map(r => ({ value: r.name, description: r.name }))}
        />
        <Controls.TextField 
          label="Descrição"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder='digite parte da descrição'
        />
        <Controls.TextField 
          label="Resposta"
          name="answer"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          placeholder='digite parte da responsta'
        />
        <Controls.SelectField
          label="Respondido ?"
          name="solved"
          value={solved}
          onChange={(e) => setSolved(e.target.value)}
          options={[{value:'1', description:'TODOS'},{value:true, description:'SIM'},{value:false, description:'NÃO'}]}
        />
        <Controls.ActionButtons
          linkName="Fechar"
          handleClickBack={closeModal}
          buttonState={stateButton}
          buttonName='Filtrar'
        />
      </form>
    </Box>
  )
}