import { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Container, IconButton } from './styles';
import { VehicleImageModal } from '../../../Modals/VehicleImageModal';
import IrregularVehicleService from '../../../../services/irregular-vehicle';
import { toast } from 'react-toastify';
import { IrregularVehicleDetail } from '../detail';
import moment from 'moment';
import { TitleButtons } from '../../../TitleButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSync } from '@fortawesome/free-solid-svg-icons';
import { Icon } from 'rbx';
import { faImage } from '@fortawesome/free-regular-svg-icons';
// import Loading from '../../../../helpers/Loading';

export function IrregularVehiclesList({ handleClickBtnBack }) {
  const profile = sessionStorage.getItem('profile')

  const [loading, setLoading] = useState(false);
  const [irregularVehiclesData, setIrregularVehiclesData] = useState([])
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [isVehicleImageModalOpen, setIsVehicleImageModalOpen] = useState(false)
  const [detailsData, setDetailsData] = useState({});
  const [vechicleImageUrl, setVechicleImageUrl] = useState('')
  const [refresh, setRefresh] = useState('')

  useEffect(() => {
    fetchIrregularVehicles()
  }, [refresh])

  const fetchIrregularVehicles = async () => {
    setLoading(true)
    try {
      const response = await IrregularVehicleService.findIrregularVehicles()
      setIrregularVehiclesData(response.data.data || [])
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const toggleDetail = () => {
    if (isDetailOpen) setDetailsData({})
    setIsDetailOpen(!isDetailOpen);
  }

  const toggleVehicleImageModal = () => {
    if (isVehicleImageModalOpen) setVechicleImageUrl('')
    setIsVehicleImageModalOpen(!isVehicleImageModalOpen);
  }

  const theme = createMuiTheme({
    typography: {
      fontFamily: `"Poppins", "Roboto", "Arial", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    // overrides: {
    //   MUIDataTableToolbar: { root: { display: 'none' } },
    // },
  });

  theme.shadows = [];

  const columns = [
    {
      name: 'date',
      label: 'Data',
      options: { filter: false, sort: true, display: true },
    },
    {
      name: 'startDate',
      label: 'Inicio',
      options: { filter: false, sort: true, display: true },
    },
    {
      name: 'endDate',
      label: 'Fim',
      options: { filter: false, sort: true, display: true },
    },
    {
      name: 'local',
      label: 'Local do estacionamento',
      options: { filter: false, sort: false, display: true },
    },
    {
      name: 'licensePlate',
      label: 'Placa',
      options: { filter: false, sort: false, display: true },
    },
    {
      name: 'imageVehicle',
      label: 'Imagem',
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Icon
              className='is-clickable mx-3'
              style={{fontSize:'1.5rem'}}
              onClick={() => {
                toggleVehicleImageModal();
                setVechicleImageUrl(value);
              }}
            >
              <FontAwesomeIcon icon={faImage} />
            </Icon>
          );
        },
      },
    },
  ];

  if (profile === 'agent') {
    columns.push(
      {
        name: 'Detalhes',
        label: 'Detalhes',
        options: {
          filter: false,
          sort: false,
          display: true,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Icon
                className='is-clickable mx-3'
                style={{fontSize:'1.5rem'}}
                onClick={() => {
                  setDetailsData(irregularVehiclesData.find(irregular => irregular.licensePlate === tableMeta.rowData[4]))
                  toggleDetail();
                }}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </Icon>
            );
          },
        },
      },
    )
  }

  const options = {
    responsive: 'standard',
    draggableColumns: {
      enabled: false,
    },
    selectableRows: 'none',
    rowsPerPage: 5,
    rowsPerPageOptions: [10, 25, 50, 100],
    print: 'false',
    filter: 'false',
    download: 'true',
    viewColumns: 'true',
    onDownload: (buildHead, buildBody, columns, data) => {
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    },
    textLabels: {
      body: {
        noMatch: 'Nenhum registro encontrado.',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'resultado(s) de', // 1-10 of 30
      },
      toolbar: {
        search: 'Pesquisar',
        viewColumns: 'Selecionar Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        title: 'FILTROS',
        reset: 'limpar',
        all: 'Todos',
      },
      viewColumns: {
        title: 'Mostrar Colunas',
        titleAria: 'Mostrar/Esconder Colunas da Tabela',
      },
    },
    customToolbarSelect: () => {},
    expandableRows: false,
    expandableRowsOnClick: false,
  };

  if (isDetailOpen) {
    return (
      <Container className="animeLeft">
        <IrregularVehicleDetail
          onDetailClose={toggleDetail}
          detailsData={detailsData}
          refreshList={() => setRefresh(moment().format())}
        />
      </Container>
    )
  }

  // if (loading) return <Loading />;
  return (
    <Container className="animeLeft">
      <VehicleImageModal 
        isOpen={isVehicleImageModalOpen}
        onRequestClose={toggleVehicleImageModal}
        vechicleImageUrl={vechicleImageUrl}
      />

      <TitleButtons 
        handleBtnBackClick={ handleClickBtnBack }
      >
        <span style={{display:'flex', alignItems:'center'}}>
          <IconButton onClick={() => setRefresh(moment().format())}>
            <FontAwesomeIcon icon={faSync} className={ loading ? 'fa-spin' : '' } title="Atualizar" style={{fontSize:'1.1rem'}} color='var(--text-title)' />
          </IconButton>
          <h3>Avisos de Irregularidades</h3>
        </span>
      </TitleButtons>

      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          data={irregularVehiclesData}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </Container>
  )
}
