import { Field, Control, Column, Button } from "rbx";
import { Link } from "react-router-dom";

const ActionButtons = ({ linkName, handleClickBack, buttonState, buttonName }) => {
  return (
    <Field>
      <Control>
        <Column.Group breakpoint="mobile">
          <Column className="has-text-left">
            { handleClickBack && <Link to="#" onClick={handleClickBack} className="button is-danger is-outlined">{linkName}</Link> }
          </Column>
          <Column className="has-text-right">
            <Button state={buttonState} color="custom-purple" outlined>{buttonName}</Button>
          </Column>
        </Column.Group>
      </Control>
    </Field>
  )
}

export default ActionButtons;
