import { Layout } from '../../components/Layout'
import { TimeRuleList } from '../../components/TimeRule/list'

export const TimeRulePage = () => {
  return (
    <Layout controllerName='Horários de estacionamento'>
      <TimeRuleList />
    </Layout>
  )
}
