import { useRef, useEffect } from 'react';
import { Field, Label, Control, Textarea } from "rbx";

const TextareaField = (props) => {
  const { name, label, value, onChange, focus=false, ...other } = props;
  const textField = useRef();

  useEffect(()=>{
    if (focus)
      textField.current.focus();
  },[focus]);

  return (
    <>
      {(label) && <Label className="">{label}</Label>}
      <Field>
        <Control>
          <Textarea 
            type={name}
            ref={textField}
            name={name}
            value={value}
            onChange={onChange}
            {...other}
          />
        </Control>
      </Field>
    </>
  )

}

export default TextareaField;
